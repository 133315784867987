import React from "react";

import { getIn, useFormikContext } from "formik";
import _ from "lodash";
import { FormCheck } from "react-bootstrap";

import { fieldInvalid, fieldValid } from "../new_utils";

type PropsType = {
  validate?: boolean;
  fast?: boolean;
  name: string;
  inactiveValue?: any;
  value?: any;
  type?: "checkbox" | "radio" | "switch";
  id?: string;
  label?: React.ReactNode;
  inline?: boolean;
  onChange?: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  disabled?: boolean;
  isValid?: boolean;
  isInvalid?: boolean;
};

const isChecked = (values: any, name: string | string[], fieldValue: any) => {
  const givenValue = getIn(values, name);

  if (_.isArray(givenValue)) {
    return givenValue.includes(fieldValue);
  }

  return givenValue === fieldValue;
};

function FormikCheck({
  validate = true,
  name,
  value = true,
  inactiveValue = false,
  type = "checkbox",
  isValid,
  isInvalid,
  ...props
}: PropsType) {
  const formik = useFormikContext();

  async function handleChange(ev: React.ChangeEvent<HTMLInputElement>) {
    const trgt = ev.target;
    const givenValue = getIn(formik.values, name);

    if (trgt.checked) {
      if (_.isArray(givenValue)) {
        await formik.setFieldValue(name, type === "radio" ? [value] : [...givenValue, value]);
      } else {
        await formik.setFieldValue(name, value, false);
      }
    } else {
      if (_.isArray(givenValue)) {
        await formik.setFieldValue(
          name,
          givenValue.filter((v) => v !== value),
          false,
        );
      } else {
        await formik.setFieldValue(name, inactiveValue, false);
      }
    }

    formik.setFieldTouched(name);

    if (props.onChange) props.onChange(ev);
  }

  return (
    <FormCheck
      type={type}
      name={name}
      onChange={handleChange}
      checked={isChecked(formik.values, name, value)}
      {..._.omit(props, ["onChange"])}
      isValid={isValid ?? (validate && fieldValid(formik.errors, formik.touched, name))}
      isInvalid={isInvalid ?? (validate && fieldInvalid(formik.errors, formik.touched, name))}
    />
  );
}

export default FormikCheck;
