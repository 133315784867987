import React, { useState } from "react";

import { useMutation, useQuery } from "@apollo/client";
import { differenceInSeconds, parseISO } from "date-fns";
import { Form, Formik, type FormikHelpers } from "formik";
import type { TFunction } from "i18next";
import _ from "lodash";
import { Form as Bsform, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { CONTACT_MUTATION, STATES_QUERY } from "@api/contacts/contacts";
import { HISTORIES_CALL_RESULTS_LIST_QUERY, MUTATE_HISTORY_ENTRY } from "@api/histories";
import { TASK_MUTATION, TASKS_LIST_QUERY } from "@api/tasks";
import { TODO_MUTATION } from "@api/todos";

import { may } from "../../abilities";
import { SaveButton } from "../../containers/buttons";
import ErrorMessage from "../../containers/ErrorMessage";
import FormikCheck from "../../containers/FormikCheck";
import FormikInput from "../../containers/FormikInput";
import FormikReactSelect from "../../containers/FormikReactSelect";
import TTFormGroup from "../../containers/TTFormGroup";
import handleError, { MutationError } from "../../handleError";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { deleteInactiveCalls, toggleAddCallHistoryModal } from "../../store/acdReducer";
import { acdSelector, AcdStateType } from "../../store/acdReducer";
import { sessionSelector } from "../../store/sessionReducer";
import { getComponent as getContactComponent } from "../contacts/components";
import { getComponent } from "../CustomerContacts/components";
import { addDangerFlash, addSuccessFlash } from "../flash/flashReducer";
import TTModal from "../TTModal";

export type CallHistoryEntry<T = unknown> = {
  subject: string;
  details: string;
  htype: "CALL";
  showCustomerContact: boolean;
  customerContact: T;
  callResultId: string | null;
  meta: {
    reached: Nullable<boolean>;
    direction: AcdCallDirectionType;
    initiator: string;
    recipient: Nullable<string>;
    connected: boolean;
    started: string;
    stopped: string;
    waitingStopped: Nullable<string>;
    duration: number;
    talkTime: Nullable<number>;
    callRecordId: string;
    hasRecordings: boolean;
  };
};

interface DefaultTask extends TaskInterface {
  caddfields: { [key: string]: unknown };
}

const validationSchema = (t: TFunction, CCSchema: Nullable<Yup.AnyObjectSchema>) =>
  Yup.object({
    subject: Yup.string().trim().required(t("acd:call_history_entry.subject_missing")),
    meta: Yup.object().shape({
      reached: Yup.boolean().test(
        "reached",
        t("acd:call_history_entry.reached_missing"),
        (value, _context) => typeof value === "boolean",
      ),
    }),
    details: Yup.string().when("meta.reached", {
      is: true,
      then: () => Yup.string().trim().required(t("acd:call_history_entry.details_missing")),
      otherwise: () => Yup.string(),
    }),
    customerContact: Yup.object().when("showCustomerContact", {
      is: true,
      then: () => CCSchema || Yup.object().nullable(),
      otherwise: () => Yup.object().nullable(),
    }),
  });

const initialValues = (t: TFunction, acd: AcdStateType): CallHistoryEntry => {
  const call = acd.modalCall ? acd.inactiveCalls[acd.modalCall.callGroupId]! : [];

  const firstEvent = call[0];
  const lastEvent = call[call.length - 1];
  // in this case stopped can't be null/undefined
  const stopped = lastEvent ? parseISO(lastEvent.stopped!) : new Date();

  let reached = null;

  if (lastEvent.direction === "INBOUND") {
    reached = lastEvent.connected;
  }

  return {
    subject:
      firstEvent?.direction === "INBOUND"
        ? t("acd:call_history_entry.inbound_call")
        : t("acd:call_history_entry.outbound_call"),
    details: "",
    htype: "CALL",
    showCustomerContact: false,
    customerContact: null,
    callResultId: null,
    meta: {
      reached,
      direction: firstEvent.direction,
      initiator: firstEvent.initiator,
      recipient: firstEvent.recipient,
      connected: lastEvent.connected,
      started: lastEvent.started,
      stopped: lastEvent.stopped!,
      waitingStopped: lastEvent?.waitingStopped,
      duration: differenceInSeconds(stopped, parseISO(lastEvent.started!)),
      talkTime: lastEvent.waitingStopped ? differenceInSeconds(stopped, parseISO(lastEvent.waitingStopped)) : null,
      callRecordId: lastEvent.callRecordId!,
      hasRecordings: false, // TODO: call recordings are not implemented,
    },
  };
};

export default function CallHistoryEntry() {
  const session = useAppSelector(sessionSelector);
  const acd = useAppSelector(acdSelector);
  const dispatch = useAppDispatch();
  const [CCSchema, setCCSchema] = useState<Nullable<Yup.AnyObjectSchema>>(null);
  const { t } = useTranslation(["translation", "acd", "customerContacts"]);

  const [mutateHistoryEntry] = useMutation<ContactHistoryMutationInterface>(MUTATE_HISTORY_ENTRY, {
    refetchQueries: ["histories"],
  });
  const [taskMutation] = useMutation<TaskMutationInterface>(TASK_MUTATION);
  const [todoMutation] = useMutation<TodoMutationInterface>(TODO_MUTATION);
  const [contactMutation] = useMutation<ContactMutationInterface>(CONTACT_MUTATION);

  const { data } = useQuery<HistoriesCallResultsDataType>(HISTORIES_CALL_RESULTS_LIST_QUERY, {
    variables: {
      customerId: session.currentCustomer.id,
      projectId: session.currentProject.id,
    },
    skip: !may(session, "contacts"),
  });

  const { data: statesData } = useQuery<ContactStatesDataInterface, ContactStatesVarsInterface>(STATES_QUERY, {
    variables: {
      customerId: session.currentCustomer.id,
      projectId: session.currentProject.id,
      filters: { allowedAfterCall: true },
    },
    skip: !acd.showCallHistoryModal || !may(session, "contacts"),
  });

  const states = statesData?.contactStates || [];

  async function createHistoryEntry(values: CallHistoryEntry, { setSubmitting }: FormikHelpers<CallHistoryEntry>) {
    setSubmitting(true);

    const saveTask = getContactComponent(session, "saveTask");

    if (values.showCustomerContact && saveTask) {
      saveTask(session, undefined, states, values.customerContact, t);
    } else if (values.showCustomerContact) {
      const taskValues = values.customerContact as DefaultTask;
      const contact = taskValues?.contact;
      const todo = taskValues?.todo;
      const caddfields = taskValues?.caddfields;

      const customerContact = _.omit(taskValues, ["infos", "contact", "todo", "caddfields"]);

      try {
        const { data } = await taskMutation({
          variables: {
            values: customerContact,
            customerId: session.currentCustomer.id,
            projectId: session.currentProject.id,
          },

          refetchQueries: [
            {
              query: TASKS_LIST_QUERY,
              variables: {
                customerId: session.currentCustomer.id,
                projectId: session.currentProject.id,
                filters: {
                  contactId: acd.inactiveCallContact!.id,
                  limit: "25",
                  offset: "0",
                  order: "INSERTED_AT",
                  direction: "DESC",
                },
              },
            },
          ],
        });

        if (!data?.mutateTask) {
          throw new MutationError();
        }

        if (caddfields && contact) {
          const { data: contactData } = await contactMutation({
            variables: {
              customerId: session.currentCustomer.id,
              projectId: session.currentProject.id,
              id: contact.id,
              contact: {
                attrs: { ...contact.attrs, addfields: { ...contact.attrs?.addfields, ...caddfields } },
              },
            },
          });

          if (!contactData?.mutateContact) {
            throw new MutationError();
          }
        }

        if (todo?.description) {
          const { data: todoData } = await todoMutation({
            variables: {
              customerId: session.currentCustomer.id,
              projectId: session.currentProject.id,
              values: {
                ...todo,
                contactId: customerContact?.contactId,
                taskId: data.mutateTask.id,
              },
            },
          });

          if (!todoData?.mutateTodo) {
            throw new MutationError();
          }
        }

        dispatch(addSuccessFlash(t("customerContacts:new_form.created")));
      } catch (e) {
        dispatch(addDangerFlash(t("translation:global.general_error")));
        handleError(e);
      }
    }

    const meta = _.omitBy(values.meta, _.isUndefined);

    const { data } = await mutateHistoryEntry({
      variables: {
        customerId: session.currentCustomer.id,
        projectId: session.currentProject.id,
        contactId: acd.inactiveCallContact!.id,
        history: { ..._.omit(values, ["showCustomerContact", "customerContact"]), meta },
      },
    });

    if (!data?.mutateHistory) {
      throw new MutationError();
    }

    const callback = getContactComponent(session, "histories/callback");

    if (callback) {
      callback(session, acd.inactiveCallContact!, data.mutateHistory);
    }

    setSubmitting(false);
    dispatch(toggleAddCallHistoryModal());
    dispatch(deleteInactiveCalls());
  }

  function testForCallResult(values: CallHistoryEntry) {
    if (!!data?.historiesCallResults.length && !values.callResultId) {
      return { callResultId: "Bitte geben Sie ein Gesprächsergebnis an!" };
    }

    return {};
  }

  const CCForm = getComponent(session.currentCustomer, session.currentProject, "form");

  return (
    <TTModal show={acd.showCallHistoryModal && !!acd.inactiveCallContact} backdrop="static" size="lg">
      {!!acd.inactiveCallContact && (
        <Formik
          initialValues={initialValues(t, acd)}
          validationSchema={validationSchema(t, CCSchema)}
          onSubmit={createHistoryEntry}
          validate={testForCallResult}
        >
          {({ isSubmitting, values, setFieldValue }) => {
            function setInitialCCValues(values: any, schema: any) {
              setFieldValue("customerContact", values);
              if (schema) setCCSchema(schema);
            }

            function updateDetails(ev: React.ChangeEvent<HTMLInputElement>) {
              if (ev.target.name === "customerContact.description") {
                setFieldValue("details", ev.target.value);
              }
            }

            return (
              <Form>
                <Modal.Header>
                  <Modal.Title>{t("acd:call_history_entry.call_details")}</Modal.Title>
                </Modal.Header>

                <Modal.Body>
                  <p>{t("acd:call_history_entry.summarize_call")}</p>

                  <TTFormGroup>
                    <Bsform.Label htmlFor="subject">{t("translation:global.subject")}</Bsform.Label>
                    <FormikInput name="subject" id="subject" />
                    <ErrorMessage path="subject" />
                  </TTFormGroup>

                  {values.meta.direction === "OUTBOUND" && (
                    <TTFormGroup>
                      <FormikCheck
                        type="radio"
                        inline
                        name="meta.reached"
                        id="reached-yes"
                        value={true}
                        label={t("acd:call_history_entry.reached")}
                      />
                      <FormikCheck
                        type="radio"
                        inline
                        name="meta.reached"
                        id="reached-no"
                        value={false}
                        label={t("acd:call_history_entry.not_reached")}
                      />
                    </TTFormGroup>
                  )}

                  {!!data?.historiesCallResults.length && (
                    <TTFormGroup>
                      <Bsform.Label htmlFor="callResultId">Gesprächsergebnis</Bsform.Label>
                      <FormikReactSelect
                        id="callResultId"
                        name="callResultId"
                        options={data.historiesCallResults.map((result) => ({
                          label: result.name,
                          value: result.id,
                        }))}
                      />
                      <ErrorMessage path="callResultId" />
                    </TTFormGroup>
                  )}

                  {may(session, "customer-contacts", "new") &&
                    values.meta.reached !== null &&
                    session.currentProject.attrs.cc_in_after_call &&
                    CCForm && (
                      <TTFormGroup>
                        <TTFormGroup>
                          <FormikCheck
                            name="showCustomerContact"
                            id="showCustomerContact"
                            label={t("acd:call_history_entry.create_customer_contact")}
                          />
                        </TTFormGroup>
                      </TTFormGroup>
                    )}

                  {!values.showCustomerContact && values.meta.reached !== false && (
                    <TTFormGroup>
                      <Bsform.Label htmlFor="details">{t("translation:global.details")}</Bsform.Label>
                      <FormikInput as="textarea" name="details" id="details" />
                      <ErrorMessage path="details" />
                    </TTFormGroup>
                  )}

                  {values.showCustomerContact && (
                    <div onChange={updateDetails}>
                      <CCForm
                        callHistory={values}
                        customerContact={{ contact: acd.inactiveCallContact }}
                        hideFormActions={true}
                        hideContactSubform={true}
                        onlyInner={true}
                        setInitial={setInitialCCValues}
                        validStates={states}
                        prefix="customerContact"
                      />
                    </div>
                  )}
                </Modal.Body>

                <Modal.Footer>
                  <SaveButton type="submit" disabled={isSubmitting}>
                    {t("translation:global.save")}
                  </SaveButton>
                </Modal.Footer>
              </Form>
            );
          }}
        </Formik>
      )}
    </TTModal>
  );
}
