import { useEffect, useRef, useState } from "react";

import { Button, Form, OverlayTrigger, Popover } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MdLink } from "react-icons/md";
import { Editor, Path, Range, Element as SlateElement, Transforms } from "slate";
import { useSlate } from "slate-react";

import TTFormGroup from "../../../containers/TTFormGroup";

const createLinkNode = (url: string, text: string): CustomElement => ({
  type: "link",
  url,
  children: [{ text: text.length ? text : "Link" }],
});

const insertLink = (editor: Editor, url: string, text: string) => {
  if (!url) return;

  const { selection } = editor;
  const link = createLinkNode(url, text);

  if (selection) {
    const [parent, parentPath] = Editor.parent(editor, selection.focus.path);

    if (SlateElement.isElement(parent) && parent.type === "link") {
      removeLink(editor);
    }

    if (SlateElement.isElement(parent) && Editor.isVoid(editor, parent)) {
      Transforms.insertNodes(editor, link, {
        at: Path.next(parentPath),
        select: true,
      });
    } else if (Range.isCollapsed(selection)) {
      Transforms.insertNodes(editor, link, { select: true });
    } else {
      Transforms.wrapNodes(editor, link, { split: true });
    }
  } else {
    Transforms.insertNodes(editor, link);
  }
};

const removeLink = (editor: Editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n.type === "link",
  });
};

export default function HyperlinkButton() {
  const editor = useSlate();
  const [url, setUrl] = useState("");
  const [caption, setCaption] = useState("");
  const [show, setShow] = useState(false);
  const { t } = useTranslation(["slateEditor"]);

  const doInsertLink = () => {
    insertLink(editor, url, caption);
    setShow(false);
  };

  useEffect(() => {
    const { selection } = editor;

    if (!!selection && !!show) {
      const selectedText = Editor.string(editor, selection);
      setCaption(selectedText);
    }
  }, [editor, show]);

  const ref = useRef(null);

  const popover = (
    <Popover id="link-popover">
      <Popover.Header as="h3">{t("slateEditor:link_popover.headline")}</Popover.Header>
      <Popover.Body>
        <TTFormGroup>
          <Form.Control
            placeholder={t("slateEditor:link_popover.link")}
            id="url"
            size="sm"
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setUrl(ev.target.value)}
          />
        </TTFormGroup>

        <TTFormGroup>
          <Form.Control
            placeholder={t("slateEditor:link_popover.text")}
            id="text"
            size="sm"
            value={caption}
            onChange={(ev: React.ChangeEvent<HTMLInputElement>) => setCaption(ev.target.value)}
          />
        </TTFormGroup>

        <Button className="TT-hyperlink-btn" variant="secondary" size="sm" onClick={doInsertLink}>
          {t("slateEditor:insert")}
        </Button>
      </Popover.Body>
    </Popover>
  );

  return (
    <>
      <div ref={ref} />

      <OverlayTrigger
        show={show}
        trigger="click"
        placement="bottom"
        overlay={popover}
        onToggle={() => setShow(!show)}
        container={ref}
      >
        <Button variant="light">
          <MdLink />
        </Button>
      </OverlayTrigger>
    </>
  );
}
