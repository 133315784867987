import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../../../store";

export interface ChangeRequestsReducerInterface {
  starts: Nullable<Date>;
  stops: Nullable<Date>;
  states: ChangeRequestStatusType[];
}

const initialState: ChangeRequestsReducerInterface = {
  starts: null,
  stops: null,
  states: ["AWAITING_CONFIRMATION", "NEW", "REQUEST", "RETURNED"],
};

const changeRequestsSlice = createSlice({
  name: "changeRequests",
  initialState,
  reducers: {
    setStarts(state, action: PayloadAction<Nullable<Date>>) {
      state.starts = action.payload;
    },

    setStops(state, action: PayloadAction<Nullable<Date>>) {
      state.stops = action.payload;
    },

    setStates(state, action: PayloadAction<ChangeRequestStatusType[]>) {
      state.states = action.payload;
    },

    resetFilters(state) {
      state.starts = null;
      state.stops = null;
      state.states = [];
    },
  },
});

export const { setStarts, setStops, setStates, resetFilters } = changeRequestsSlice.actions;
export const changeRequestsSelector = (state: RootState) => state.changeRequests;
export default changeRequestsSlice.reducer;
