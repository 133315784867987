import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { endOfDay, startOfDay } from "date-fns";

import type { RootState } from "../../store";

export type CustomerContactsReducerType = {
  start: Nullable<Date>;
  stop: Nullable<Date>;
  filter: string;
  teamId: Nullable<string>;
  contactType: Nullable<string>;
  reactionNeeded: Nullable<boolean>;
  sent: Nullable<boolean>;
  filterAttrs: Record<string, AttributeFilterType>;
  predefinedFilters: { name: string; params: any }[];
  creator: Nullable<UserInterface>;
  editor: Nullable<UserInterface>;
};

const initialState: CustomerContactsReducerType = {
  start: null,
  stop: null,
  filter: "",
  teamId: null,
  contactType: null,
  reactionNeeded: null,
  sent: null,
  filterAttrs: {},
  creator: null,
  editor: null,
  predefinedFilters: [],
};

const customerContactsSlice = createSlice({
  name: "customerContacts",
  initialState,
  reducers: {
    setStart(state, action: PayloadAction<Nullable<Date>>) {
      state.start = action.payload ? startOfDay(action.payload) : null;
    },
    setStop(state, action: PayloadAction<Nullable<Date>>) {
      state.stop = action.payload ? endOfDay(action.payload) : null;
    },
    setFilter(state, action: PayloadAction<string>) {
      state.filter = action.payload;
    },
    setTeamId(state, action: PayloadAction<Nullable<string>>) {
      state.teamId = action.payload;
    },
    setContactType(state, action: PayloadAction<Nullable<string>>) {
      state.contactType = action.payload;
    },
    setAttributeFilter(state, action: PayloadAction<{ name: string; value: Nilable<AttributeFilterType> }>) {
      if (!action.payload.value) {
        delete state.filterAttrs[action.payload.name];
      } else {
        state.filterAttrs[action.payload.name] = action.payload.value;
      }
    },

    setReactionNeeded(state, action: PayloadAction<Nullable<boolean>>) {
      state.reactionNeeded = action.payload;
    },
    setSent(state, action: PayloadAction<Nullable<boolean>>) {
      state.sent = action.payload;
    },
    setCreator(state, { payload }: PayloadAction<Nullable<UserInterface>>) {
      state.creator = payload;
    },
    setEditor(state, { payload }: PayloadAction<Nullable<UserInterface>>) {
      state.editor = payload;
    },
    addPredefinedFilter(state, action: PayloadAction<{ name: string; params: any }>) {
      state.predefinedFilters.push(action.payload);
    },

    removePredefinedFilter(state, action: PayloadAction<string>) {
      state.predefinedFilters = state.predefinedFilters.filter((item) => item.name !== action.payload);
    },
    resetFilters: (_state) => initialState,
  },
});

export const {
  setStart,
  setStop,
  setFilter,
  setTeamId,
  setContactType,
  setAttributeFilter,
  resetFilters,
  setReactionNeeded,
  setSent,
  setCreator,
  setEditor,
  addPredefinedFilter,
  removePredefinedFilter,
} = customerContactsSlice.actions;
export const customerContactsSelector = (state: RootState) => state.todos.customerContacts;
export default customerContactsSlice.reducer;
