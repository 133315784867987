import { useMutation } from "@apollo/client";
import { Button, Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MdGesture } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";

import { CONFIRM_DOCUMENT_MUTATION, DELETE_DOCUMENT_MUTATION, DOCUMENT_QUERY } from "@api/documents";
import { documentsPath, editDocumentPath } from "@path/document_paths";

import { may } from "../../abilities";
import { isCustomerAdmin } from "../../abilities/helpers";
import { updateDeletion } from "../../apollo_utils";
import { BackButton, DeleteButton, EditButton } from "../../containers/buttons";
import FormActionsContainer from "../../containers/FormActionsContainer";
import { NoDataTag } from "../../containers/NoData";
import User from "../../containers/User";
import handleError, { MutationError } from "../../handleError";
import { useAppDispatch } from "../../hooks";
import { dateTimeFormat } from "../../utils/dates";
import { addDangerFlash, addSuccessFlash } from "../flash/flashReducer";
import { DOCUMENT_FORMS } from "./utils";

type PropsType = {
  document: TTDocumentType;
  cancelAction?: () => void;
  session: SessionInterface;
};

export default function DocumentDetails({ document, cancelAction, session }: PropsType) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation(["documents", "translation"]);
  const { currentCustomer, currentProject, currentUser } = session;
  const variables = { customerId: currentCustomer.id, projectId: currentProject.id, id: document.id };

  const [deleteDocumentMutation] = useMutation<DeleteDocumentMutationInterface>(DELETE_DOCUMENT_MUTATION, {
    update: (cache, { data }) => updateDeletion(cache, data?.deleteDocument.id, "documents"),
  });

  const [confirmDocumentMutation] = useMutation<ConfirmDocumentMutationInterface>(CONFIRM_DOCUMENT_MUTATION, {
    update(cache, { data }) {
      if (!data?.confirmDocument) return;

      const document = cache.readQuery<DocumentDataInterface>({ query: DOCUMENT_QUERY, variables })?.document;
      if (!document) return;

      const newDoc = {
        ...document,
        signatures: [...document.signatures, data.confirmDocument],
        missingSignatures: document.missingSignatures.filter((u) => u.id !== data.confirmDocument.userId),
      };

      cache.writeQuery({ query: DOCUMENT_QUERY, variables, data: { document: newDoc } });
    },
  });

  async function deleteDocument() {
    try {
      const { data } = await deleteDocumentMutation({
        variables: { id: document.id, customerId: currentCustomer.id, projectId: currentProject.id },
      });

      if (!data?.deleteDocument) {
        throw new MutationError();
      }

      dispatch(addSuccessFlash(t("documents:list.deleted")));
      navigate(documentsPath(session));
    } catch (e) {
      dispatch(addDangerFlash(t("translation:global.general_error")));
      handleError(e);
    }
  }

  async function signDocument() {
    try {
      const { data } = await confirmDocumentMutation({
        variables: {
          customerId: currentCustomer.id,
          projectId: currentProject.id,
          id: document.id,
        },
      });

      if (!data?.confirmDocument) {
        throw new MutationError();
      }

      dispatch(addSuccessFlash(t("documents:show.document_confirmed")));
    } catch (e) {
      dispatch(addDangerFlash(t("translation:global.general_error")));
      handleError(e);
    }
  }

  const [, , Component] = DOCUMENT_FORMS[document.type];

  return (
    <>
      <Component document={document} />

      {document.signatureType !== "NONE" &&
        !!document.missingSignatures?.length &&
        isCustomerAdmin(currentUser, currentCustomer) && (
          <Row as="section" className="TT-documents-show-signatures-list">
            <Col md={6}>
              <h3>{t("documents:show.missing_signatures")}</h3>

              <ul>
                <NoDataTag data={document.missingSignatures} />

                {document.missingSignatures.map((user) => (
                  <li key={user.id}>
                    <User user={user} />
                  </li>
                ))}
              </ul>
            </Col>

            <Col md={6}>
              <h3>{t("documents:show.signatures")}</h3>

              <ul>
                <NoDataTag data={document.missingSignatures} />

                {document.signatures.map((signature) => (
                  <li key={signature.id}>
                    <User user={signature.user} /> ({dateTimeFormat(signature.insertedAt)})
                  </li>
                ))}
              </ul>
            </Col>
          </Row>
        )}

      <FormActionsContainer>
        {may(session, "documents", "edit", document) && (
          <EditButton as={Link} to={editDocumentPath(session, document)}>
            {t("translation:global.edit")}
          </EditButton>
        )}

        {may(session, "documents", "delete", document) && (
          <DeleteButton onClick={deleteDocument}>{t("translation:global.delete")}</DeleteButton>
        )}

        {may(session, "documents", "sign", document) && (
          <Button variant="secondary" onClick={signDocument}>
            <MdGesture /> {t("documents:show.confirm")}
          </Button>
        )}

        {cancelAction && <BackButton onClick={cancelAction}>{t("translation:global.back")}</BackButton>}
      </FormActionsContainer>
    </>
  );
}
