import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../../../store";

export interface WorktimeChangeRequestsReducerInterface {
  starts: Nullable<Date>;
  stops: Nullable<Date>;
  states: WorktimeChangeRequestStatusType[];
}

const initialState: WorktimeChangeRequestsReducerInterface = {
  starts: null,
  stops: null,
  states: ["PENDING", "REQUEST"],
};

const worktimesChangeRequestsSlice = createSlice({
  name: "worktimesChangeRequests",
  initialState,
  reducers: {
    setStarts(state, action: PayloadAction<Nullable<Date>>) {
      state.starts = action.payload;
    },

    setStops(state, action: PayloadAction<Nullable<Date>>) {
      state.stops = action.payload;
    },

    setStates(state, action: PayloadAction<WorktimeChangeRequestStatusType[]>) {
      state.states = action.payload;
    },

    resetFilters(state) {
      state.starts = null;
      state.stops = null;
      state.states = [];
    },
  },
});

export const { setStarts, setStops, setStates, resetFilters } = worktimesChangeRequestsSlice.actions;
export const worktimesChangeRequestsSelector = (state: RootState) => state.worktimesChangeRequests;
export default worktimesChangeRequestsSlice.reducer;
