import { getIn } from "formik";

import { ReactLazyLoading } from "../../../containers/ReactLoading";

const COMPONENTS = {
  "sevenit-gmbh": {
    sevdesk: ReactLazyLoading(() => import("../../../customers/sevenit/Hotseat/ForwardModal")),
  },
};

export const getComponent = (customer: CustomerInterface, project: BaseProjectInterface) =>
  getIn(COMPONENTS, [customer.identifier, project.identifier]) || null;
