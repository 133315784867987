import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../../../store";

type BottomBarReducerState = {
  bottomBarVisible: boolean;
  updateAvailable: boolean;
  displayNotitificationDialog: boolean;
};

const initialState: BottomBarReducerState = {
  bottomBarVisible: false,
  updateAvailable: false,
  displayNotitificationDialog: window.Notification && Notification.permission === "default",
};

const bottombarSlice = createSlice({
  name: "bottombar",
  initialState,
  reducers: {
    setUpdateAvailable(state, action: PayloadAction<boolean>) {
      state.updateAvailable = action.payload;
      state.bottomBarVisible = action.payload;
    },
    setDisplayNotitificationDialog(state, action: PayloadAction<boolean>) {
      state.displayNotitificationDialog = action.payload;
      state.bottomBarVisible = action.payload;
    },
  },
});

export const { setUpdateAvailable, setDisplayNotitificationDialog } = bottombarSlice.actions;
export const bottomBarSelector = (state: RootState) => state.bottomBarReducer;
export default bottombarSlice.reducer;
