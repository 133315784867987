import { useState } from "react";

import { useQuery } from "@apollo/client";
import _ from "lodash";
import { Button, Collapse } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MdExpandLess, MdExpandMore } from "react-icons/md";

import { PROJECT_LIST_QUERY } from "@api/projects";

import Customer from "./Customer";

type PropsType = {
  customer: FullCustomerInterface;
  project: ProjectInterface;
  unchooseCustomer: () => void;
  chooseProject: (project: ProjectInterface) => void;
  isMobile: boolean;
};

export default function ProjectList({ customer, project, unchooseCustomer, chooseProject, isMobile }: PropsType) {
  const [showInactive, setShowInactive] = useState(false);
  const { t } = useTranslation(["translation"]);

  const { data } = useQuery<ProjectsDataInterface>(PROJECT_LIST_QUERY, {
    variables: { customerId: customer.id, filters: { order: "NAME", direction: "ASC" } },
  });

  if (!data || !data.projects) return null;

  const activeProjects = _.filter(data.projects, (project) => project.active);
  const inactiveProjects = _.filter(data.projects, (project) => !project.active);

  return (
    <>
      {isMobile && (
        <Customer
          tag="div"
          className="single TT-switcher-customer"
          key={customer.id}
          customer={customer}
          onClick={unchooseCustomer}
        >
          <Button variant="secondary" className="TT-switcher-back-button" size="sm" onClick={unchooseCustomer}>
            {t("translation:global.back")}
          </Button>
        </Customer>
      )}

      {!!activeProjects.length && (
        <>
          <h3>{t("translation:switcher.active_projects")}</h3>
          <ul className="TT-switcher-project-list">
            {activeProjects.map((p) => (
              <li key={p.id}>
                <Button
                  variant="secondary"
                  type="button"
                  active={project && project.id === p.id}
                  onClick={() => chooseProject(p)}
                >
                  {p.name}
                </Button>
              </li>
            ))}
          </ul>
        </>
      )}

      {inactiveProjects.length > 0 && (
        <>
          <h3>
            <button onClick={() => setShowInactive(!showInactive)} className="TT-switcher-inactive-projects-toggle">
              {t("translation:switcher.inactive_projects")}

              <span>{showInactive ? <MdExpandLess /> : <MdExpandMore />}</span>
            </button>
          </h3>

          <Collapse in={showInactive}>
            <ul className="TT-switcher-project-list">
              {inactiveProjects.map((p) => (
                <li key={p.id}>
                  <Button
                    variant="secondary"
                    type="button"
                    active={project && project.id === p.id}
                    onClick={() => chooseProject(p)}
                  >
                    {p.name}
                  </Button>
                </li>
              ))}
            </ul>
          </Collapse>
        </>
      )}
    </>
  );
}
