export const sessionPrefix = (session: LightSessionInterface) =>
  `/${session.currentCustomer.identifier}/${session.currentProject.identifier}`;

export const queryString = (obj: QueryStringType) => {
  if (!obj) {
    return "";
  }

  const str: string[] = [];
  Object.entries(obj).forEach(([key, value]) => {
    if (typeof value === "string") {
      str.push(encodeURIComponent(key) + "=" + encodeURIComponent(value));
    } else {
      value.forEach((val) => {
        str.push(encodeURIComponent(key) + "=" + encodeURIComponent(val));
      });
    }
  });

  if (str.length) {
    return "?" + str.join("&");
  }

  return "";
};
