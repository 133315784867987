import { useCallback, useEffect, useState } from "react";

import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MdSync } from "react-icons/md";
import { useNavigate } from "react-router-dom";

import { isAdmin } from "../../../abilities/helpers";
import { getNewUrl, setCurrentCustomerAndProject } from "../../../actions/sessionActions";
import { useAppDispatch, useAppSelector } from "../../../hooks";
import { sessionSelector } from "../../../store/sessionReducer";
import TTModal from "../../TTModal";
import CustomerList from "./CustomerList";
import ProjectList from "./ProjectList";
import { setCustomer, setOnlyActive, switcherSelector, toggleModal } from "./switcherReducer";

type PropsType = {
  currentCustomer: CustomerInterface;
  currentProject: ProjectInterface;
};

export default function Switcher({ currentCustomer, currentProject }: PropsType) {
  const [isMobile, setIsMobile] = useState(false);
  const { show, customer, onlyActive } = useAppSelector(switcherSelector);
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["translation"]);
  const navigate = useNavigate();
  const session = useAppSelector(sessionSelector);

  useEffect(() => {
    const handleResize = () => {
      const mq = window.matchMedia("(max-width: 768px)");
      setIsMobile(mq.matches);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSwitcher = useCallback(() => {
    dispatch(toggleModal());
  }, [dispatch]);

  const unchooseCustomer = useCallback(() => dispatch(setCustomer(null)), [dispatch]);

  function chooseProject(project: ProjectInterface) {
    dispatch(toggleModal());
    dispatch(setCurrentCustomerAndProject(customer!, project));

    const newUrl = getNewUrl(customer!, project, session);
    if (newUrl) {
      navigate(newUrl);
    }
  }

  const doSetCustomer = useCallback((customer: FullCustomerInterface) => dispatch(setCustomer(customer)), [dispatch]);

  return (
    <>
      <button type="button" onClick={toggleSwitcher} className="TT-switcher-button">
        <img src={currentCustomer.logo.medium || "/default-logo.svg"} alt="" />{" "}
        {!currentCustomer.attrs.hide_name && <span className="name">{currentCustomer.name}</span>}
        <span className="show-switcher-text">
          <MdSync /> {t("translation:header.show-switcher")}
        </span>
      </button>

      <TTModal show={show} onHide={toggleSwitcher} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{t("translation:switcher.customers")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {(!isMobile || !customer) && <CustomerList chooseCustomer={doSetCustomer} chosenCustomer={customer} />}

          {!!customer && (
            <ProjectList
              customer={customer}
              project={currentProject}
              chooseProject={chooseProject}
              unchooseCustomer={unchooseCustomer}
              isMobile={isMobile}
            />
          )}
        </Modal.Body>

        <Modal.Footer>
          {isAdmin(session.currentUser) && (!isMobile || !customer) && (
            <Button variant="secondary" onClick={() => dispatch(setOnlyActive(!onlyActive))}>
              {onlyActive
                ? t("translation:switcher.show_all_customers")
                : t("translation:switcher.show_only_active_customers")}
            </Button>
          )}

          <Button variant="secondary" onClick={toggleSwitcher}>
            {t("translation:global.cancel")}
          </Button>
        </Modal.Footer>
      </TTModal>
    </>
  );
}
