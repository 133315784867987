import React from "react";

import clsx from "clsx";
import { Badge } from "react-bootstrap";

type UserAvatarProps = {
  avatar: AvatarInterface;
  notificationCount: number;
};

function UserMenuToggle({ avatar, notificationCount }: UserAvatarProps) {
  return (
    <div className="user-menu-toggle">
      <img src={avatar["thumb"]} alt="" className={clsx("TT-user-avatar", "thumb", "header")} />
      {notificationCount > 0 && (
        <Badge pill bg="primary" className="unread-message-badge">
          {notificationCount}
        </Badge>
      )}
    </div>
  );
}

export default React.memo(UserMenuToggle);
