import { RS_API_URI } from "@api/session";

import { queryString, sessionPrefix } from "./helpers";

export const staffPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/staff`;

export const worktimesPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/staff/worktimes`;
export const showWorktimePath = (session: LightSessionInterface, worktime: WorktimeInterface) =>
  `${sessionPrefix(session)}/staff/worktimes/${worktime.id}`;
export const newWorktimePath = (session: LightSessionInterface) => `${sessionPrefix(session)}/staff/worktimes/new`;
export const editWorktimePath = (session: LightSessionInterface, worktime: WorktimeInterface) =>
  `${sessionPrefix(session)}/staff/worktimes/${worktime.id}/edit`;

export const staffWorktimeChangeRequestsPath = (session: LightSessionInterface) =>
  `${sessionPrefix(session)}/staff/worktime-change-requests`;
export const showStaffWorktimeChangeRequestPath = (
  session: LightSessionInterface,
  worktime: WorktimeChangeRequestType,
) => `${sessionPrefix(session)}/staff/worktime-change-requests/${worktime.id}`;
export const newStaffWorktimeChangeRequestPath = (session: LightSessionInterface) =>
  `${sessionPrefix(session)}/staff/worktime-change-requests/new`;
export const editStaffWorktimeChangeRequestPath = (
  session: LightSessionInterface,
  worktime: WorktimeChangeRequestType,
) => `${sessionPrefix(session)}/staff/worktime-change-requests/${worktime.id}/edit`;

export const staffSupervisorPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/staff/supervisor`;

export const staffEmployeesPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/staff/employees`;
export const staffCondensedEmployeesPath = (session: LightSessionInterface) =>
  `${sessionPrefix(session)}/staff/employees/condensed`;
export const newEmployeePath = (session: LightSessionInterface) => `${sessionPrefix(session)}/staff/employees/new`;
export const showEmployeePath = (session: LightSessionInterface, employee: EmployeeInterface) =>
  `${sessionPrefix(session)}/staff/employees/${employee.id}`;
export const terminateEmployeePath = (session: LightSessionInterface, employee: EmployeeInterface) =>
  `${sessionPrefix(session)}/staff/employees/${employee.id}/terminate`;

export const staffFileTypesPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/staff/file-types`;
export const newFileTypePath = (session: LightSessionInterface) => `${sessionPrefix(session)}/staff/file-types/new`;
export const editFileTypePath = (session: LightSessionInterface, fileType: PersonalFileTypeType) =>
  `${sessionPrefix(session)}/staff/file-types/${fileType.id}`;
export const staffFileTypesMissingPath = (session: LightSessionInterface) =>
  `${sessionPrefix(session)}/staff/file-types/missing`;

export const personalFileDownloadPath = (file: PersonalFileInterface) =>
  `${RS_API_URI}/staff/personal-files/${file.id}`;

export const vacationPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/staff/vacation`;
export const vacationOverviewPath = (session: LightSessionInterface) =>
  `${sessionPrefix(session)}/staff/vacation/overview`;
export const vacationPlanPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/staff/vacation/plan`;
export const newVacationPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/staff/vacation/new`;
export const showVacationPath = (session: LightSessionInterface, request: VacationRequestInterface) =>
  `${sessionPrefix(session)}/staff/vacation/${request.id}`;
export const editVacationPath = (session: LightSessionInterface, request: VacationRequestInterface) =>
  `${sessionPrefix(session)}/staff/vacation/${request.id}/edit`;

export const presettlementsPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/staff/presettlements`;
export const newPresettlementPath = (session: LightSessionInterface) =>
  `${sessionPrefix(session)}/staff/presettlements/new`;
export const showPresettlementPath = (session: LightSessionInterface, presettlement: PresettlementInterface) =>
  `${sessionPrefix(session)}/staff/presettlements/${presettlement.id}`;
export const editPresettlementPath = (session: LightSessionInterface, presettlement: PresettlementInterface) =>
  `${sessionPrefix(session)}/staff/presettlements/${presettlement.id}/edit`;

export const settlementsPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/staff/settlements`;
export const newSettlementPath = (session: LightSessionInterface, qs: QueryStringType = null) =>
  `${sessionPrefix(session)}/staff/settlements/new${queryString(qs)}`;
export const editSettlementPath = (session: LightSessionInterface, settlement: SettlementInterface) =>
  `${sessionPrefix(session)}/staff/settlements/${settlement.id}/edit`;

export const timesheetsPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/staff/timesheets`;
export const showTimesheetPath = (session: LightSessionInterface, user: UserInterface) =>
  `${sessionPrefix(session)}/staff/timesheets/${user.id}`;
export const showTimesheetYearPath = (session: LightSessionInterface, user: UserInterface) =>
  `${sessionPrefix(session)}/staff/timesheets/${user.id}/year`;
export const showTimesheetMonthPath = (session: LightSessionInterface, user: UserInterface) =>
  `${sessionPrefix(session)}/staff/timesheets/${user.id}/month`;

export const companyNewsPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/staff/company-news/`;
export const newCompanyNewsPath = (session: LightSessionInterface) =>
  `${sessionPrefix(session)}/staff/company-news/new`;
export const editCompanyNewsPath = (session: LightSessionInterface, news: CompanyNewsType) =>
  `${sessionPrefix(session)}/staff/company-news/${news.id}`;

export const coachingsPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/staff/coachings/`;
export const newCoachingPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/staff/coachings/new`;
export const showCoachingPath = (session: LightSessionInterface, coaching: CoachingType) =>
  `${sessionPrefix(session)}/staff/coachings/${coaching.id}`;
export const editCoachingPath = (session: LightSessionInterface, coaching: CoachingType) =>
  `${sessionPrefix(session)}/staff/coachings/${coaching.id}/edit`;

export const staffCouponsPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/staff/coupons`;
export const newStaffCouponPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/staff/coupons/new`;
export const editStaffCouponPath = (session: LightSessionInterface, coupon: EmployeeCouponType) =>
  `${sessionPrefix(session)}/staff/coupons/${coupon.id}/edit`;

export const staffPerformancesPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/staff/performance`;
export const staffPerformancePath = (session: LightSessionInterface, user: UserInterface) =>
  `${sessionPrefix(session)}/staff/performance/${user.id}`;

export const staffChangeRequestsPath = (session: LightSessionInterface) =>
  `${sessionPrefix(session)}/staff/change-requests`;
export const staffChangeRequestPath = (session: LightSessionInterface, changeRequest: ChangeRequestType) =>
  `${sessionPrefix(session)}/staff/change-requests/${changeRequest.id}`;
export const editStaffChangeRequestPath = (session: LightSessionInterface, changeRequest: ChangeRequestType) =>
  `${sessionPrefix(session)}/staff/change-requests/${changeRequest.id}/edit`;
export const newStaffChangeRequestPath = (session: LightSessionInterface) =>
  `${sessionPrefix(session)}/staff/change-requests/new`;
export const createUserFromChangeRequestPath = (session: LightSessionInterface, changeRequest: ChangeRequestType) =>
  `${sessionPrefix(session)}/staff/change-requests/${changeRequest.id}/create-user`;

export const worktimesCustomerReviewPath = (session: LightSessionInterface) =>
  `${sessionPrefix(session)}/staff/worktimes-customer-review`;
export const showWorktimesCustomerReviewPath = (session: LightSessionInterface, user: UserInterface) =>
  `${sessionPrefix(session)}/staff/worktimes-customer-review/${user.id}`;

export const sickNotesPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/staff/sick-notes`;
export const sickNotePath = (session: LightSessionInterface, sickNote: SickNoteType) =>
  `${sessionPrefix(session)}/staff/sick-notes/${sickNote.id}`;
export const newSickNotePath = (session: LightSessionInterface) => `${sessionPrefix(session)}/staff/sick-notes/new`;
export const editSickNotePath = (session: LightSessionInterface, sickNote: SickNoteType) =>
  `${sessionPrefix(session)}/staff/sick-notes/${sickNote.id}/edit`;
export const sickNoteFilePath = (sickNote: SickNoteType) => `${RS_API_URI}/sick-notes/${sickNote.id}`;

export const lateNotesPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/staff/late-notes`;
export const lateNotePath = (session: LightSessionInterface, lateNote: LateNoteType) =>
  `${sessionPrefix(session)}/staff/late-notes/${lateNote.id}`;
export const newLateNotePath = (session: LightSessionInterface) => `${sessionPrefix(session)}/staff/late-notes/new`;
export const editLateNotePath = (session: LightSessionInterface, lateNote: LateNoteType) =>
  `${sessionPrefix(session)}/staff/late-notes/${lateNote.id}/edit`;
