import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { may } from "../../abilities";
import type { RootState } from "../../store";

export type ContactsReducerType = {
  ctypeFilter: ContactType;
  statusFilter: Nullable<string>;
  groupFilter: Nullable<string>;
  searchFilter: string;
  ownerFilter: Nullable<UserInterface>;
  ignoreProjects: boolean;
  filterAttrs: Record<string, AttributeFilterType>;
  filterStart: Nullable<Date>;
  filterStop: Nullable<Date>;
  filterInsertedStart: Nullable<Date>;
  filterInsertedStop: Nullable<Date>;
  mergeContacts: ContactInterface[];
  mergeContact: Nullable<ContactInterface>;
  predefinedFilters: { name: string; params: any }[];
  agendaFilters: Record<string, AttributeFilterType>;
  eventFilter: Nullable<ContactEventStatusType>;
};

const initialState: ContactsReducerType = {
  ctypeFilter: "MAIN",
  statusFilter: null,
  groupFilter: null,
  searchFilter: "",
  ownerFilter: null,
  ignoreProjects: false,
  filterAttrs: {},
  filterStart: null,
  filterStop: null,
  filterInsertedStart: null,
  filterInsertedStop: null,
  mergeContact: null,
  mergeContacts: [],
  predefinedFilters: [],
  agendaFilters: {},
  eventFilter: null,
};

const contactsSlice = createSlice({
  name: "contacts.contacts",
  initialState,
  reducers: {
    setCtypeFilter(state, action: PayloadAction<ContactType>) {
      state.ctypeFilter = action.payload;
    },

    setFilter(state, action: PayloadAction<string>) {
      state.searchFilter = action.payload;
    },

    setStatusFilter(state, action: PayloadAction<Nullable<string>>) {
      state.statusFilter = action.payload;
    },

    setGroupFilter(state, action: PayloadAction<Nullable<string>>) {
      state.groupFilter = action.payload;
    },

    setOwnerFilter(state, action: PayloadAction<Nullable<UserInterface>>) {
      state.ownerFilter = action.payload;
    },

    toggleIgnoreProject(state) {
      state.ignoreProjects = !state.ignoreProjects;
    },

    setFilterStartStop(state, action: PayloadAction<{ start: Nullable<Date>; stop: Nullable<Date> }>) {
      state.filterStart = action.payload.start;
      state.filterStop = action.payload.stop;
    },

    setInsertedStartStop(state, action: PayloadAction<{ start: Nullable<Date>; stop: Nullable<Date> }>) {
      state.filterInsertedStart = action.payload.start;
      state.filterInsertedStop = action.payload.stop;
    },

    setAttributeFilter(state, action: PayloadAction<{ name: string; value: Nilable<AttributeFilterType> }>) {
      if (!action.payload.value) {
        delete state.filterAttrs[action.payload.name];
      } else {
        state.filterAttrs[action.payload.name] = action.payload.value;
      }
    },

    setMergeContact(state, { payload }: PayloadAction<Nullable<ContactInterface>>) {
      state.mergeContact = payload;
    },

    addMergeContact(state, { payload }: PayloadAction<ContactInterface>) {
      state.mergeContacts.push(payload);
    },

    resetMerge(state) {
      state.mergeContacts = [];
      state.mergeContact = null;
    },

    addPredefinedFilter(state, action: PayloadAction<{ name: string; params: any }>) {
      state.predefinedFilters.push(action.payload);
    },

    setAgendaFilter(state, action: PayloadAction<{ name: string; value: Nilable<AttributeFilterType> }>) {
      if (!action.payload.value) {
        delete state.agendaFilters[action.payload.name];
      } else {
        state.agendaFilters[action.payload.name] = action.payload.value;
      }
    },

    removePredefinedFilter(state, action: PayloadAction<string>) {
      state.predefinedFilters = state.predefinedFilters.filter((item) => item.name !== action.payload);
    },

    setEventFilter(state, action: PayloadAction<Nullable<ContactEventStatusType>>) {
      state.eventFilter = action.payload;
    },

    resetFilters(state) {
      state.ctypeFilter = "MAIN";
      state.statusFilter = null;
      state.groupFilter = null;
      state.searchFilter = "";
      state.ownerFilter = null;
      state.ignoreProjects = false;
      state.filterAttrs = {};
      state.filterStart = null;
      state.filterStop = null;
      state.filterInsertedStart = null;
      state.filterInsertedStop = null;
      state.predefinedFilters = [];
      state.agendaFilters = {};
      state.eventFilter = null;
    },
  },
});

export const {
  setCtypeFilter,
  setFilter,
  setStatusFilter,
  setGroupFilter,
  setOwnerFilter,
  toggleIgnoreProject,
  setFilterStartStop,
  setInsertedStartStop,
  setAttributeFilter,
  setMergeContact,
  addMergeContact,
  resetMerge,
  addPredefinedFilter,
  removePredefinedFilter,
  setAgendaFilter,
  setEventFilter,
  resetFilters,
} = contactsSlice.actions;

export const canMerge = (session: SessionInterface, contacts: ContactsReducerType, contact: ContactInterface) =>
  may(session, "contacts", "merge", contact) &&
  contacts.mergeContact &&
  contacts.mergeContact.id !== contact.id &&
  contacts.mergeContacts.find((cnt) => cnt.id === contact.id) == null;

export const contactsSelector = (state: RootState) => state.contacts.contacts;

export default contactsSlice.reducer;
