import { useEffect, useState } from "react";

import { clsx } from "clsx";
import { Form, Formik, type FormikHelpers } from "formik";
import type { TFunction } from "i18next";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import { AuthenticationError } from "@api/session";

import { login } from "../../actions/sessionActions";
import ErrorMessage from "../../containers/ErrorMessage";
import FormikInput from "../../containers/FormikInput";
import handleError from "../../handleError";
import { useAppDispatch, useAppSelector } from "../../hooks";
import useTitle from "../../hooks/useTitle";
import { sessionSelector } from "../../store/sessionReducer";
import FlashMessagesList from "../flash";
import { addDangerFlash, addWarningFlash } from "../flash/flashReducer";

type ValuesType = {
  username: string;
  password: string;
  otp: string;
};

const initialValues = () => ({
  username: "",
  password: "",
  otp: "",
});

const validationSchema = (t: TFunction) =>
  Yup.object({
    username: Yup.string().trim().required(t("translation:signinform.username_missing")),
    password: Yup.string().trim().required(t("translation:signinform.password_missing")),
  });

export default function SignInForm() {
  const session = useAppSelector(sessionSelector);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["translation"]);
  const [otpNeeded, setOtpNeeded] = useState(false);

  useTitle(t("translation:signinform.title"));

  useEffect(() => {
    if (session.isAuthenticated) {
      navigate("/");
    }
  });

  async function handleSubmit(values: ValuesType, { setSubmitting }: FormikHelpers<ValuesType>) {
    try {
      setSubmitting(true);
      await dispatch(login(values.username, values.password, values.otp));
      navigate("/");
    } catch (e: any) {
      if (e.message === "OTP needed") {
        setOtpNeeded(true);
        setSubmitting(false);
        dispatch(addWarningFlash(t("translation:signinform.otp_needed")));
        return;
      }

      setSubmitting(false);
      dispatch(addDangerFlash(t("translation:signinform.wrong_login_data"), 5000));

      if (!(e instanceof AuthenticationError)) {
        handleError(e);
      }
    }
  }

  return (
    <Formik initialValues={initialValues()} validationSchema={validationSchema(t)} onSubmit={handleSubmit}>
      {({ isSubmitting }) => (
        <div className={clsx("TT-login-form-container", { "otp-needed": otpNeeded })}>
          <Form>
            <FlashMessagesList className="sign-in-form" />

            <label htmlFor="inputUsername" className="visually-hidden">
              {t("translation:signinform.username")}
            </label>
            <FormikInput
              id="inputUsername"
              type="text"
              name="username"
              className="username"
              placeholder={t("translation:signinform.username")}
              required
              autoFocus
            />
            <ErrorMessage path="username" className="visually-hidden" />

            <label htmlFor="inputPassword" className="visually-hidden">
              {t("translation:signinform.password")}
            </label>
            <FormikInput
              className="password"
              type="password"
              id="inputPassword"
              name="password"
              placeholder={t("translation:signinform.password")}
              required
            />
            <ErrorMessage path="password" className="visually-hidden" />

            {otpNeeded && (
              <>
                <label htmlFor="inputOtp">{t("translation:signinform.otpCode")}</label>
                <FormikInput
                  type="text"
                  id="inputOtp"
                  name="otp"
                  placeholder={t("translation:signinform.otpCode")}
                  required
                />
              </>
            )}

            <div className="d-grid">
              <Button type="submit" variant="primary" size="lg" disabled={isSubmitting}>
                {t("translation:signinform.login")}
              </Button>
            </div>
          </Form>
        </div>
      )}
    </Formik>
  );
}
