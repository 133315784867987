import React from "react";

import { Breadcrumb } from "react-bootstrap";
import { Link } from "react-router-dom";

type PropsType = {
  last: boolean;
  item: BreadcrumbItemInterface;
};

function TTBreadcrumbItem({ last, item }: PropsType) {
  return (
    <Breadcrumb.Item linkAs={Link} linkProps={{ to: item.to }} active={last}>
      {item.text}
    </Breadcrumb.Item>
  );
}

export default React.memo(TTBreadcrumbItem);
