import { featureEnabled, userFeatureEnabled } from "./helpers";

export interface DocumentMayInterface {
  (session: SessionInterface, path: "documents"): boolean;
  (session: SessionInterface, path: "documents", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "documents",
    action: "show" | "edit" | "delete" | "sign",
    doc: TTDocumentType,
  ): boolean;
}

const DocumentPermissions: PermissionType<TTDocumentType> = {
  documents: {
    index: ({ currentUser, currentProject }) => featureEnabled(currentUser, currentProject, "documents"),
    edit: ({ currentUser, currentProject }) =>
      featureEnabled(currentUser, currentProject, "documents") && userFeatureEnabled(currentUser, "documents/write"),
    delete: ({ currentUser, currentProject }) =>
      featureEnabled(currentUser, currentProject, "documents") && userFeatureEnabled(currentUser, "documents/write"),
    new: ({ currentUser, currentProject }) =>
      featureEnabled(currentUser, currentProject, "documents") && userFeatureEnabled(currentUser, "documents/new"),
    sign: ({ currentUser, currentProject }, document) =>
      featureEnabled(currentUser, currentProject, "documents") &&
      document.signatureType !== "NONE" &&
      !!document.missingSignatures.find((user) => user.id === currentUser.id),
  },
};

export default DocumentPermissions;
