import React from "react";

import * as Sentry from "@sentry/react";
import { IconContext } from "react-icons";
import { BrowserRouter, Route, Routes } from "react-router-dom";

import Loading from "../containers/Loading";
import { ReactLazyLoading } from "../containers/ReactLoading";
import useTitle from "../hooks/useTitle";
import { setContext } from "../sentry";
import AuthenticatedRoutes from "./AuthenticatedRoutes";
import FallbackComponent from "./FallbackComponent";
import SignInForm from "./session/SignInForm";
import EmploymentContract from "./staff/EpmloymentContract";

const ConfirmTodo = ReactLazyLoading(() => import("./ConfirmTodo"));
const ConfirmEvent = ReactLazyLoading(() => import("./ConfirmEvent"));
const StaffQuestionnaire = ReactLazyLoading(() => import("./staff/ChangeRequests/StaffQuestionnaire"));

export default function App() {
  useTitle("Termitool");

  return (
    <>
      <IconContext.Provider value={{ className: "react-icons" }}>
        <div id="root-wrapper" data-environment={process.env.NODE_ENV}>
          <BrowserRouter>
            <React.Suspense fallback={<Loading backdrop fullscreen absolute />}>
              <Sentry.ErrorBoundary fallback={FallbackComponent} beforeCapture={() => setContext()}>
                <Routes>
                  <Route path="/confirm-event" element={<ConfirmEvent />} />
                  <Route path="/confirm-todo" element={<ConfirmTodo />} />
                  <Route path="/change-request" element={<StaffQuestionnaire />} />
                  <Route path="/employment-contract/:id" element={<EmploymentContract />} />
                  <Route path="/login" element={<SignInForm />} />
                  <Route path="/*" element={<AuthenticatedRoutes />} />
                </Routes>
              </Sentry.ErrorBoundary>
            </React.Suspense>
          </BrowserRouter>
        </div>
      </IconContext.Provider>
    </>
  );
}
