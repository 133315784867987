import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../../store";

export interface DocumentsReducerInterface {
  customerId: Nullable<string>;
  projectId: Nullable<string>;
  relevantProjectId: Nullable<string>;
  search: string;
  type: Nullable<DocumentTypeType>;
  archived: Nullable<"true" | "false">;
  visible: boolean;
  modalVisible: boolean;
  start: Nullable<Date>;
  stop: Nullable<Date>;
  signatureMissing: boolean;
  detailedView: boolean;
}

const initialState: DocumentsReducerInterface = {
  customerId: null,
  projectId: null,
  relevantProjectId: null,
  search: "",
  type: null,
  archived: null,
  visible: true,
  modalVisible: false,
  start: null,
  stop: null,
  signatureMissing: false,
  detailedView: false,
};

const documentsSlice = createSlice({
  name: "documents",
  initialState,
  reducers: {
    setCustomerId(state, action: PayloadAction<Nullable<string>>) {
      state.customerId = action.payload;
      state.projectId = null;
    },
    setProjectId(state, action: PayloadAction<Nullable<string>>) {
      state.projectId = action.payload;
    },
    setRelevantProjectId(state, action: PayloadAction<Nullable<string>>) {
      state.relevantProjectId = action.payload;
    },
    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    setType(state, action: PayloadAction<Nullable<DocumentTypeType>>) {
      state.type = action.payload;
    },
    setArchived(state, action: PayloadAction<Nullable<"true" | "false">>) {
      state.archived = action.payload;
    },
    setVisible(state, action: PayloadAction<boolean>) {
      state.visible = action.payload;
    },
    setModalVisible(state, action: PayloadAction<boolean>) {
      state.modalVisible = action.payload;
    },
    setStart(state, action: PayloadAction<Nullable<Date>>) {
      state.start = action.payload;
    },
    setStop(state, action: PayloadAction<Nullable<Date>>) {
      state.stop = action.payload;
    },
    setSignatureMissing(state, action: PayloadAction<boolean>) {
      state.signatureMissing = action.payload;
    },
    toggleDetailedView(state) {
      state.detailedView = !state.detailedView;
    },
    resetFilters(_state) {
      return { ...initialState };
    },
  },
});

export const {
  setCustomerId,
  setProjectId,
  setSearch,
  setType,
  setArchived,
  setVisible,
  setModalVisible,
  setStart,
  setStop,
  setSignatureMissing,
  setRelevantProjectId,
  toggleDetailedView,
  resetFilters,
} = documentsSlice.actions;
export const documentsSelector = (state: RootState) => state.documents;
export default documentsSlice.reducer;
