import { useState } from "react";

import { Button, Form, OverlayTrigger, Popover } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { MdCloudUpload, MdImage } from "react-icons/md";
import { useSlate } from "slate-react";

import { PropsType } from ".";
import TTFormGroup from "../../../containers/TTFormGroup";
import { insertImage } from "./utils";

export default function ImageButton({ onImageUpload }: PropsType) {
  const editor = useSlate();
  const [show, setShow] = useState(false);
  const { t } = useTranslation(["slateEditor"]);

  async function uploadAndInsert(ev: React.ChangeEvent<HTMLInputElement>) {
    if (!onImageUpload) return;

    const newUrl = await onImageUpload(ev);

    if (!newUrl) return;
    insertImage(editor, newUrl);
    setShow(false);
  }

  const popover = (
    <Popover id="image-popover" className="TT-slate-image-popover">
      <Popover.Header as="h3">{t("slateEditor:image_popover.headline")}</Popover.Header>
      <Popover.Body>
        <Form.Label>{t("slateEditor:image_popover.upload_or_insert")}</Form.Label>

        {onImageUpload && (
          <TTFormGroup>
            <input
              className="TT-image-upload-input"
              type="file"
              id="image-upload"
              accept="image/*"
              onChange={uploadAndInsert}
            />
            <Button className="TT-image-upload-btn" size="sm" variant="secondary" as="label" htmlFor="image-upload">
              <MdCloudUpload /> {t("slateEditor:image_popover.choose_file")}
            </Button>
          </TTFormGroup>
        )}
      </Popover.Body>
    </Popover>
  );

  return (
    <OverlayTrigger show={show} trigger="click" placement="bottom" overlay={popover} onToggle={() => setShow(!show)}>
      <Button variant="light">
        <MdImage />
      </Button>
    </OverlayTrigger>
  );
}
