import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { endOfMonth, startOfMonth } from "date-fns";

import type { RootState } from "../../store";

type RosterReducerInterface = {
  groupId: string[];
  start: Date;
  stop: Date;
  day: Date;
  startHour: number;
  stopHour: number;
  userId: Nullable<string>;
  allCustomers: boolean;
  mobileView: boolean;
  standardAttendances: {
    search: string;
  };
};

const initialState: RosterReducerInterface = {
  groupId: [],
  start: startOfMonth(new Date()),
  stop: endOfMonth(new Date()),
  day: new Date(),
  startHour: 7,
  stopHour: 20,
  userId: null,
  allCustomers: false,
  mobileView: false,
  standardAttendances: {
    search: "",
  },
};

const rosterSlice = createSlice({
  name: "roster",
  initialState,
  reducers: {
    setGroupId(state, action: PayloadAction<string[]>) {
      state.groupId = action.payload;
    },
    setStartStop(state, action: PayloadAction<{ start: Date; stop: Date }>) {
      state.start = action.payload.start;
      state.stop = action.payload.stop;
    },
    setDay(state, action: PayloadAction<Date>) {
      state.day = action.payload;
    },
    setStartHour(state, action: PayloadAction<number>) {
      if (action.payload >= 0 && action.payload <= 23) {
        state.startHour = action.payload;
      }
    },
    setStopHour(state, action: PayloadAction<number>) {
      if (action.payload >= 0 && action.payload <= 23) {
        state.stopHour = action.payload;
      }
    },
    setUserId(state, action: PayloadAction<Nullable<string>>) {
      state.userId = action.payload;
    },
    setAllCustomers(state, action: PayloadAction<boolean>) {
      state.allCustomers = action.payload;
    },
    setMobileView(state, action: PayloadAction<boolean>) {
      state.mobileView = action.payload;
    },
    setStandardAttendancesSearch(state, { payload }: PayloadAction<string>) {
      state.standardAttendances.search = payload;
    },
  },
});

export const {
  setGroupId,
  setStartStop,
  setDay,
  setStartHour,
  setStopHour,
  setUserId,
  setAllCustomers,
  setMobileView,
  setStandardAttendancesSearch,
} = rosterSlice.actions;
export const rosterSelector = (state: RootState) => state.roster;
export default rosterSlice.reducer;
