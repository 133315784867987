import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../../../store";

export interface CoachingsReducerInterface {
  user: Nullable<UserInterface>;
  creator: Nullable<UserInterface>;
  customerId: Nullable<string>;
  start: Nullable<Date>;
  stop: Nullable<Date>;
}

const initialState: CoachingsReducerInterface = {
  user: null,
  creator: null,
  customerId: null,
  start: null,
  stop: null,
};

const coachingsSlice = createSlice({
  name: "coachings",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<Nullable<UserInterface>>) {
      state.user = action.payload;
    },
    setCreator(state, action: PayloadAction<Nullable<UserInterface>>) {
      state.creator = action.payload;
    },
    setCustomerId(state, { payload }: PayloadAction<Nullable<string>>) {
      state.customerId = payload;
    },
    setStart(state, { payload }: PayloadAction<Nullable<Date>>) {
      state.start = payload;
    },
    setStop(state, { payload }: PayloadAction<Nullable<Date>>) {
      state.stop = payload;
    },
    resetFilters(state) {
      state.user = null;
      state.creator = null;
      state.customerId = null;
      state.start = null;
      state.stop = null;
    },
  },
});

export const { setUser, setCreator, setCustomerId, setStart, setStop, resetFilters } = coachingsSlice.actions;
export const coachingsSelector = (state: RootState) => state.coachings;
export default coachingsSlice.reducer;
