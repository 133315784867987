import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { startOfMonth, subMonths } from "date-fns";

import type { RootState } from "../../../store";

type EmployeeCouponsReducerType = {
  search: string;
  month: Date;
};

const initialState: EmployeeCouponsReducerType = {
  search: "",
  month: subMonths(startOfMonth(new Date()), 1),
};

const employeeCouponsSlice = createSlice({
  name: "employeeCoupons",
  initialState,
  reducers: {
    setSearch(state, { payload }: PayloadAction<string>) {
      state.search = payload;
    },

    setMonth(state, { payload }: PayloadAction<Date>) {
      state.month = payload;
    },

    resetFilters(state) {
      state.search = "";
      state.month = startOfMonth(new Date());
    },
  },
});

export const { setSearch, resetFilters, setMonth } = employeeCouponsSlice.actions;
export const employeeCouponsSelector = (state: RootState) => state.employeeCoupons;
export default employeeCouponsSlice.reducer;
