import { RS_API_URI } from "@api/session";

import { queryString, sessionPrefix } from "./helpers";

export const mailsPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/mails`;
export const newMailPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/mails/new`;

export const replyMailPath = (session: LightSessionInterface, mail: MailInterface, qs: QueryStringType = null) =>
  `${sessionPrefix(session)}/mails/${mail.id}/reply${queryString(qs)}`;
export const forwardMailPath = (session: LightSessionInterface, mail: MailInterface, qs: QueryStringType = null) =>
  `${sessionPrefix(session)}/mails/${mail.id}/forward${queryString(qs)}`;
export const editMailPath = (session: LightSessionInterface, mail: MailInterface) =>
  `${sessionPrefix(session)}/mails/${mail.id}/edit`;

export const showMailAttachmentPath = (_session: LightSessionInterface, attachment: MailAttachmentInterface) =>
  `${RS_API_URI}/mails/attachments/${attachment.id}`;

export function showMailPath(session: LightSessionInterface, thread: MailThreadInterface, mail?: MailInterface): string;
export function showMailPath(session: LightSessionInterface, thread: undefined, mail: MailInterface): string;

export function showMailPath(
  session: LightSessionInterface,
  thread: MailThreadInterface | undefined,
  mail?: MailInterface,
) {
  let path = `${sessionPrefix(session)}/mails/${thread?.id || mail?.threadId}`;

  if (mail) {
    path += `#mail-${mail.id}`;
  }

  return path;
}
