import React, { type MemoExoticComponent } from "react";

import { Button, type ButtonProps } from "react-bootstrap";
import { MdCancel } from "react-icons/md";

const Btn: TTButtonProps = ({ children, ...props }) => (
  <Button variant="secondary" {...(props as ButtonProps)}>
    <MdCancel /> {children}
  </Button>
);

const CancelButton: MemoExoticComponent<TTButtonProps> = React.memo(Btn);

export default CancelButton;
