import { Dropdown, Nav, NavItem, NavLink } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { SUPPORTED_LANGUAGES } from "../../i18n";

const normalizeLang = (lang: Nilable<string>) => lang?.replace(/-[A-Z]+$/, "");

export default function LanguageChooser() {
  const { t, i18n } = useTranslation(["translation"]);

  function changeLanguage(lang: string) {
    i18n.changeLanguage(lang);
  }

  const lang = normalizeLang(i18n.language);

  if (!lang) return null;

  return (
    <Nav>
      <Dropdown as={NavItem} className="TT-language-chooser-dropdown">
        <Dropdown.Toggle as={NavLink}>{t(`translation:languages.${lang}`)}</Dropdown.Toggle>

        <Dropdown.Menu>
          {SUPPORTED_LANGUAGES.map((lang) => (
            <Dropdown.Item key={lang} onClick={() => changeLanguage(lang)}>
              {t(`translation:languages.${lang}`)}
            </Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </Nav>
  );
}
