import AcdCallsPermissions, { type AcdMayInterface } from "./AcdPermissions";
import AdminPermission, { type AdminMayInterface } from "./AdminPermissions";
import CalendarPermissions, { type CalendarMayInterface } from "./CalendarPermissions";
import ContactPermissions, { type ContactsMayInterface } from "./ContactPermissions";
import CustomerContactPermissions, { type CustomerContactMayInterface } from "./CustomerContactPermissions";
import CustomerFeedbackPermissions, { type CustomerFeedbacksMayInterface } from "./CustomerFeedbackPermissions";
import DigitalSignaturesPermissions, { type DigitalSignatureMayInterface } from "./DigitalSignaturePermissions";
import DocumentPermissions, { type DocumentMayInterface } from "./DocumentPermissions";
import FileExchangePermissions, { type FileExchangeMayInterface } from "./FileExchangePermissions";
import HotseatPermissions, { type HotseatMayInterface } from "./HotseatPremissions";
import InventoryPermissions, { type InventoryMayInterface } from "./InventoryPermissions";
import InvoicePermissions, { type InvoicesMayInterface } from "./InvoicePermissions";
import JobsPermissions, { type JobsMayInterface } from "./JobsPermissions";
import KnowledgebasePermissions, { type KnowledgebaseMayInterface } from "./KnowledgebasePermissions";
import MailsPermissions, { type MailsMayInterface } from "./MailsPermissions";
import MessagePermissions, { type MessageMayInterface } from "./MessagePermissions";
import ProjectNewsPermissions, { type ProjectNewsMayInterface } from "./ProjectNewsPremissions";
import ProjectPermissions, { type ProjectMayInterface } from "./ProjectPermissions";
import PunchlistPermissions, { type PunchlistMayInterface } from "./PunchlistPermissions";
import ReminderNotificationPermissions, {
  type ReminderNotificationsMayInterface,
} from "./ReminderNotificationPermissions";
import RosterPermissions, { type RosterMayInterface } from "./RosterPermissions";
import StaffPermissions, { type StaffMayInterface } from "./StaffPermissions";
import StatsPermissions, { type StatsMayInterface } from "./StatsPermissions";
import TicketPermissions, { type TicketMayInterface } from "./TicketPermissions";
import TodoPermissions, { type TodoMayInterface } from "./TodoPermissions";

export const abilities = {
  ...AdminPermission,
  ...ContactPermissions,
  ...CalendarPermissions,
  ...StatsPermissions,
  ...JobsPermissions,
  ...TodoPermissions,
  ...CustomerContactPermissions,
  ...ProjectPermissions,
  ...MailsPermissions,
  ...InvoicePermissions,
  ...KnowledgebasePermissions,
  ...StaffPermissions,
  ...MessagePermissions,
  ...AcdCallsPermissions,
  ...RosterPermissions,
  ...DocumentPermissions,
  ...TicketPermissions,
  ...PunchlistPermissions,
  ...InventoryPermissions,
  ...CustomerFeedbackPermissions,
  ...HotseatPermissions,
  ...ProjectNewsPermissions,
  ...FileExchangePermissions,
  ...ReminderNotificationPermissions,
  ...DigitalSignaturesPermissions,
};

export interface MayInterface
  extends AdminMayInterface,
    TodoMayInterface,
    TicketMayInterface,
    StatsMayInterface,
    StaffMayInterface,
    RosterMayInterface,
    PunchlistMayInterface,
    ProjectMayInterface,
    MessageMayInterface,
    MailsMayInterface,
    KnowledgebaseMayInterface,
    InvoicesMayInterface,
    InventoryMayInterface,
    HotseatMayInterface,
    DocumentMayInterface,
    CustomerFeedbacksMayInterface,
    CustomerContactMayInterface,
    ContactsMayInterface,
    CalendarMayInterface,
    AcdMayInterface,
    ProjectNewsMayInterface,
    JobsMayInterface,
    FileExchangeMayInterface,
    ReminderNotificationsMayInterface,
    DigitalSignatureMayInterface {}

type GenericPermissionFunction<T> = (session: SessionInterface, resource: Nilable<T>) => boolean;

export const may: MayInterface = <T>(
  session: SessionInterface,
  path: keyof typeof abilities,
  action?: string,
  resource?: Nullable<T>,
) => {
  if (!action) {
    action = "index";
  }

  if (abilities[path]) {
    const fun = (abilities[path]![action] || abilities[path]!.index || (() => false)) as GenericPermissionFunction<T>;
    return fun(session, resource);
  }

  return false;
};
