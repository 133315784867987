import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../../../../store";
import type { JobStatusType } from "./types";

type AdminJobsReducerType = {
  starts: Nullable<Date>;
  ends: Nullable<Date>;
  status: Nullable<JobStatusType>;
  worker: Nullable<string>;
};

const initialState: AdminJobsReducerType = {
  starts: null,
  ends: null,
  status: null,
  worker: null,
};

const adminJobsSlice = createSlice({
  name: "admin/jobs",
  initialState,
  reducers: {
    setStarts(state, { payload }: PayloadAction<Nullable<Date>>) {
      state.starts = payload;
    },
    setEnds(state, { payload }: PayloadAction<Nullable<Date>>) {
      state.ends = payload;
    },
    setStatus(state, { payload }: PayloadAction<Nullable<JobStatusType>>) {
      state.status = payload;
    },
    setWorker(state, { payload }: PayloadAction<Nullable<string>>) {
      state.worker = payload;
    },

    resetFilters(state) {
      state.starts = null;
      state.ends = null;
      state.status = null;
      state.worker = null;
    },
  },
});

export const { setStarts, setEnds, setStatus, setWorker, resetFilters } = adminJobsSlice.actions;
export const adminJobsSelector = (state: RootState) => state.admin.jobs;
export default adminJobsSlice.reducer;
