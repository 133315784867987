import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../../store";

type MessagesReducerType = {
  filterAttrs: Record<string, AttributeFilterType>;
  sender: Nullable<UserInterface>;
  start: Nullable<Date>;
  stop: Nullable<Date>;
  seen: Nullable<boolean>;
  search: string;
  customerId: Nullable<string>;
  projectId: Nullable<string>;
};

const initialState: MessagesReducerType = {
  search: "",
  filterAttrs: {},
  sender: null,
  start: null,
  stop: null,
  seen: null,
  customerId: null,
  projectId: null,
};

const messagesSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    setAttributeFilter(state, action: PayloadAction<{ name: string; value: Nilable<AttributeFilterType> }>) {
      if (!action.payload.value) {
        delete state.filterAttrs[action.payload.name];
      } else {
        state.filterAttrs[action.payload.name] = action.payload.value;
      }
    },
    setSender(state, action: PayloadAction<Nullable<UserInterface>>) {
      state.sender = action.payload;
    },
    setStart(state, { payload }: PayloadAction<Nullable<Date>>) {
      state.start = payload;
    },
    setStop(state, { payload }: PayloadAction<Nullable<Date>>) {
      state.stop = payload;
    },
    setSeen(state, { payload }: PayloadAction<Nullable<boolean>>) {
      state.seen = payload;
    },
    setSearch(state, { payload }: PayloadAction<string>) {
      state.search = payload;
    },
    setCustomerId(state, { payload }: PayloadAction<Nullable<string>>) {
      state.customerId = payload;
    },
    setProjectId(state, { payload }: PayloadAction<Nullable<string>>) {
      state.projectId = payload;
    },
    resetFilters(state) {
      state.sender = null;
      state.start = null;
      state.stop = null;
      state.seen = null;
      state.search = "";
      state.customerId = null;
      state.projectId = null;
    },
  },
});

export const {
  setAttributeFilter,
  setSender,
  setStart,
  setStop,
  setSeen,
  setSearch,
  setCustomerId,
  setProjectId,
  resetFilters,
} = messagesSlice.actions;
export const messagesSelector = (state: RootState) => state.messages;
export default messagesSlice.reducer;
