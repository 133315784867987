import React, { type MemoExoticComponent } from "react";

import { Button, type ButtonProps } from "react-bootstrap";
import { MdAddCircle } from "react-icons/md";

const Btn: TTButtonProps = ({ children, ...props }) => {
  return (
    <Button variant="secondary" {...(props as ButtonProps)}>
      <MdAddCircle /> {children}
    </Button>
  );
};

const AddButton: MemoExoticComponent<TTButtonProps> = React.memo(Btn);

export default AddButton;
