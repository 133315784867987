import { featureEnabled, isCustomerAdmin } from "./helpers";

export interface HotseatMayInterface {
  (session: SessionInterface, path: "hotseat/requests"): boolean;
  (session: SessionInterface, path: "hotseat/requests", action: "new" | "edit"): boolean;
  (session: SessionInterface, path: "hotseat/requests", action: "delete", item: HotseatRequestInterface): boolean;
}

const HotseatPermissions: PermissionType<HotseatRequestInterface> = {
  "hotseat/requests": {
    index: ({ currentUser, currentProject }) => featureEnabled(currentUser, currentProject, "hotseat/requests"),
    new: ({ currentUser, currentProject }) => featureEnabled(currentUser, currentProject, "hotseat/requests"),
    edit: ({ currentUser, currentCustomer, currentProject }) =>
      featureEnabled(currentUser, currentProject, "hotseat/requests") && isCustomerAdmin(currentUser, currentCustomer),
    delete: ({ currentUser, currentCustomer, currentProject }) =>
      featureEnabled(currentUser, currentProject, "hotseat/requests") && isCustomerAdmin(currentUser, currentCustomer),
  },
};

export default HotseatPermissions;
