import clsx from "clsx";
import { ButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { editDocumentPath } from "@path/document_paths";

import { may } from "../../../abilities";
import { DeleteButton, EditButton, ShowButton } from "../../../containers/buttons";
import { useAppSelector } from "../../../hooks";
import { sessionSelector } from "../../../store/sessionReducer";
import type { DeleteDocumentType, ShowDocumentType } from "../types";
import DocumentOverview from "./DocumentOverview";

type PropsType = {
  document: TTDocumentType;
  showDocument?: ShowDocumentType;
  deleteDocument?: DeleteDocumentType;
};

export default function DocumentsListItem({ document, deleteDocument, showDocument }: PropsType) {
  const session = useAppSelector(sessionSelector);
  const { t } = useTranslation(["translation"]);
  const currentSession = { ...session, currentCustomer: document.customer, currentProject: document.project };

  return (
    <li key={document.id} className={clsx({ visible: document.visible, not_visible: !document.visible })}>
      <DocumentOverview document={document} />

      <ButtonGroup size="sm" className="TT-documents-list-controls">
        {showDocument && (
          <ShowButton onClick={() => showDocument(document)}>{t("translation:global.details")}</ShowButton>
        )}
        {may(session, "documents", "edit", document) && (
          <EditButton as={Link} to={editDocumentPath(currentSession, document)}>
            {t("translation:global.edit")}
          </EditButton>
        )}
        {deleteDocument && may(session, "documents", "delete", document) && (
          <DeleteButton onClick={() => deleteDocument(document)}>{t("translation:global.delete")}</DeleteButton>
        )}
      </ButtonGroup>
    </li>
  );
}
