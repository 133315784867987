import { createElement } from "react";

import { useTranslation } from "react-i18next";

type PropsType = {
  data?: any[];
  tag?: string;
  message?: string;
};

const NoDataTag = ({ data, tag = "li", message = undefined }: PropsType) => {
  const { t } = useTranslation(["translation"]);

  if (data?.length) return null;

  if (!message) {
    message = t("translation:global.no_data");
  }

  return createElement(tag, { className: "TT-no-data" }, message);
};

export default NoDataTag;
