import { type TFunction } from "i18next";
import _ from "lodash";

import { EVENT_MUTATION, EVENT_TYPES_LIST_QUERY } from "@api/calendar";
import { CONTACT_MUTATION } from "@api/contacts/contacts";
import { TASK_MUTATION } from "@api/tasks";

import apolloClient from "../../../../apollo";
import { addDangerFlash, addSuccessFlash } from "../../../../components/flash/flashReducer";
import handleError, { MutationError } from "../../../../handleError";
import store from "../../../../store";
import { naiveDateTime } from "../../../../utils/dates";
import type { ValuesType } from "../types";

async function createFollowUp(session: SessionInterface, values: ValuesType, t: TFunction) {
  try {
    const { data: eventTypeData } = await apolloClient.query<EventTypesDataInterface, EventTypesVariablesInterface>({
      query: EVENT_TYPES_LIST_QUERY,
      variables: {
        customerId: session.currentCustomer.id,
        projectId: session.currentProject.id,
      },
    });

    const eventType = eventTypeData?.eventTypes.find((eventType) => eventType.type === "FOLLOWUP");

    if (!eventType) {
      store.dispatch(addDangerFlash("Oops! Es konnte kein Wiedervorlage-Event-Typ gefunden werden!"));
    }

    const { data } = await apolloClient.mutate<EventMutationInterface, EventMutationVariablesInterface>({
      mutation: EVENT_MUTATION,
      variables: {
        customerId: session.currentCustomer.id,
        projectId: session.currentProject.id,
        event: {
          subject: "Wiedervorlage",
          allDay: false,
          startTime: naiveDateTime(values.attrs.followUp),
          endTime: naiveDateTime(values.attrs.followUp),
          contactId: values.contactId,
          ownerId: session.currentUser.id,
          typeId: eventType?.id,
        },
      },
    });

    if (!data?.mutateEvent) {
      throw new MutationError();
    }
  } catch (e) {
    store.dispatch(addDangerFlash(t("translation:global.general_error")));
    handleError(e);
  }
}

export const saveTask = async (
  session: SessionInterface,
  existingTask: Nilable<TaskInterface>,
  states: ContactStatusInterface[],
  values: ValuesType,
  t: TFunction,
) => {
  if (values.attrs.followUp) {
    createFollowUp(session, values, t);
  }

  const { statusId, contact } = values;

  try {
    const { data } = await apolloClient.mutate<TaskMutationInterface>({
      mutation: TASK_MUTATION,
      variables: {
        values: _.omit(values, ["infos", "contact", "statusId", "caddfields"]),
        customerId: session.currentCustomer.id,
        projectId: session.currentProject.id,
        id: existingTask?.id,
      },
    });

    if (!data?.mutateTask) {
      throw new MutationError();
    }

    if (statusId && contact) {
      const status = states.find((s) => s.id === statusId);

      const { data } = await apolloClient.mutate<ContactMutationInterface>({
        mutation: CONTACT_MUTATION,
        variables: {
          customerId: session.currentCustomer.id,
          projectId: session.currentProject.id,
          id: contact.id,
          contact: { statusId: status?.id },
        },
      });

      if (!data?.mutateContact) {
        throw new MutationError();
      }
    }

    const transString = existingTask?.id ? "customerContacts:edit_form.updated" : "customerContacts:new_form.created";

    store.dispatch(addSuccessFlash(t(transString)));

    return data.mutateTask;
  } catch (e) {
    store.dispatch(addDangerFlash(t("translation:global.general_error")));
    handleError(e);
  }
};
