import { API_URI } from "@api/session";

import { queryString, sessionPrefix } from "./helpers";

export const todosPath = (session: LightSessionInterface, args: QueryStringType = null) =>
  `${sessionPrefix(session)}/todos${queryString(args)}`;
export const newTodoPath = (session: LightSessionInterface, args: QueryStringType = null) =>
  `${sessionPrefix(session)}/todos/new${queryString(args)}`;
export const editTodoPath = <T = Record<string, unknown>>(
  session: LightSessionInterface,
  todo: TodoInterface<T>,
  args: QueryStringType = null,
) => `${sessionPrefix(session)}/todos/${todo.id}/edit${queryString(args)}`;
export const showTodoPath = <T = Record<string, unknown>>(
  session: LightSessionInterface,
  todo: TodoInterface<T>,
  args: QueryStringType = null,
) => `${sessionPrefix(session)}/todos/${todo.id}${queryString(args)}`;

export const todoAttachmentUrl = ({ currentCustomer, currentProject }: LightSessionInterface, todo: TodoInterface) =>
  `${API_URI}/todos/${todo.id}?customer_id=${currentCustomer.id}&project_id=${currentProject.id}`;
