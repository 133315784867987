import clsx from "clsx";
import { getIn, useFormikContext } from "formik";

export default function ErrorMessage({ path, className }: { path: string; className?: string }) {
  const { errors, touched } = useFormikContext();
  const error = getIn(errors, path);

  if (!error || !getIn(touched, path)) {
    return null;
  }

  return <div className={clsx("TT-error-feedback", className)}>{error}</div>;
}
