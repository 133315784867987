import { Button, ButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import type { IconType } from "react-icons";
import {
  MdCode,
  MdFormatAlignCenter,
  MdFormatAlignJustify,
  MdFormatAlignLeft,
  MdFormatAlignRight,
  MdFormatBold,
  MdFormatItalic,
  MdFormatListBulleted,
  MdFormatListNumbered,
  MdFormatQuote,
  MdFormatUnderlined,
} from "react-icons/md";
import { Editor, Element as SlateElement, Transforms } from "slate";
import { useSlate } from "slate-react";

import HyperlinkButton from "./HyperlinkButton";
import ImageButton from "./ImageButton";
import { isMarkActive, toggleMark } from "./utils";

const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];

const isBlockActive = (editor: Editor, format: ElementType, blockType: BlockTypeType = "type") => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) => !Editor.isEditor(n) && SlateElement.isElement(n) && n[blockType] === format,
    }),
  );
  return !!match;
};

const toggleBlock = (editor: Editor, format: ElementType) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties: Partial<SlateElement>;
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? "paragraph" : isList ? "list-item" : format,
    };
  }
  Transforms.setNodes(editor, newProperties);

  Transforms.setNodes<SlateElement>(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

const BlockButton = ({
  format,
  icon: Icon,
  title,
  text,
}: {
  format: ElementType;
  icon?: IconType;
  text?: string;
  title: string;
}) => {
  const editor = useSlate();
  return (
    <Button
      title={title}
      variant="light"
      active={isBlockActive(editor, format, TEXT_ALIGN_TYPES.includes(format) ? "align" : "type")}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {Icon ? <Icon /> : text || title}
    </Button>
  );
};

const MarkButton = ({ format, icon: Icon, title }: { format: InlineTypes; icon: IconType; title: string }) => {
  const editor = useSlate();

  return (
    <Button
      title={title}
      variant="light"
      active={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();

        toggleMark(editor, format);
      }}
    >
      <Icon />
    </Button>
  );
};

export type PropsType = {
  onImageUpload?: (ev: React.ChangeEvent<HTMLInputElement>) => Promise<string | undefined>;
};

export default function Toolbar({ onImageUpload }: PropsType) {
  const { t } = useTranslation(["slateEditor"]);

  return (
    <div className="TT-slate-editor-toolbar">
      <ButtonGroup>
        <MarkButton title={t("slateEditor:bold")} format="bold" icon={MdFormatBold} />
        <MarkButton title={t("slateEditor:italic")} format="italic" icon={MdFormatItalic} />
        <MarkButton title={t("slateEditor:underline")} format="underline" icon={MdFormatUnderlined} />
        <MarkButton title={t("slateEditor:code")} format="code" icon={MdCode} />
      </ButtonGroup>

      <ButtonGroup>
        <HyperlinkButton />
        <BlockButton title={t("slateEditor:quote")} format="quote" icon={MdFormatQuote} />
        <BlockButton title={t("slateEditor:numbered-list")} format="numbered-list" icon={MdFormatListNumbered} />
        <BlockButton title={t("slateEditor:bulleted-list")} format="bulleted-list" icon={MdFormatListBulleted} />
        <ImageButton onImageUpload={onImageUpload} />
      </ButtonGroup>

      <ButtonGroup>
        <BlockButton title={t("slateEditor:align-left")} format="left" icon={MdFormatAlignLeft} />
        <BlockButton title={t("slateEditor:align-center")} format="center" icon={MdFormatAlignCenter} />
        <BlockButton title={t("slateEditor:align-right")} format="right" icon={MdFormatAlignRight} />
        <BlockButton title={t("slateEditor:align-justify")} format="justify" icon={MdFormatAlignJustify} />
      </ButtonGroup>

      <ButtonGroup>
        <BlockButton
          title={t("slateEditor:heading-one")}
          format="heading-one"
          text={t("slateEditor:heading-one-text")}
        />

        <BlockButton
          title={t("slateEditor:heading-two")}
          format="heading-two"
          text={t("slateEditor:heading-two-text")}
        />
        <BlockButton
          title={t("slateEditor:heading-three")}
          format="heading-three"
          text={t("slateEditor:heading-three-text")}
        />
      </ButtonGroup>
    </div>
  );
}
