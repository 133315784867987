import { featureEnabled, isCustomerAdmin, userFeatureEnabled } from "./helpers";

export interface PunchlistMayInterface {
  (session: SessionInterface, path: "punchlist"): boolean;
  (session: SessionInterface, path: "punchlist", action: "new"): boolean;
  (
    session: SessionInterface,
    path: "punchlist",
    action: "show" | "edit" | "delete" | "owner",
    group: PunchlistEntryType,
  ): boolean;
}

const PunchlistPermissions: PermissionType<PunchlistEntryType> = {
  punchlist: {
    index: ({ currentUser, currentProject }) => featureEnabled(currentUser, currentProject, "punchlist"),
    edit: ({ currentUser, currentCustomer, currentProject }) =>
      featureEnabled(currentUser, currentProject, "punchlist") &&
      (isCustomerAdmin(currentUser, currentCustomer) || userFeatureEnabled(currentUser, "punchlist/write")),
    delete: ({ currentUser, currentCustomer, currentProject }) =>
      featureEnabled(currentUser, currentProject, "punchlist") &&
      (isCustomerAdmin(currentUser, currentCustomer) || userFeatureEnabled(currentUser, "punchlist/write")),
    new: ({ currentUser, currentCustomer, currentProject }) =>
      featureEnabled(currentUser, currentProject, "punchlist") &&
      (isCustomerAdmin(currentUser, currentCustomer) || userFeatureEnabled(currentUser, "punchlist/write")),
  },
};

export default PunchlistPermissions;
