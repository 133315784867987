import { sessionPrefix } from "./helpers";

export const rosterPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/roster`;

export const rosterAttendanceTypesPath = (session: LightSessionInterface) =>
  `${sessionPrefix(session)}/roster/attendance-types`;
export const newRosterAttendanceTypePath = (session: LightSessionInterface) =>
  `${sessionPrefix(session)}/roster/attendance-types/new`;
export const editRosterAttendanceTypePath = (session: LightSessionInterface, type: RosterAttendanceTypeInterface) =>
  `${sessionPrefix(session)}/roster/attendance-types/${type.id}/edit`;

export const rosterTasksPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/roster/tasks`;
export const newRosterTaskPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/roster/tasks/new`;
export const editRosterTaskPath = (session: LightSessionInterface, task: RosterTaskInterface) =>
  `${sessionPrefix(session)}/roster/tasks/${task.id}/edit`;

export const rosterGroupsPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/roster/groups`;
export const newRosterGroupPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/roster/groups/new`;
export const editRosterGroupPath = (session: LightSessionInterface, group: RosterGroupInterface) =>
  `${sessionPrefix(session)}/roster/groups/${group.id}/edit`;

export const rosterStandardAttendancesPath = (session: LightSessionInterface) =>
  `${sessionPrefix(session)}/roster/standard-attendances`;
export const newRosterStandardAttendancePath = (session: LightSessionInterface) =>
  `${sessionPrefix(session)}/roster/standard-attendances/new`;
export const editRosterStandardAttendancePath = (
  session: LightSessionInterface,
  attendance: RosterStandardAttendanceInterface,
) => `${sessionPrefix(session)}/roster/standard-attendances/${attendance.id}/edit`;

export const rosterStandardDaysPath = (session: LightSessionInterface) =>
  `${sessionPrefix(session)}/roster/standard-days`;
export const userRosterStandardDayPath = (session: LightSessionInterface, user: UserInterface) =>
  `${sessionPrefix(session)}/roster/standard-days/${user.id}`;
export const editRosterStandardDayPath = (session: LightSessionInterface, day: RosterStandardDayInterface) =>
  `${sessionPrefix(session)}/roster/standard-days/${day.id}/edit`;

export const rosterAttendancePlanningPath = (session: LightSessionInterface) =>
  `${sessionPrefix(session)}/roster/attendance-planning`;

export const rosterDayPlanningPath = (session: LightSessionInterface) =>
  `${sessionPrefix(session)}/roster/day-planning`;

export const rosterDayPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/roster/day`;
export const rosterMonthPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/roster/month`;
