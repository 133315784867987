import { useMutation } from "@apollo/client";
import { useFormikContext } from "formik";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { DOCUMENT_ATTACHMENT_MUTATION } from "@api/documents";
import { documentAttachmentPath } from "@path/document_paths";

import ErrorMessage from "../../../../containers/ErrorMessage";
import TTFormGroup from "../../../../containers/TTFormGroup";
import handleError, { MutationError } from "../../../../handleError";
import { useAppDispatch } from "../../../../hooks";
import { addDangerFlash } from "../../../flash/flashReducer";
import SlateEditor from "../../../SlateEditor";
import type { ValuesType } from "../../types";
import { DocumentFormProps } from "../../utils";
import Preview from "./Preview";

export default function WorkingInstruction(props: DocumentFormProps) {
  const { values, setFieldValue } = useFormikContext<ValuesType>();
  const { t } = useTranslation(["documents"]);

  function changeValue(value: any) {
    setFieldValue("document.content", value);
  }

  const [mutateDocumentAttachment] = useMutation<DocumentAttachmentMutationInterface>(DOCUMENT_ATTACHMENT_MUTATION);
  const dispatch = useAppDispatch();
  const document = props.document;

  async function uploadImage(ev: React.ChangeEvent<HTMLInputElement>) {
    try {
      if (!ev?.target?.files) return;

      const { data } = await mutateDocumentAttachment({
        variables: {
          customerId: document.customerId,
          projectId: document.projectId,
          documentId: document.id,
          id: document.attachments[0]?.id,
          values: {
            file: ev.target.files[0],
          },
        },
      });

      if (!data?.mutateDocumentAttachment) {
        throw new MutationError();
      }

      return documentAttachmentPath(data.mutateDocumentAttachment);
    } catch (e) {
      dispatch(addDangerFlash(t("translation:global.general_error")));
      handleError(e);
    }
  }

  return (
    <>
      <fieldset>
        <legend>{t("documents:form.content")}</legend>

        <TTFormGroup>
          <Form.Label htmlFor="document.content">{t("documents:field_names.content")}</Form.Label>
          <SlateEditor
            id="document-content-editor"
            value={values.document.content}
            onChange={changeValue}
            onImageUpload={uploadImage}
          />
          <ErrorMessage path="document.content" />
        </TTFormGroup>
      </fieldset>

      <Preview {...props} />
    </>
  );
}
