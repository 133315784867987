import { useEffect } from "react";

import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from ".";
import { sessionSelector } from "../store/sessionReducer";
import { addBreadcrumbItem, removeBreadcrumbItem } from "../store/sessionReducer";

export default function useI18nBreadcrumb<T>(
  identifier: string,
  fun: I18nBreadcrumbCallbackType<T>,
  resource?: BreadcrumbResourceType<T>,
  namespaces: string[] = ["translation"],
) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(namespaces);
  const { currentProject, currentCustomer, currentUser } = useAppSelector(sessionSelector);
  const dep = resource && typeof (resource as any).toString ? (resource as any).toString() : resource;

  useEffect(() => {
    const item = fun(t, { currentCustomer, currentProject, currentUser } as SessionInterface, resource);
    dispatch(addBreadcrumbItem(item));

    return () => {
      dispatch(removeBreadcrumbItem(identifier));
    };
  }, [fun, dispatch, identifier, currentCustomer, currentProject, currentUser, dep, resource, t]);
}
