import { ApolloCache } from "@apollo/client";
import _ from "lodash";

export function updateDeletion<T = any>(cache: ApolloCache<T>, id: Nilable<string>, field: string, broadcast = true) {
  if (!id) {
    return;
  }

  cache.modify({
    fields: {
      [field]: (list, { readField }) => {
        return _.filter(list as any[], (obj) => readField("id", obj) !== id);
      },
    },
    broadcast,
  });
}

interface IdInterface {
  id: string;
  [key: string]: any;
  [key: number]: any;
}

export function updateMutationList(
  cache: ApolloCache<any>,
  object: Nilable<IdInterface>,
  field: string,
  broadcast = true,
) {
  if (!object?.id) {
    return;
  }

  cache.modify({
    fields: {
      [field]: (list, { readField }) => {
        const idx = _.findIndex<any>(list as any[], (obj) => readField("id", obj) === object.id);

        if (idx === -1) {
          return [...(list as any[]), object];
        }

        return [...(list as any[]).slice(0, idx), object, ...(list as any[]).slice(idx + 1)];
      },
    },
    broadcast,
  });
}
