import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { endOfISOWeek, startOfISOWeek } from "date-fns";

import type { RootState } from "@/store";

export interface LateNotesReducerInterface {
  starts: Nullable<Date>;
  ends: Nullable<Date>;
}

const initialState: LateNotesReducerInterface = {
  starts: startOfISOWeek(new Date()),
  ends: endOfISOWeek(new Date()),
};

const lateNotesSlice = createSlice({
  name: "lateNotes",
  initialState,
  reducers: {
    resetFilters(state) {
      state.starts = startOfISOWeek(new Date());
      state.ends = endOfISOWeek(new Date());
    },

    setStarts(state, { payload }: PayloadAction<Nullable<Date>>) {
      state.starts = payload;
    },
    setEnds(state, { payload }: PayloadAction<Nullable<Date>>) {
      state.ends = payload;
    },
  },
});

export const { resetFilters, setStarts, setEnds } = lateNotesSlice.actions;
export const lateNotesSelector = (state: RootState) => state.lateNotes;
export default lateNotesSlice.reducer;
