import {
  addDays,
  addHours,
  differenceInDays,
  endOfDay,
  endOfISOWeek,
  endOfMonth,
  endOfYear,
  isBefore,
  setHours,
  startOfDay,
  startOfISOWeek,
  startOfMonth,
  startOfYear,
  subDays,
} from "date-fns";
import EmailAddressParser from "email-addresses";
import { getIn } from "formik";
import _ from "lodash";

import { formatPercentage } from "./number_format";

type PeriodTodayType = { name: "today" };
type PeriodYesterdayType = { name: "yesterday" };
type Period7DaysType = { name: "7days" };
type PeriodWeekType = { name: "week"; start: Date };
type Period30DaysType = { name: "30days" };
type PeriodMonthType = { name: "month"; start: Date };
type PeriodYearType = { name: "year"; start: Date };
type PeriodDateType = { name: "date"; start: Date; stop: Date };

type PeriodType =
  | PeriodTodayType
  | PeriodYesterdayType
  | Period7DaysType
  | PeriodWeekType
  | Period30DaysType
  | PeriodMonthType
  | PeriodYearType
  | PeriodDateType;
type PeriodResultType = { start: Date; stop: Date };

export function getPeriodStartAndStop(period: PeriodType): PeriodResultType {
  let start, stop;

  switch (period.name) {
    case "yesterday":
      start = startOfDay(subDays(new Date(), 1));
      stop = endOfDay(start);
      break;

    case "7days":
      start = startOfDay(subDays(new Date(), 7));
      stop = endOfDay(new Date());
      break;

    case "week":
      start = startOfISOWeek(period.start);
      stop = endOfISOWeek(start);
      break;

    case "30days":
      stop = endOfDay(new Date());
      start = startOfDay(subDays(stop, 30));
      break;

    case "month":
      start = startOfMonth(period.start);
      stop = endOfMonth(start);
      break;

    case "year":
      start = startOfYear(period.start);
      stop = endOfYear(start);
      break;

    case "date":
      start = startOfDay(period.start);
      stop = endOfDay(period.stop);
      break;

    default:
      start = startOfDay(new Date());
      stop = endOfDay(new Date());
  }

  return { start, stop };
}

export const getFormat = (start: Date, stop: Date) => (differenceInDays(stop, start) < 1 ? "HH:mm" : "dd.MM.");
export const getGroup = (start: Date, stop: Date) => (differenceInDays(stop, start) < 1 ? "hour" : "day");

export const getDateRange = (start: Date, stop: Date, startHour = 7, stopHour = 18) => {
  const categories: Date[] = [];
  const group = getGroup(start, stop);
  const fun = group === "hour" ? addHours : addDays;

  if (group === "hour") {
    start = setHours(start, startHour);
    stop = setHours(stop, stopHour);
  }

  for (; isBefore(start, stop); ) {
    categories.push(start);
    start = fun(start, 1);
  }

  return categories;
};

export function percentageOf(part: number, total: number) {
  if (total === 0) {
    return 0;
  }

  return (part * 100) / total;
}

export function percentageOfFormatted(part: number, total: number, percentageWhenZero: number | string = 1) {
  if (total === 0) {
    if (part === 0) {
      if (typeof percentageWhenZero === "string") return percentageWhenZero;
      formatPercentage(percentageWhenZero);
    }
    return formatPercentage(0);
  }

  return formatPercentage(part / total);
}

export function percentageFmt(part: number, basePart: number, placeholderWhenZero: string = "–") {
  if (part + basePart === 0) {
    if (part === 0) return placeholderWhenZero;
    return formatPercentage(0);
  }

  return formatPercentage(part / (part + basePart));
}

export function saveDivision(part: Nilable<number>, basePart: Nilable<number>) {
  if (!basePart || !part) return 0;
  return part / basePart;
}

export const getPeerNo = (event: AcdCallInfoType | undefined): string | null => {
  if (!event) {
    return null;
  }

  const no = _.find(event.no, (no) => no.type === "peer");
  if (no) {
    return no.e164;
  }

  return null;
};

export const getThisNo = (event: AcdCallInfoType | undefined): string | null => {
  if (!event) return null;

  const no = _.find(event.no, (no) => no.type === "this");
  if (no) {
    return no.e164;
  }

  return null;
};

export const getForeignNo = (event: AcdCallInfoType | undefined): string | null => {
  if (!event) return null;

  if ((event.state & 0x80) === 0) {
    return getThisNo(event);
  }

  return getPeerNo(event);
};

export const getLocalNo = (event: AcdCallInfoType | undefined): string | null => {
  if (!event) return null;

  if ((event.state & 0x80) === 0) {
    return getPeerNo(event);
  }

  return getThisNo(event);
};

export const getForeignNoFromHistory = (call: AcdCallRecordType): string => {
  if (call.direction === "INBOUND") {
    return call.initiator;
  }

  return call.calledNo;
};

export const sessionLike = ({
  customer,
  project,
}: {
  customer: CustomerInterface;
  project: BaseProjectInterface;
}): SessionInterface =>
  ({
    currentCustomer: customer,
    currentProject: project,
  }) as SessionInterface;

export const fieldInvalid = (errors: object, touched: object, path: string) =>
  !!(getIn(errors, path) && getIn(touched, path));
export const fieldValid = (errors: object, touched: object, path: string) =>
  !!(!getIn(errors, path) && getIn(touched, path));

export const itemByValue = (
  value: any,
  options: OptionProp[],
  isMulti: boolean = false,
  fallback: boolean = false,
): OptionProp | undefined => {
  if (!value) {
    return undefined;
  }

  if (isMulti) {
    if (!_.isArray(value)) value = [];
    return value.map((val: any) => {
      const v = _.find(options, (o) => _.isEqual(o.value, val));

      if (fallback && !v) {
        return { label: val, value: val };
      }

      return v;
    });
  } else {
    const v = _.find(options, (o) => o.value === value);

    if (fallback && !v) {
      return { label: value, value };
    }

    return v;
  }
};

export const isActive = (location: string, path: string) =>
  new RegExp("^" + path + "($|/)").test(location.replace(/^\/[\w0-9-]+\/[\w0-9-]+/, ""));

export const isActiveExact = (location: string, path: string) =>
  new RegExp(path + "$").test(location.replace(/^\/[\w0-9-]+\/[\w0-9-]+/, ""));

export const parseColor = (color: string): [number, number, number] | undefined => {
  let m = color.match(/^#([0-9a-f]{3})$/i);
  if (m && m[1]) {
    // in three-character format, each value is multiplied by 0x11 to give an
    // even scale from 0x00 to 0xff
    return [
      parseInt(m[1].charAt(0), 16) * 0x11,
      parseInt(m[1].charAt(1), 16) * 0x11,
      parseInt(m[1].charAt(2), 16) * 0x11,
    ];
  }

  m = color.match(/^#([0-9a-f]{6})$/i);
  if (m && m[1]) {
    return [parseInt(m[1].substr(0, 2), 16), parseInt(m[1].substr(2, 2), 16), parseInt(m[1].substr(4, 2), 16)];
  }

  m = color.match(/^rgb\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*\)$/i);
  if (m) {
    return [parseInt(m[1], 10), parseInt(m[2], 10), parseInt(m[3], 10)];
  }
};

export const transparentColor = (color: Nilable<string>, transparency = 0.3) => {
  if (!color) return;
  const parsed = parseColor(color);

  if (!parsed) return;

  return `rgba(${parsed[0]}, ${parsed[1]}, ${parsed[2]}, ${transparency})`;
};

export const lightenColor = ([R, G, B]: [number, number, number], percent: number) => {
  R = Math.trunc((R * (100 + percent)) / 100);
  G = Math.trunc((G * (100 + percent)) / 100);
  B = Math.trunc((B * (100 + percent)) / 100);

  R = R < 255 ? R : 255;
  G = G < 255 ? G : 255;
  B = B < 255 ? B : 255;

  return [R, G, B];
};

export function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined;
}

/* eslint-disable @typescript-eslint/no-empty-object-type */

export function notBlank<T>(value: T | [] | {} | null | undefined | "" | 0 | false): value is T {
  if (value && Object.hasOwn(value, "length") && (value as { length: number }).length > 0) {
    return true;
  }

  if (value && Object.hasOwn(value, "size") && (value as { size: number }).size > 0) {
    return true;
  }

  return value !== null && value !== undefined && value !== "" && value !== 0 && value !== false;
}

export function blank<T>(
  value: T | [] | {} | null | undefined | "" | 0 | false,
): value is [] | {} | null | undefined | "" | 0 | false {
  if (value && Object.hasOwn(value, "length") && (value as { length: number }).length === 0) {
    return true;
  }

  if (value && Object.hasOwn(value, "size") && (value as { size: number }).size === 0) {
    return true;
  }

  return value === null || value === undefined || value === "" || value === 0 || value === false;
}

/* eslint-enable @typescript-eslint/no-empty-object-type */

export const isNil = (v: undefined | null | typeof NaN | any): v is undefined | null | typeof NaN =>
  v === undefined || v === null || isNaN(v);

export const isBlob = (value: Blob | File | any): value is Blob | File =>
  value && typeof value.size === "number" && typeof value.type === "string" && typeof value.slice === "function";

export const isFile = (value: File | any): value is File =>
  isBlob(value) && typeof (value as File).lastModified === "number" && typeof (value as File).name === "string";

export const omit = <T extends object, K extends keyof T>(value: T, keys: ReadonlyArray<K>): Omit<T, K> => {
  const copy = { ...value };
  keys.forEach((key) => delete copy[key]);
  return copy;
};

export const cleanEmail = (str: string) => {
  const parsed = EmailAddressParser.parseOneAddress(str);
  if (!parsed) {
    return str;
  }

  // due to typing errors in the module we need this cast :(
  return (parsed as any).address;
};

export const truncateWords = (str: string, max: number) => {
  if (str.length <= max) {
    return str;
  }

  const words = str.split(/\s+/);
  const result = words.slice(0, max).join(" ");

  return result.trim() + "…";
};

export const optsFromTranslationDict = <T extends string>(dict: Record<T, string>): { label: string; value: T }[] =>
  _.map(dict, (label, value) => ({ value: value as T, label }));

export const strToFloat = (input: string | number | null): number | null => {
  if (input === null) {
    return null;
  }

  if (typeof input === "number") {
    return input;
  }

  return parseFloat(input.replace(/,/, "."));
};
