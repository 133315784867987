import { useState } from "react";

import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { SUPPORTED_LANGUAGES } from "../../../../i18n";

const normalizeLang = (lang: Nilable<string>) => lang?.replace(/-[A-Z]+$/, "");

export default function LanguageChooser() {
  const { t, i18n } = useTranslation(["translation"]);
  const [showLanguages, setShowLanguages] = useState(false);

  function toggle() {
    setShowLanguages(!showLanguages);
  }

  function changeLanguage(lang: string) {
    i18n.changeLanguage(lang);
  }

  const lang = normalizeLang(i18n.language);

  if (!lang) return null;

  return (
    <Dropdown>
      <Dropdown.Toggle className="dropdown-item" onClick={toggle} key="lang-chooser">
        {t(`translation:languages.${lang}`)}
      </Dropdown.Toggle>

      {showLanguages &&
        SUPPORTED_LANGUAGES.filter((lg) => lg !== lang).map((lg) => (
          <Dropdown.Item key={lg} onClick={() => changeLanguage(lg)}>
            {t(`translation:languages.${lg}`)}
          </Dropdown.Item>
        ))}
    </Dropdown>
  );
}
