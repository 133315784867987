import "./styles/app.scss";
import "react-calendar/dist/Calendar.css";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/Page/TextLayer.css";

import { ApolloProvider } from "@apollo/client";
import * as Sentry from "@sentry/react";

import { createRoot } from "react-dom/client";
import { pdfjs } from "react-pdf";
import { Provider } from "react-redux";

import { refreshLogin, setCurrentUser } from "./actions/sessionActions";
import apolloClient from "./apollo";
import { getAuthorizationToken, setAuthorizationToken } from "./authorization_token";
import App from "./components/App";
//import * as serviceWorker from './serviceWorker';
import "./i18n";
import "./socketHandlers";
import store from "./store";
import "./TTSocket";

const token = getAuthorizationToken();
if (token) {
  setAuthorizationToken(token);
  store.dispatch(refreshLogin(apolloClient));
} else {
  store.dispatch(setCurrentUser(null));
}

const renderApp = (Component: () => JSX.Element) => {
  const container = document.getElementById("root");
  const root = createRoot(container!);

  root.render(
    <ApolloProvider client={apolloClient}>
      <Provider store={store}>
        <Component />
      </Provider>
    </ApolloProvider>,
  );
};

renderApp(App);

Sentry.init({
  dsn: "https://dcdb67e3badda88614892c61a6445cd6@o254619.ingest.us.sentry.io/4507814111281152",
  integrations: [Sentry.browserTracingIntegration()],
  enabled: process.env.NODE_ENV === "production",
  replaysSessionSampleRate: 0.0,
  replaysOnErrorSampleRate: 0.0,
  autoSessionTracking: false,
  release: import.meta.env.VITE_VERSION,
  environment: process.env.NODE_ENV,
});

pdfjs.GlobalWorkerOptions.workerSrc = `https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
//serviceWorker.unregister();
