import { gql, useMutation } from "@apollo/client";
import { Form, Formik, type FormikHelpers } from "formik";
import type { TFunction } from "i18next";
import { Form as BsForm, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";

import { SESSION_USER_FRAGMENT } from "@api/session";

import { SaveButton } from "../../containers/buttons";
import ErrorMessage from "../../containers/ErrorMessage";
import FormikInput from "../../containers/FormikInput";
import TTFormGroup from "../../containers/TTFormGroup";
import handleError, { MutationError } from "../../handleError";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { sessionSelector, setCurrentUser } from "../../store/sessionReducer";
import { addDangerFlash, addSuccessFlash } from "../flash/flashReducer";
import TTModal from "../TTModal";

type ValuesType = {
  password: string;
  passwordConfirm: string;
};

const USER_PASSWORD_MUTATION = gql`
  mutation mutateUserPassword($password: String!) {
    mutateUserPassword(password: $password) {
      ...SessionUserFragment

      profiles {
        id
        name
      }
    }
  }

  ${SESSION_USER_FRAGMENT}
`;

const validationSchema = (t: TFunction) =>
  Yup.object({
    password: Yup.string()
      .required(t("admin:users.profile.password_missing"))
      .min(12, t("admin:users.profile.password_to_short")),
    passwordConfirm: Yup.string()
      .nullable()
      .oneOf([Yup.ref("password"), undefined], t("admin:users.profile.passwords_do_not_match"))
      .required(t("admin:users.profile.password_confirmation_missing")),
  });

export default function PasswordResetModal() {
  const dispatch = useAppDispatch();
  const [mutateUserPassword] = useMutation<PasswordUserMutationInterface>(USER_PASSWORD_MUTATION);
  const { t } = useTranslation(["admin"]);
  const { currentUser } = useAppSelector(sessionSelector);

  async function updateUserPassword(values: ValuesType, { setSubmitting }: FormikHelpers<ValuesType>) {
    setSubmitting(true);

    try {
      const { data } = await mutateUserPassword({
        variables: {
          password: values.password,
        },
      });

      if (!data?.mutateUserPassword) {
        throw new MutationError();
      }

      dispatch(setCurrentUser(data.mutateUserPassword));
      dispatch(addSuccessFlash(t("admin:users.profile.password_changed")));
    } catch (e) {
      setSubmitting(false);
      dispatch(addDangerFlash(t("translation:global.general_error")));
      handleError(e);
    }
  }

  if (!currentUser.passwordReset) return null;

  return (
    <TTModal show={true} size="lg">
      <Formik
        initialValues={{ password: "", passwordConfirm: "" }}
        validationSchema={validationSchema(t)}
        onSubmit={updateUserPassword}
      >
        {({ isSubmitting }) => (
          <Form>
            <Modal.Header>
              <Modal.Title>{t("admin:users.profile.change_password")}</Modal.Title>
            </Modal.Header>

            <Modal.Body>
              <BsForm.Label htmlFor="password">{t("admin:users.profile.change_your_password")}</BsForm.Label>

              <TTFormGroup>
                <BsForm.Label htmlFor="password">{t("admin:users.field_names.password")}</BsForm.Label>
                <FormikInput name="password" id="password" type="password" />
                <ErrorMessage path="password" />
              </TTFormGroup>

              <TTFormGroup>
                <BsForm.Label htmlFor="passwordConfirm">{t("admin:users.field_names.password_confirm")}</BsForm.Label>
                <FormikInput name="passwordConfirm" id="passwordConfirm" type="password" />
                <ErrorMessage path="passwordConfirm" />
              </TTFormGroup>
            </Modal.Body>

            <Modal.Footer>
              <SaveButton type="submit" disabled={isSubmitting}>
                {t("translation:global.save")}
              </SaveButton>
            </Modal.Footer>
          </Form>
        )}
      </Formik>
    </TTModal>
  );
}
