import { createSlice, type PayloadAction } from "@reduxjs/toolkit";
import { endOfDay, startOfDay } from "date-fns";

import type { RootState } from "../../../store";

export type AcdCallsReducerType = {
  starts: Date;
  stops: Nullable<Date>;
  result: Nullable<AcdCallResultType>;
  status: Nilable<TtAcdCallInterface["status"]>;
  direction: Nullable<AcdCallDirectionType>;
};

const initialState: AcdCallsReducerType = {
  starts: startOfDay(new Date()),
  stops: endOfDay(new Date()),
  result: "NONE",
  direction: "INBOUND",
  status: null,
};

const acdCallsSlice = createSlice({
  name: "acdCalls",
  initialState,
  reducers: {
    setStarts(state, action: PayloadAction<Date>) {
      state.starts = action.payload;
    },

    setStops(state, action: PayloadAction<Date>) {
      state.stops = action.payload;
    },

    setResult(state, action: PayloadAction<Nullable<AcdCallResultType>>) {
      state.result = action.payload;
    },

    setStatus(state, action: PayloadAction<Nullable<TtAcdCallInterface["status"]>>) {
      state.status = action.payload;
    },

    setDirection(state, action: PayloadAction<Nullable<AcdCallDirectionType>>) {
      state.direction = action.payload;
    },

    resetFilters(state) {
      state.starts = startOfDay(new Date());
      state.stops = endOfDay(new Date());
      state.result = "NONE";
      state.direction = "INBOUND";
    },
  },
});

export const { setStarts, setStops, setResult, setStatus, setDirection, resetFilters } = acdCallsSlice.actions;
export const acdCallsSelector = (state: RootState) => state.acdCalls;
export default acdCallsSlice.reducer;
