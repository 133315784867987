import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../../../store";

type UsersReducerType = {
  filter: string;
  showInheritancePopup: boolean;
  user: Nullable<UserInterface>;
  active: Nullable<boolean>;
  teamId: Nullable<string>;
  role: Nullable<string>;
  projectIds: string[];
};

const initialState: UsersReducerType = {
  filter: "",
  showInheritancePopup: false,
  user: null,
  active: null,
  teamId: null,
  role: null,
  projectIds: [],
};

const usersSlice = createSlice({
  name: "admin/users",
  initialState,
  reducers: {
    setFilter(state, action: PayloadAction<string>) {
      state.filter = action.payload;
    },
    setShowInheritancePopup(state, action: PayloadAction<[boolean, Nullable<UserInterface>]>) {
      const [popupState, user] = action.payload;
      state.showInheritancePopup = popupState;
      state.user = user;
    },
    setActive(state, { payload }: PayloadAction<Nullable<boolean>>) {
      state.active = payload;
    },
    setTeamId(state, action: PayloadAction<Nullable<string>>) {
      state.teamId = action.payload;
    },
    setRole(state, action: PayloadAction<Nullable<string>>) {
      state.role = action.payload;
    },
    setProjectIds(state, action: PayloadAction<string[]>) {
      state.projectIds = action.payload;
    },
    resetFilters: (_state) => initialState,
  },
});

export const { setFilter, setShowInheritancePopup, setActive, setTeamId, setRole, resetFilters, setProjectIds } =
  usersSlice.actions;
export const adminUsersSelector = (state: RootState) => state.admin.users;
export default usersSlice.reducer;
