import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { endOfDay, startOfDay, subDays } from "date-fns";

import type { RootState } from "../../../store";

export type ContactsCallsReducerType = {
  start: Nullable<Date>;
  stop: Nullable<Date>;
  user: Nullable<UserInterface>;
  search: Nullable<string>;
  status: Nullable<boolean>;
  connected: Nullable<boolean>;
  callDirection: Nullable<"INBOUND" | "OUTBOUND">;
};

const initialState: ContactsCallsReducerType = {
  start: startOfDay(subDays(new Date(), 7)),
  stop: endOfDay(new Date()),
  user: null,
  search: null,
  status: null,
  connected: null,
  callDirection: null,
};

const contactCallsSlice = createSlice({
  name: "contactsCalls",
  initialState,
  reducers: {
    setStart(state, action: PayloadAction<Nullable<Date>>) {
      state.start = action.payload ? startOfDay(action.payload) : null;
    },
    setStop(state, action: PayloadAction<Nullable<Date>>) {
      state.stop = action.payload ? endOfDay(action.payload) : null;
    },
    setSearch(state, action: PayloadAction<Nullable<string>>) {
      state.search = action.payload;
    },
    setStatus(state, action: PayloadAction<Nullable<boolean>>) {
      state.status = action.payload;
    },
    setUser(state, action: PayloadAction<Nullable<UserInterface>>) {
      state.user = action.payload;
    },
    setConnected(state, action: PayloadAction<Nullable<boolean>>) {
      state.connected = action.payload;
    },
    setCallDirection(state, action: PayloadAction<Nullable<"INBOUND" | "OUTBOUND">>) {
      state.callDirection = action.payload;
    },

    resetFilters(state) {
      state.start = startOfDay(subDays(new Date(), 7));
      state.stop = endOfDay(new Date());
      state.user = null;
      state.search = null;
      state.status = null;
      state.connected = null;
      state.callDirection = null;
    },
  },
});

export const contactsCallsSelector = (state: RootState) => state.contacts.calls;
export const { setStart, setStop, setSearch, setStatus, setUser, setConnected, resetFilters, setCallDirection } =
  contactCallsSlice.actions;
export default contactCallsSlice.reducer;
