import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../../../store";

type CommunicationsReducerType = {
  type: string;
};

const initialState: CommunicationsReducerType = {
  type: "",
};

const communicationsSlice = createSlice({
  name: "admin/communications",
  initialState,
  reducers: {
    setType(state, action: PayloadAction<string>) {
      state.type = action.payload;
    },
  },
});

export const { setType } = communicationsSlice.actions;
export const adminCommunicationsSelector = (state: RootState) => state.admin.communications;
export default communicationsSlice.reducer;
