import * as Sentry from "@sentry/react";
import { GraphQLFormattedError } from "graphql";

export class MutationError extends Error {
  result: Nilable<TTMutationResultType>;

  constructor(result: Nilable<TTMutationResultType> = undefined) {
    const errors = result?.messages.map((msg) => `${msg.field}: ${msg.message}`).join("\n") || "unknown error";
    super("Mutation failed:\n" + errors);
    this.result = result;
  }
}

function includesPathToIgnore(gqlErrors: GraphQLFormattedError[]) {
  const pathsToIgnore = ["login", "refresh"];

  if (gqlErrors.some((gqlE) => gqlE?.path?.some((path) => typeof path === "string" && pathsToIgnore.includes(path)))) {
    return true;
  }

  return false;
}

function hasGraphQLErrors(e: unknown): e is { graphQLErrors: GraphQLFormattedError[] } {
  return typeof e === "object" && e !== null && "graphQLErrors" in e;
}

export default function handleError(e: unknown, rethrow: boolean = true) {
  const ignoreError = hasGraphQLErrors(e) && includesPathToIgnore(e.graphQLErrors);

  if (ignoreError) {
    return;
  }

  if (typeof e === "string") {
    e = new Error(e);
    if ("captureStackTrace" in Error) Error.captureStackTrace(e as object, handleError);
  } else if (typeof e === "object" && e !== null && "stack" in e && "captureStackTrace" in Error) {
    const newE = new Error();
    Error.captureStackTrace(newE, handleError);
    if (newE.stack) {
      (e as Error).stack = newE.stack + (e as Error).stack;
    }
  }

  console.log(e);

  if (rethrow) {
    if (process.env.NODE_ENV === "production") {
      Sentry.captureException(e);
    } else {
      throw e;
    }
  }
}
