import _ from "lodash";

import type { ValuesType } from "./types";

const isCancled = (val: Nilable<string>) =>
  _.includes(["Stilllegung", "Löschung", "Stilllegung, Servicekunde", "Stilllegung Servicekunde"], val);

const statusStr = (status: Nilable<string>, addStatus: string) => {
  if (status) {
    if (addStatus) {
      return status + "," + addStatus;
    }

    return status;
  }

  return addStatus || "";
};

export const recalculateMbvsResult = (values: ValuesType) => {
  let addStatus = "";
  const mayContact = values.attrs["kontakt-erlaubt"] === "ja";

  if (isCancled(values.attrs.kundenstatus)) {
    return "062";
  }

  if (values.attrs["weiterbehandlung-bedarfsfall"]?.includes("Reklamation")) {
    addStatus = "045";
  } else if (values.attrs["weiterbehandlung-bedarfsfall"]?.includes("Probefahrt")) {
    addStatus = "055";
  }

  if (!mayContact || values.attrs["kontakt-erlaubt"] === "nicht möglich") {
    return statusStr("036,037", addStatus);
  }

  if (mayContact && values.attrs["fahrzeug-gewechselt"] === "nein" && !!values.attrs.fuhrpark) {
    return statusStr("036,037", addStatus);
  }

  if (values.attrs["wechsel-mb-oder-nicht"] === "kein MB") {
    return statusStr("036,037", addStatus);
  }

  switch (values.attrs["fahrzeugwechsel-geplant"]) {
    case "gar nicht":
      return statusStr("036,037", addStatus);

    case "<12 Monate":
      if (values.attrs["wechsel-neuwagen-oder-gebraucht"] === "neu") {
        return statusStr("036,038", addStatus);
      }

      return statusStr("036,039", addStatus);

    case ">12 Monate":
      return statusStr("036,051", addStatus);

    default:
  }

  return statusStr(null, addStatus);
};

export const sugNextContactValidation = (
  contact: BaseContactInterface,
  lastCallContact: Nilable<BaseContactInterface>,
): [boolean, string] => {
  if (contact.id === lastCallContact?.id) {
    return [true, ""];
  }

  const allowedStates = [
    "Dublette",
    "Dublette (identischer Lead)",
    "Keine DSE-Telefon-Freigabe",
    "Kein DSE/Kundenstamm (MBVS)",
    "Bereits durch SuG kontaktiert",
  ];

  if (contact.status && allowedStates.includes(contact.status.name)) {
    return [true, ""];
  }

  const toolTipText =
    "Bitte rufen Sie diesen Kontakt erst an oder setzen Sie ihn auf eine der folgenden Statusmöglichkeiten: „Dublette (identischer Lead)“, „Keine DSE-Telefon-Freigabe“, „Kein DSE/Kundenstamm (MBVS)“, „Bereits durch SuG kontaktiert“";

  return [false, toolTipText];
};
