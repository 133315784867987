import { useEffect } from "react";

import { useQuery } from "@apollo/client";
import { useTranslation } from "react-i18next";
import { MdWork } from "react-icons/md";
import { Link } from "react-router-dom";

import { COUNT_JOB_RESULTS_QUERY, COUNT_JOB_RESULTS_SUBSCRIPTION } from "@api/jobs";
import { jobsPath } from "@path/jobs_path";

import { useAppSelector } from "../../hooks";
import { sessionSelector } from "../../store/sessionReducer";

export default function FinishedJobs() {
  const session = useAppSelector(sessionSelector);
  const { data, refetch, subscribeToMore } = useQuery<CountJobResultsDataInterface>(COUNT_JOB_RESULTS_QUERY);
  const { t } = useTranslation(["translation"]);

  // TODO: backend_rs: remove when Elixir backend has been replaced
  useEffect(() => {
    document.addEventListener("tt:jobFinished", refetch);
    return () => {
      document.removeEventListener("tt:jobFinished", refetch);
    };
  });

  useEffect(() => {
    subscribeToMore({
      document: COUNT_JOB_RESULTS_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        return subscriptionData.data || prev;
      },
    });
  }, [subscribeToMore]);

  const unseen = parseInt(data?.countJobResults.unseen || "0", 10) || 0;

  if (!unseen) return null;

  return (
    <Link
      className="TT-unseen-jobs"
      to={jobsPath(session)}
      title={t("translation:sidebar.finished_jobs", { count: unseen })}
    >
      <MdWork /> {unseen}
    </Link>
  );
}
