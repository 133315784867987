import { RS_API_URI } from "@api/session";

import { sessionPrefix } from "./helpers";

export const digitalSignaturesPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/digital-signatures`;
export const newDigitalSignaturePath = (session: LightSessionInterface) => `${digitalSignaturesPath(session)}/new`;
export const showDigitalSignaturePath = (session: LightSessionInterface, envelope: DigitalSignatureEnvelope) =>
  `${digitalSignaturesPath(session)}/${envelope.id}`;
export const editDigitalSignaturePath = (session: LightSessionInterface, envelope: DigitalSignatureEnvelope) =>
  `${digitalSignaturesPath(session)}/${envelope.id}/edit`;

export const digitalSignatureFilePath = (file: DigitalSignatureFile) =>
  `${RS_API_URI}/digital-signatures/${file.digitalSignatureId}/${file.id}`;
export const digitalSignatureSignedFilePath = (file: DigitalSignatureFile) =>
  `${RS_API_URI}/digital-signatures/${file.digitalSignatureId}/${file.id}/signed-version`;
