import { find, isEmpty } from "lodash";

import type { Feature as ProjectFeature } from "../components/admin/projects/settings/Features";
import type { Feature } from "../utils/admin";

interface ProjectLikeInterface {
  attrs?: {
    features?: string[];
  };
}

type ProjectLikeType = BaseProjectInterface | ProjectLikeInterface;
type FeatureLikeType = Feature | ProjectFeature;

export const customerFeatureEnabled = (customer: Nilable<CustomerInterface>, feature: FeatureLikeType): boolean =>
  !isEmpty(find(customer?.attrs?.features || [], (f) => f === feature));

export const projectFeatureEnabled = (project: Nilable<ProjectLikeType>, feature: FeatureLikeType): boolean =>
  !isEmpty(find(project?.attrs?.features || [], (f) => f === feature));

export const userFeatureEnabled = (user: Nilable<UserInterface>, feature: FeatureLikeType): boolean =>
  !isEmpty(find(user?.flags || [], (f) => f === feature)) || isRoot(user);

export const featureEnabled = (
  user: Nilable<UserInterface>,
  project: Nilable<ProjectLikeType>,
  feature: FeatureLikeType,
): boolean => projectFeatureEnabled(project, feature) && userFeatureEnabled(user, feature);

export const isRoot = (user: Nilable<UserInterface>): boolean => !!user && user.role.toUpperCase() === "ROOT";
export const isAdmin = (user: Nilable<UserInterface>): boolean =>
  !!user && (isRoot(user) || user.role.toUpperCase() === "ADMIN");
export const isCustomerAdmin = (user: Nilable<UserInterface>, customer: FullCustomerInterface): boolean =>
  !!user &&
  (isAdmin(user) ||
    (user.role.toUpperCase() === "CUSTOMER_ADMIN" && customer.id === user.customerId) ||
    (!!customer && !!find(customer.customerAdministrators, (u) => u.id === user.id)));
export const isStaffAdmin = (user: Nilable<UserInterface>): boolean =>
  !!user && (isRoot(user) || (isAdmin(user) && userFeatureEnabled(user, "employees/write")));

export const userAndTargetAreAdmin = (user: Nilable<UserInterface>, targetUser: Nilable<UserInterface>): boolean =>
  !!user && !!targetUser && user.role.toUpperCase() === "ADMIN" && isAdmin(targetUser) && user.id !== targetUser.id;
export const isTermitel = (user: Nilable<UserInterface>): boolean => user?.customerId === "1";

export const onlyRootForTermitelVerwaltung = (
  user: Nilable<UserInterface>,
  customer: Nilable<CustomerInterface>,
  project: Nilable<BaseProjectInterface>,
): boolean => {
  if (!customer || !project || !user) {
    return false;
  }

  return customer.identifier === "termitel" && project.identifier === "verwaltung" ? isRoot(user) : true;
};
