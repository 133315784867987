import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../../store";

export interface TicketsReducerInterface {
  search: string;
  status: TicketStatusType[];
  category: Nullable<TicketCategoryType>;
  solution: Nullable<TicketSolutionType>;
  owners: UserInterface[];
  nextStepBy: Nullable<UserInterface>;
  reporters: UserInterface[];
  priority: Nullable<number>;
  customerId: Nullable<string>;
  doneStart: Nullable<Date>;
  doneStop: Nullable<Date>;
  insertedStart: Nullable<Date>;
  insertedStop: Nullable<Date>;
  dueStart: Nullable<Date>;
  dueStop: Nullable<Date>;
  boardView: boolean;
  filterAttrs: Record<string, AttributeFilterType>;
  team: Nullable<TeamInterface>;
}

const initialState: TicketsReducerInterface = {
  search: "",
  status: ["NEW", "ACCEPTED", "IN_PROGRESS", "QUESTION"],
  category: null,
  solution: null,
  owners: [],
  nextStepBy: null,
  reporters: [],
  priority: null,
  customerId: null,
  doneStart: null,
  doneStop: null,
  insertedStart: null,
  insertedStop: null,
  dueStart: null,
  dueStop: null,
  boardView: false,
  filterAttrs: {},
  team: null,
};

const ticketsSlice = createSlice({
  name: "tickets",
  initialState,
  reducers: {
    resetFilters(state) {
      state.search = "";
      state.status = ["NEW", "ACCEPTED", "IN_PROGRESS", "QUESTION"];
      state.category = null;
      state.solution = null;
      state.owners = [];
      state.reporters = [];
      state.nextStepBy = null;
      state.priority = null;
      state.customerId = null;
      state.doneStart = null;
      state.doneStop = null;
      state.insertedStart = null;
      state.insertedStop = null;
      state.dueStart = null;
      state.dueStop = null;
      state.team = null;
    },
    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    setStatus(state, action: PayloadAction<TicketStatusType[]>) {
      state.status = action.payload;
    },
    setOwner(state, action: PayloadAction<UserInterface[]>) {
      state.owners = action.payload;
    },
    setNextStepBy(state, action: PayloadAction<Nullable<UserInterface>>) {
      state.nextStepBy = action.payload;
    },
    setReporters(state, action: PayloadAction<UserInterface[]>) {
      state.reporters = action.payload;
    },
    setPriority(state, action: PayloadAction<Nullable<number>>) {
      state.priority = action.payload;
    },
    setCategory(state, action: PayloadAction<Nullable<TicketCategoryType>>) {
      state.category = action.payload;
    },
    setSolution(state, action: PayloadAction<Nullable<TicketSolutionType>>) {
      state.solution = action.payload;
    },
    setCustomerId(state, { payload }: PayloadAction<Nullable<string>>) {
      state.customerId = payload;
    },
    setDoneStart(state, { payload }: PayloadAction<Nullable<Date>>) {
      state.doneStart = payload;
    },
    setDoneStop(state, { payload }: PayloadAction<Nullable<Date>>) {
      state.doneStop = payload;
    },
    setInsertedStart(state, { payload }: PayloadAction<Nullable<Date>>) {
      state.insertedStart = payload;
    },
    setInsertedStop(state, { payload }: PayloadAction<Nullable<Date>>) {
      state.insertedStop = payload;
    },
    setDueStart(state, { payload }: PayloadAction<Nullable<Date>>) {
      state.dueStart = payload;
    },
    setDueStop(state, { payload }: PayloadAction<Nullable<Date>>) {
      state.dueStop = payload;
    },
    setTeam(state, { payload }: PayloadAction<Nullable<TeamInterface>>) {
      state.team = payload;
    },
    switchView(state) {
      state.boardView = !state.boardView;
    },
    setAttributeFilter(state, action: PayloadAction<{ name: string; value: Nilable<AttributeFilterType> }>) {
      if (!action.payload.value) {
        delete state.filterAttrs[action.payload.name];
      } else {
        state.filterAttrs[action.payload.name] = action.payload.value;
      }
    },
  },
});

export const {
  resetFilters,
  setSearch,
  setStatus,
  setOwner,
  setNextStepBy,
  setReporters,
  setPriority,
  setCategory,
  setSolution,
  setCustomerId,
  setDoneStart,
  setDoneStop,
  setInsertedStart,
  setInsertedStop,
  switchView,
  setAttributeFilter,
  setDueStart,
  setDueStop,
  setTeam,
} = ticketsSlice.actions;
export const ticketsSelector = (state: RootState) => state.tickets;
export default ticketsSlice.reducer;
