import { useMutation } from "@apollo/client";
import { useFormikContext } from "formik";
import { Form } from "react-bootstrap";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";
import { Descendant } from "slate";

import { DOCUMENT_ATTACHMENT_MUTATION } from "@api/documents";

import ErrorMessage from "../../../../containers/ErrorMessage";
import TTFormGroup from "../../../../containers/TTFormGroup";
import handleError, { MutationError } from "../../../../handleError";
import { useAppDispatch } from "../../../../hooks";
import { addDangerFlash } from "../../../flash/flashReducer";
import SlateEditor from "../../../SlateEditor";
import type { ValuesType } from "../../types";
import { DocumentFormProps } from "../../utils";

export default function FAQForm({ document }: DocumentFormProps) {
  const dispatch = useAppDispatch();
  const { values, setFieldValue } = useFormikContext<ValuesType>();
  const { t } = useTranslation(["documents"]);

  const [mutateDocumentAttachment] = useMutation<DocumentAttachmentMutationInterface>(DOCUMENT_ATTACHMENT_MUTATION, {
    refetchQueries: ["document"],
  });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: async (files) => {
      try {
        const { data } = await mutateDocumentAttachment({
          variables: {
            customerId: document.customerId,
            projectId: document.projectId,
            documentId: document.id,
            id: document.attachments[0]?.id,
            values: {
              file: files[0],
            },
          },
        });

        if (!data?.mutateDocumentAttachment) {
          throw new MutationError();
        }
      } catch (e) {
        dispatch(addDangerFlash(t("translation:global.general_error")));
        handleError(e);
      }
    },
  });

  function changeValue(value: Descendant[]) {
    setFieldValue("document.content", value);
  }

  return (
    <fieldset>
      <TTFormGroup>
        <Form.Label htmlFor="document.content">{t("documents:field_names.content")}</Form.Label>
        <SlateEditor id="document-content-editor" value={values.document.content || ""} onChange={changeValue} />
        <ErrorMessage path="document.content" />
      </TTFormGroup>

      <div className="TT-file-dropzone">
        <section>
          <div {...getRootProps({ className: "dropzone" })}>
            <Form.Label>{t("documents:form.choose_file")}</Form.Label>
            <input id="file" {...getInputProps()} />
          </div>

          {!!document.attachments.length && (
            <aside>{t("documents:form.file_chosen", { name: document.attachments[0].file })}</aside>
          )}
        </section>
      </div>
    </fieldset>
  );
}
