import { RS_API_URI } from "@api/session";

import { sessionPrefix } from "./helpers";

export const fileExchangePath = (session: LightSessionInterface) => `${sessionPrefix(session)}/file-exchange`;
export const showFileExchangeFilePath = (session: LightSessionInterface, file: FileExchangeFile) =>
  `${sessionPrefix(session)}/file-exchange/${file.id}`;
export const editFileExchangeFilePath = (session: LightSessionInterface, file: FileExchangeFile) =>
  `${sessionPrefix(session)}/file-exchange/${file.id}/edit`;
export const newFileExchangeFilePath = (session: LightSessionInterface) =>
  `${sessionPrefix(session)}/file-exchange/new`;

export const fileExchangeDownloadPath = (file: FileExchangeFile) => `${RS_API_URI}/file-exchange/${file.id}`;
