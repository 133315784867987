import { gql } from "@apollo/client";
import { type Channel } from "phoenix";

export const API_URI = process.env.NODE_ENV === "production" ? "/api/v1" : "http://localhost:4001/api/v1";
export const RS_API_URI = process.env.NODE_ENV === "production" ? "/api/v2" : "http://localhost:8000/api/v2";

export type UserResponseType = { user: FullUserInterface; customer: FullCustomerInterface };

export class AuthenticationError extends Error {}

export let userChannel: Nullable<Channel> = null;
export let userLobbyChannel: Nullable<Channel> = null;

export const setUserChannel = (channel: Nullable<Channel>) => (userChannel = channel);
export const setUserLobbyChannel = (channel: Nullable<Channel>) => (userLobbyChannel = channel);

export const SESSION_USER_FRAGMENT = gql`
  fragment SessionUserFragment on User {
    id
    customerId
    username
    email
    customerId
    role
    flags
    teamLeader
    isJumper
    billableCustomerId
    billableCustomerIds
    currentBillingCustomerId

    currentBillingCustomer {
      id
      name

      logo {
        thumb
        medium
        original
      }
    }

    billableCustomers {
      id
      name

      logo {
        thumb
        medium
        original
      }
    }

    avatar {
      thumb
      medium
      original
    }

    attrs
    passwordReset
    otp

    contact {
      id
      displayName
      birthdate
    }

    currentContract {
      id
      hours
      hourlyWage
      monthlyWage
    }

    projects {
      id
      name
      identifier
      attrs
    }

    customer {
      id
      identifier
      attrs

      defaultProject {
        id
        identifier
        attrs
      }
    }
  }
`;

export const LOGIN = gql`
  mutation login($username: String!, $password: String!, $otp: String) {
    login(username: $username, password: $password, otp: $otp)
  }
`;

export const SESSION_DATA_QUERY = gql`
  query sessionData($customerIdentifier: String) {
    sessionData(customerIdentifier: $customerIdentifier) {
      user {
        ...SessionUserFragment
      }

      customer {
        id
        name
        identifier
        defaultProjectId
        attrs

        logo {
          thumb
          medium
          original
        }

        projects {
          id
          name
          identifier
          attrs
        }

        customerAdministrators {
          id
          username
        }
      }
    }
  }

  ${SESSION_USER_FRAGMENT}
`;

export const REFRESH = gql`
  mutation refresh($customerIdentifier: String) {
    refresh(customerIdentifier: $customerIdentifier) {
      token

      user {
        ...SessionUserFragment
      }

      customer {
        id
        name
        identifier
        defaultProjectId
        attrs

        logo {
          thumb
          medium
          original
        }

        projects {
          id
          name
          identifier
          attrs
        }

        customerAdministrators {
          id
          username
        }
      }
    }
  }
  ${SESSION_USER_FRAGMENT}
`;

export const GET_SESSION_CUSTOMER = gql`
  query customer($customerId: ID, $identifier: String) {
    customer(id: $customerId, identifier: $identifier) {
      id
      name
      identifier
      defaultProjectId
      attrs

      logo {
        thumb
        medium
        original
      }

      projects {
        id
        name
        identifier
        attrs
      }

      customerAdministrators {
        id
        username
      }
    }
  }
`;

export const TOKEN_FOR_USER = gql`
  mutation tokenForUser($userId: ID!) {
    tokenForUser(userId: $userId)
  }
`;

export const CHALLENGE_MOBILE_MUTATION = gql`
  mutation requestChallenge($number: String!) {
    requestChallenge(number: $number)
  }
`;

export const VERIFY_MOBILE_CHALENGE_MUTATION = gql`
  mutation verifyChallenge($number: String!, $response: String!) {
    verifyChallenge(number: $number, response: $response)
  }
`;

export const REFRESH_USER_PROTOCOL_BEACON = gql`
  mutation refreshUserProtocolBeacon {
    refreshUserProtocolBeacon {
      id
    }
  }
`;

export const SET_CURRENT_BILLING_CUSTOMER = gql`
  mutation usersSetCurrentBillingCustomer($customerId: ID) {
    usersSetCurrentBillingCustomer(customerId: $customerId) {
      ...SessionUserFragment
    }
  }
  ${SESSION_USER_FRAGMENT}
`;
