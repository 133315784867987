import { useState } from "react";

import { useMutation, useQuery } from "@apollo/client";
import { Alert, Button, Offcanvas } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { HIDE_PROJECT_NEWS_MUTATION, PROJECT_NEWS_LIST_QUERY } from "@api/projectNews";

import { may } from "../../abilities";
import { CancelButton, ConfirmButton, OkButton } from "../../containers/buttons";
import handleError, { MutationError } from "../../handleError";
import { useAppDispatch, useAppSelector } from "../../hooks";
import { sessionSelector } from "../../store/sessionReducer";
import { addDangerFlash } from "../flash/flashReducer";
import SlateEditor from "../SlateEditor";

type OffcanvasStateType = {
  show: boolean;
  news: ProjectNewsType | null;
};

export default function ProjectNews() {
  const session = useAppSelector(sessionSelector);
  const { t } = useTranslation(["projectNews"]);
  const { currentCustomer, currentProject } = session;
  const [offcanvasState, setOffcanvasState] = useState<OffcanvasStateType>({ show: false, news: null });
  const dispatch = useAppDispatch();

  const [hideNewsMutation] = useMutation<HideProjectNewsMutationInterface>(HIDE_PROJECT_NEWS_MUTATION, {
    refetchQueries: ["projectNews"],
  });

  const { data } = useQuery<ProjectNewsDataType>(PROJECT_NEWS_LIST_QUERY, {
    variables: {
      customerId: currentCustomer.id,
      projectId: currentProject.id,
      filters: {
        active: true,
        visible: true,
      },
    },
    skip: !may(session, "project-news"),
  });

  async function hideNews(id: string | undefined) {
    try {
      const { data } = await hideNewsMutation({
        variables: {
          customerId: currentCustomer.id,
          projectId: currentProject.id,
          id,
        },
      });

      if (!data?.hideProjectNews) {
        throw new MutationError();
      }

      setOffcanvasState({ show: false, news: null });
    } catch (e) {
      dispatch(addDangerFlash(t("translation:global.general_error")));
      handleError(e);
    }
  }

  if (!data?.projectNews) return null;

  return (
    <>
      {data.projectNews.map((post) => (
        <Alert key={post.id} variant="info" className="TT-project-news-alert">
          <Alert.Link onClick={() => setOffcanvasState({ show: true, news: post })}>{post.subject}</Alert.Link>

          <Button variant="link" size="sm" onClick={() => setOffcanvasState({ show: true, news: post })}>
            {t("translation:global.show_more")}
          </Button>
        </Alert>
      ))}

      <Offcanvas
        className="TT-project-news-offcanvas"
        placement="top"
        scroll={true}
        show={offcanvasState.show}
        onHide={() => setOffcanvasState({ show: false, news: null })}
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{offcanvasState.news?.subject}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="document">
          <SlateEditor id="content" value={offcanvasState.news?.document.content} readOnly toolbar={false} />

          <div className="controls">
            <ConfirmButton as={OkButton} color="primary" onClick={() => hideNews(offcanvasState.news?.id)}>
              {t("projectNews:close_project_news.dont_show_more")}
            </ConfirmButton>

            <CancelButton onClick={() => setOffcanvasState({ show: false, news: null })}>
              {t("projectNews:close_project_news.close")}
            </CancelButton>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
