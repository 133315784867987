import { useState } from "react";

import { useSubscription } from "@apollo/client";
import { Modal } from "react-bootstrap";
import { Trans, useTranslation } from "react-i18next";

import { ROSTER_TASK_SUBSCRIPTION } from "@api/roster";

import { userFeatureEnabled } from "../../abilities/helpers";
import { DoneButton } from "../../containers/buttons";
import { useAppSelector } from "../../hooks";
import { sessionSelector } from "../../store/sessionReducer";
import { relativeTime } from "../../utils/dates";
import TTModal from "../TTModal";

export default function TaskNotificationModal() {
  const { currentUser } = useAppSelector(sessionSelector);
  const [showModal, setShowModal] = useState(false);
  const [hour, setHour] = useState<Nilable<RosterHourInterface>>(null);
  const { t } = useTranslation(["roster"]);

  useSubscription<RosterTaskNotificationSubscritpionInterface>(ROSTER_TASK_SUBSCRIPTION, {
    skip: !userFeatureEnabled(currentUser, "roster"),
    onData: ({ data: { data } }) => {
      if (!data) return;

      if (localStorage.getItem("lastRosterHourNotification") !== data.notifyRosterHour.id) {
        setHour(data.notifyRosterHour);
        setShowModal(true);
      }
    },
  });

  function closeModal() {
    setShowModal(false);
    localStorage.setItem("lastRosterHourNotification", hour!.id);
  }

  if (!hour) return null;

  return (
    <TTModal show={showModal} onHide={() => setShowModal(false)} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{t("roster:notification_modal.title")}</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Trans t={t} i18nKey="roster:notification_modal.body">
          Aufgabe „{{ taskName: hour.task.name }}“ beginnt {{ time: relativeTime(hour.hour) }}
        </Trans>
      </Modal.Body>

      <Modal.Footer>
        <DoneButton onClick={closeModal}>{t("roster:notification_modal.understood")}</DoneButton>
      </Modal.Footer>
    </TTModal>
  );
}
