import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

import type { RootState } from ".";
import { rootPath } from "../path_helpers";

const initialState: SessionInterface = {
  currentUser: null as any,
  isAuthenticated: false,
  loading: true,
  currentCustomer: null as any,
  currentProject: null as any,
  authToken: null,
  socket: null,
  customers: [],
  breadcrumb: [],
  isNotFound: false,
};

type CurrentCustomerAndProjectType = { customer: FullCustomerInterface; project: ProjectInterface };

const breadcrumbPath = (breadcrumb: BreadcrumbItemInterface[], item: BreadcrumbItemInterface) => {
  const newBreadcrumb = _.filter(breadcrumb, (itm) => itm.type !== item.type);

  let index = item.after ? _.findIndex(newBreadcrumb, (itm) => itm.type === item.after) : newBreadcrumb.length;
  if (index < 0) {
    index = newBreadcrumb.length;
  } else {
    index += 1;
  }

  newBreadcrumb.splice(index, 0, item);
  return newBreadcrumb;
};

const breadcrumbItem = (payload: CurrentCustomerAndProjectType) => ({
  type: "currentProject",
  to: rootPath({ currentCustomer: payload.customer, currentProject: payload.project } as SessionInterface),
  text: payload.project.name,
  after: "",
});

const sessionSlice = createSlice({
  name: "session",
  initialState,
  reducers: {
    setCurrentUser(state, { payload }: PayloadAction<Nullable<FullUserInterface>>) {
      state.currentUser = payload as any;
      state.loading = false;
      state.isAuthenticated = !!payload;
    },
    logout() {
      return { ...initialState };
    },

    setCurrentCustomerAndProject(state, { payload }: PayloadAction<CurrentCustomerAndProjectType>) {
      state.breadcrumb = [breadcrumbItem(payload), ...state.breadcrumb.slice(1)];
      state.currentCustomer = payload.customer;
      state.currentProject = payload.project;
    },

    addBreadcrumbItem(state, { payload }: PayloadAction<BreadcrumbItemInterface>) {
      state.breadcrumb = breadcrumbPath(state.breadcrumb, payload);
    },
    removeBreadcrumbItem(state, { payload }: PayloadAction<string>) {
      state.breadcrumb = state.breadcrumb.filter((itm) => itm.type !== payload);
    },
    setNotFound(state, { payload }: PayloadAction<boolean>) {
      state.isNotFound = payload;
    },
    setAuthorizationToken(state, { payload }: PayloadAction<Nullable<string>>) {
      state.authToken = payload;
    },
  },
});

export const {
  setCurrentUser,
  logout,
  setNotFound,
  setCurrentCustomerAndProject,
  addBreadcrumbItem,
  removeBreadcrumbItem,
  setAuthorizationToken,
} = sessionSlice.actions;
export default sessionSlice.reducer;

export const sessionSelector = (state: RootState) => state.session;
