import { RS_API_URI } from "@api/session";

import { sessionPrefix } from "./helpers";

export const documentsPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/documents`;
export const newDocumentPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/documents/new`;
export const missingSignaturesPath = (session: LightSessionInterface) =>
  `${sessionPrefix(session)}/documents/missing-signatures`;
export const missingSignaturesForUserPath = (session: LightSessionInterface, user: UserInterface) =>
  `${sessionPrefix(session)}/documents/missing-signatures/${user.id}`;
export const showDocumentPath = (session: LightSessionInterface, document: TTDocumentType) =>
  `${sessionPrefix(session)}/documents/${document.id}`;
export const editDocumentPath = (session: LightSessionInterface, document: TTDocumentType) =>
  `${sessionPrefix(session)}/documents/${document.id}/edit`;

export const documentAttachmentPath = (attachment: DocumentAttachmentType) =>
  `${RS_API_URI}/documents/attachments/${attachment.id}`;
