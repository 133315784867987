import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../../../store";

export interface ProjectsReducerInterface {
  search: string;
  active: Nullable<boolean>;
}

const initialState: ProjectsReducerInterface = {
  search: "",
  active: null,
};

const projectsSlice = createSlice({
  name: "admin/projects",
  initialState,
  reducers: {
    setSearch(state, { payload }: PayloadAction<string>) {
      state.search = payload;
    },
    setActive(state, { payload }: PayloadAction<Nullable<boolean>>) {
      state.active = payload;
    },

    resetFilters(state) {
      state.search = "";
      state.active = null;
    },
  },
});

export const { setSearch, setActive, resetFilters } = projectsSlice.actions;
export const adminProjectsSelector = (state: RootState) => state.admin.projects;
export default projectsSlice.reducer;
