import "./employment-contract.scss";

import { useState } from "react";

import { useMutation } from "@apollo/client";
import { Form, Formik, type FormikHelpers } from "formik";
import { Form as BsForm, Button } from "react-bootstrap";
import { MdFileDownload } from "react-icons/md";
import { useParams } from "react-router-dom";

import { SEND_PERSONAL_FILE_CHALLENGE_MUTATION, VERIFY_PERSONAL_FILE_CHALLENGE_MUTATION } from "@api/personal_files";
import { personalFileDownloadPath } from "@path/staff_paths";

import { DoneButton } from "../../../containers/buttons";
import FormActionsContainer from "../../../containers/FormActionsContainer";
import FormikInput from "../../../containers/FormikInput";
import NoLoginPageHeader from "../../../containers/NoLoginPageHeader";
import Spinner from "../../../containers/Spinner";
import TTFormGroup from "../../../containers/TTFormGroup";
import handleError from "../../../handleError";
import { useAppDispatch } from "../../../hooks";
import useTitle from "../../../hooks/useTitle";
import FlashMessagesList from "../../flash";
import { addDangerFlash } from "../../flash/flashReducer";

type ValuesType = {
  code: string;
};

export default function EmploymentContract() {
  const [hasRequestedChallenge, setHasRequestedChallenge] = useState(false);
  const dispatch = useAppDispatch();
  const { id } = useParams<"id">();
  const [sendPersonalFileChallenge] = useMutation<SendPersonalFileChallenge>(SEND_PERSONAL_FILE_CHALLENGE_MUTATION);
  const [verifyPersonalFileChallenge] = useMutation<VerifyPersonalFileChallenge>(
    VERIFY_PERSONAL_FILE_CHALLENGE_MUTATION,
  );

  useTitle("Arbeitsvertrag herunterladen");

  async function requestChallenge() {
    try {
      const { data } = await sendPersonalFileChallenge({ variables: { id: id } });

      if (!data?.sendPersonalFileChallenge) {
        dispatch(addDangerFlash("Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."));
      }

      setHasRequestedChallenge(true);
    } catch (e) {
      dispatch(addDangerFlash("Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."));
      handleError(e);
    }
  }

  async function verifyPersonalFileChallengeAndDownload(
    values: ValuesType,
    { setSubmitting }: FormikHelpers<ValuesType>,
  ) {
    const { data } = await verifyPersonalFileChallenge({
      variables: {
        id: id,
        response: values.code.toString(),
      },
    });

    if (!data?.verifyPersonalFileChallenge) {
      dispatch(addDangerFlash("Es ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut."));
    } else {
      const rsp = await fetch(personalFileDownloadPath({ id } as any), {
        method: "get",
        cache: "no-cache",
        credentials: "same-origin",
        referrer: "no-referrer",
        headers: {
          Authorization: `Bearer ${data.verifyPersonalFileChallenge}`,
        },
      });

      const blob = await rsp.blob();
      const uri = URL.createObjectURL(blob);
      document.location.href = uri;
    }

    setSubmitting(false);
  }

  return (
    <div className="TT-staff-emplyee-contract">
      <NoLoginPageHeader />

      <div className="main">
        <h1>Arbeitsvertrag herunterladen</h1>

        <FlashMessagesList inline />

        <p>
          Um Ihre Identität zu bestätigen, werden wir ihnen eine SMS mit einer 6-stelligen Nummer zusenden. Bitte geben
          Sie die hier ein.
        </p>

        <p>
          <DoneButton disabled={hasRequestedChallenge} onClick={requestChallenge}>
            Code anfordern
          </DoneButton>
        </p>

        <Formik initialValues={{ code: "" }} onSubmit={verifyPersonalFileChallengeAndDownload}>
          {({ isSubmitting }) => (
            <Form>
              <TTFormGroup>
                <BsForm.Label htmlFor="code">Authentifizierungs-Code</BsForm.Label>
                <FormikInput id="code" name="code" type="number" min={0} step={1} />
              </TTFormGroup>

              <FormActionsContainer>
                <Button variant="secondary" disabled={isSubmitting} type="submit">
                  {isSubmitting ? <Spinner /> : <MdFileDownload />} Code absenden und Datei herunterladen
                </Button>
              </FormActionsContainer>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
}
