import { gql } from "@apollo/client";

import { USER_FRAGMENT } from "./users";

export const ATTENDANCE_TYPE_FRAGMENT = gql`
  fragment RosterAttendanceTypeFragment on RosterAttendanceType {
    id
    name
    type
    char
    color
    isStandard
    ignoreForSickNotes
    location

    insertedAt
    updatedAt
  }
`;

export const ATTENDANCE_TYPES_LIST_QUERY = gql`
  query rosterAttendanceTypes($customerId: ID!, $filters: RosterAttendanceTypeFilters) {
    rosterAttendanceTypes(customerId: $customerId, filters: $filters) {
      ...RosterAttendanceTypeFragment
    }
  }
  ${ATTENDANCE_TYPE_FRAGMENT}
`;

export const ATTENDANCE_TYPES_COUNT_QUERY = gql`
  query rosterAttendanceTypes($customerId: ID!, $filters: RosterAttendanceTypeFilters) {
    countRosterAttendanceTypes(customerId: $customerId, filters: $filters)
  }
`;

export const ROSTER_ATTENDANCE_TYPE_QUERY = gql`
  query rosterAttendanceType($customerId: ID!, $id: ID!) {
    rosterAttendanceType(customerId: $customerId, id: $id) {
      ...RosterAttendanceTypeFragment
    }
  }
  ${ATTENDANCE_TYPE_FRAGMENT}
`;

export const ROSTER_STANDARD_ATTENDANCE_TYPE_VALID_QUERY = gql`
  query rosterStandardAttendanceTypeValid($customerId: ID!) {
    rosterStandardAttendanceTypeValid(customerId: $customerId)
  }
`;

export const ATTENDANCE_TYPE_MUTATION = gql`
  mutation mutateRosterAttendanceType($customerId: ID!, $id: ID, $values: RosterAttendanceTypeInput!) {
    mutateRosterAttendanceType(customerId: $customerId, id: $id, values: $values) {
      ...RosterAttendanceTypeFragment
    }
  }
  ${ATTENDANCE_TYPE_FRAGMENT}
`;

export const DELETE_ROSTER_ATTENDANCE_TYPE_MUTATION = gql`
  mutation deleteRosterAttendanceType($customerId: ID!, $id: ID!) {
    deleteRosterAttendanceType(customerId: $customerId, id: $id) {
      ...RosterAttendanceTypeFragment
    }
  }
  ${ATTENDANCE_TYPE_FRAGMENT}
`;

export const ARCHIVE_ROSTER_ATTENDANCE_TYPE_MUTATION = gql`
  mutation archiveRosterAttendanceType($customerId: ID!, $id: ID!) {
    archiveRosterAttendanceType(customerId: $customerId, id: $id) {
      ...RosterAttendanceTypeFragment
    }
  }
  ${ATTENDANCE_TYPE_FRAGMENT}
`;

export const ROSTER_TASK_FRAGMENT = gql`
  fragment RosterTaskFragment on RosterTask {
    id
    name
    type
    char
    color
    notifyUser
    notifyMinutes

    insertedAt
    updatedAt
  }
`;

export const ROSTER_TASKS_LIST_QUERY = gql`
  query rosterTasks($customerId: ID!, $filters: RosterTaskFilters) {
    rosterTasks(customerId: $customerId, filters: $filters) {
      customer {
        id
        name
      }

      ...RosterTaskFragment
    }
  }
  ${ROSTER_TASK_FRAGMENT}
`;

export const ROSTER_TASKS_COUNT_QUERY = gql`
  query countRosterTasks($customerId: ID!, $filters: RosterTaskFilters) {
    countRosterTasks(customerId: $customerId, filters: $filters)
  }
`;

export const ROSTER_TASK_QUERY = gql`
  query rosterTask($customerId: ID!, $id: ID!) {
    rosterTask(customerId: $customerId, id: $id) {
      ...RosterTaskFragment
    }
  }
  ${ROSTER_TASK_FRAGMENT}
`;

export const ROSTER_TASK_MUTATION = gql`
  mutation mutateRosterTask($customerId: ID!, $id: ID, $values: RosterTaskInput!) {
    mutateRosterTask(customerId: $customerId, id: $id, values: $values) {
      ...RosterTaskFragment
    }
  }
  ${ROSTER_TASK_FRAGMENT}
`;

export const DELETE_ROSTER_TASK_MUTATION = gql`
  mutation deleteRosterTask($customerId: ID!, $id: ID!) {
    deleteRosterTask(customerId: $customerId, id: $id) {
      ...RosterTaskFragment
    }
  }
  ${ROSTER_TASK_FRAGMENT}
`;

export const ARCHIVE_ROSTER_TASK_MUTATION = gql`
  mutation archiveRosterTask($customerId: ID!, $id: ID!) {
    archiveRosterTask(customerId: $customerId, id: $id) {
      ...RosterTaskFragment
    }
  }
  ${ROSTER_TASK_FRAGMENT}
`;

export const ROSTER_GROUP_FRAGMENT = gql`
  fragment RosterGroupFragment on RosterGroup {
    id
    name

    users {
      ...UserFragment
    }

    customer {
      id
      name
    }

    insertedAt
    updatedAt
  }
  ${USER_FRAGMENT}
`;

export const ROSTER_GROUPS_LIST_QUERY = gql`
  query rosterGroups($customerId: ID, $filters: RosterGroupFilters) {
    rosterGroups(customerId: $customerId, filters: $filters) {
      ...RosterGroupFragment
    }
  }
  ${ROSTER_GROUP_FRAGMENT}
`;

export const ROSTER_GROUP_QUERY = gql`
  query rosterGroup($customerId: ID!, $id: ID!) {
    rosterGroup(customerId: $customerId, id: $id) {
      ...RosterGroupFragment
    }
  }
  ${ROSTER_GROUP_FRAGMENT}
`;

export const ROSTER_GROUP_MUTATION = gql`
  mutation mutateRosterGroup($customerId: ID!, $id: ID, $values: RosterGroupInput!) {
    mutateRosterGroup(customerId: $customerId, id: $id, values: $values) {
      ...RosterGroupFragment
    }
  }
  ${ROSTER_GROUP_FRAGMENT}
`;

export const DELETE_ROSTER_GROUP_MUTATION = gql`
  mutation deleteRosterGroup($customerId: ID!, $id: ID!) {
    deleteRosterGroup(customerId: $customerId, id: $id) {
      ...RosterGroupFragment
    }
  }
  ${ROSTER_GROUP_FRAGMENT}
`;

export const ROSTER_STANDARD_ATTENDANCE_FRAGMENT = gql`
  fragment RosterStandardAttendanceFragment on RosterStandardAttendance {
    id
    weekDay

    attendanceTypeId
    type {
      ...RosterAttendanceTypeFragment
    }

    userId
    user {
      ...UserFragment
    }

    insertedAt
    updatedAt
  }
  ${ATTENDANCE_TYPE_FRAGMENT}
  ${USER_FRAGMENT}
`;

export const ROSTER_STANDARD_ATTENDANCE_LIST_QUERY = gql`
  query rosterStandardAttendances($customerId: ID!, $filters: RosterStandardAttendanceFilters) {
    rosterStandardAttendances(customerId: $customerId, filters: $filters) {
      ...RosterStandardAttendanceFragment
    }
  }
  ${ROSTER_STANDARD_ATTENDANCE_FRAGMENT}
`;

export const ROSTER_STANDARD_ATTENDANCE_QUERY = gql`
  query rosterStandardAttendance($customerId: ID!, $id: ID!) {
    rosterStandardAttendance(customerId: $customerId, id: $id) {
      ...RosterStandardAttendanceFragment
    }
  }
  ${ROSTER_STANDARD_ATTENDANCE_FRAGMENT}
`;

export const ROSTER_STANDARD_ATTENDANCE_MUTATION = gql`
  mutation mutateRosterStandardAttendance($customerId: ID!, $id: ID, $values: RosterStandardAttendanceInput!) {
    mutateRosterStandardAttendance(customerId: $customerId, id: $id, values: $values) {
      ...RosterStandardAttendanceFragment
    }
  }
  ${ROSTER_STANDARD_ATTENDANCE_FRAGMENT}
`;

export const DELETE_ROSTER_STANDARD_ATTENDANCE_MUTATION = gql`
  mutation deleteRosterStandardAttendance($customerId: ID!, $id: ID!) {
    deleteRosterStandardAttendance(customerId: $customerId, id: $id) {
      ...RosterStandardAttendanceFragment
    }
  }
  ${ROSTER_STANDARD_ATTENDANCE_FRAGMENT}
`;

export const ROSTER_STANDARD_DAY_FRAGMENT = gql`
  fragment RosterStandardDayFragment on RosterStandardDay {
    id
    weekDay

    attendanceTypeId
    type {
      ...RosterAttendanceTypeFragment
    }

    userId
    user {
      ...UserFragment
    }

    hours {
      id
      hour
      taskId
      task {
        ...RosterTaskFragment
      }
    }

    insertedAt
    updatedAt
  }
  ${ATTENDANCE_TYPE_FRAGMENT}
  ${USER_FRAGMENT}
  ${ROSTER_TASK_FRAGMENT}
`;

export const ROSTER_STANDARD_DAY_LIST_QUERY = gql`
  query rosterStandardDays($customerId: ID!, $filters: RosterStandardDayFilters) {
    rosterStandardDays(customerId: $customerId, filters: $filters) {
      ...RosterStandardDayFragment
    }
  }
  ${ROSTER_STANDARD_DAY_FRAGMENT}
`;

export const ROSTER_STANDARD_DAY_MUTATION = gql`
  mutation mutateRosterStandardDay($customerId: ID!, $id: ID, $values: RosterStandardDayInput!) {
    mutateRosterStandardDay(customerId: $customerId, id: $id, values: $values) {
      ...RosterStandardDayFragment
    }
  }
  ${ROSTER_STANDARD_DAY_FRAGMENT}
`;

export const SHIFT_STANDARD_DAY_HOURS_MUTATION = gql`
  mutation shiftRosterStandardDayHours($customerId: ID!, $standardDayId: ID!, $minutes: Int!) {
    shiftRosterStandardDayHours(customerId: $customerId, standardDayId: $standardDayId, minutes: $minutes) {
      id
      hour
      taskId
      task {
        ...RosterTaskFragment
      }
    }
  }
  ${ROSTER_TASK_FRAGMENT}
`;

export const COPY_ROSTER_STANDARD_DAY_MUTATION = gql`
  mutation copyRosterStandardDay($customerId: ID!, $id: ID!, $values: RosterStandardDayInput!) {
    copyRosterStandardDay(customerId: $customerId, id: $id, values: $values) {
      ...RosterStandardDayFragment
    }
  }
  ${ROSTER_STANDARD_DAY_FRAGMENT}
`;

export const DELETE_ROSTER_STANDARD_DAY_MUTATION = gql`
  mutation deleteRosterStandardDay($customerId: ID!, $id: ID!) {
    deleteRosterStandardDay(customerId: $customerId, id: $id) {
      ...RosterStandardDayFragment
    }
  }
  ${ROSTER_STANDARD_DAY_FRAGMENT}
`;

export const ROSTER_ATTENDANCE_FRAGMENT = gql`
  fragment RosterAttendanceFragment on RosterAttendance {
    id
    day
    visible
    toBeDeleted

    attendanceTypeId
    userId

    insertedAt
    updatedAt
  }
`;

export const ROSTER_ATTENDANCE_LIST_QUERY = gql`
  query rosterAttendances($customerId: ID!, $filters: RosterAttendanceFilters) {
    rosterAttendances(customerId: $customerId, filters: $filters) {
      ...RosterAttendanceFragment
    }
  }
  ${ROSTER_ATTENDANCE_FRAGMENT}
`;

export const ROSTER_ATTENDANCE_W_TYPE_LIST_QUERY = gql`
  query rosterAttendances($customerId: ID!, $filters: RosterAttendanceFilters) {
    rosterAttendances(customerId: $customerId, filters: $filters) {
      ...RosterAttendanceFragment
      attendanceType {
        id
        name
        type
      }
    }
  }
  ${ROSTER_ATTENDANCE_FRAGMENT}
`;

export const ROSTER_ATTENDANCE_QUERY = gql`
  query rosterAttendance($customerId: ID!, $id: ID!) {
    rosterAttendance(customerId: $customerId, id: $id) {
      ...RosterAttendanceFragment
    }
  }
  ${ROSTER_ATTENDANCE_FRAGMENT}
`;

export const ROSTER_ATTENDANCE_MUTATION = gql`
  mutation mutateRosterAttendance($customerId: ID!, $id: ID, $values: RosterAttendanceInput!) {
    mutateRosterAttendance(customerId: $customerId, id: $id, values: $values) {
      ...RosterAttendanceFragment
    }
  }
  ${ROSTER_ATTENDANCE_FRAGMENT}
`;

export const ROSTER_PLAN_STANDARD_ATTENDANCE_MUTATION = gql`
  mutation planStandardRosterAttendance($customerId: ID!, $userId: ID!, $month: NaiveDate!) {
    planStandardRosterAttendance(customerId: $customerId, userId: $userId, month: $month) {
      ...RosterAttendanceFragment
    }
  }
  ${ROSTER_ATTENDANCE_FRAGMENT}
`;

export const DELETE_ROSTER_ATTENDANCE_MUTATION = gql`
  mutation deleteRosterAttendance($customerId: ID!, $id: ID!) {
    deleteRosterAttendance(customerId: $customerId, id: $id) {
      ...RosterAttendanceFragment
    }
  }
  ${ROSTER_ATTENDANCE_FRAGMENT}
`;

export const BATCH_DELETE_ROSTER_ATTENDANCE_MUTATION = gql`
  mutation batchDeleteRosterAttendances($customerId: ID!, $userId: ID!, $month: NaiveDate!) {
    batchDeleteRosterAttendances(customerId: $customerId, userId: $userId, month: $month) {
      ...RosterAttendanceFragment
    }
  }
  ${ROSTER_ATTENDANCE_FRAGMENT}
`;

export const ROSTER_ATTENDANCE_CONFIRM_MUTATION = gql`
  mutation confirmRosterAttendances($customerId: ID!, $userIds: [ID]!, $month: NaiveDate!) {
    confirmRosterAttendances(customerId: $customerId, userIds: $userIds, month: $month) {
      ...RosterAttendanceFragment
    }
  }
  ${ROSTER_ATTENDANCE_FRAGMENT}
`;

export const ROSTER_HOUR_FRAGMENT = gql`
  fragment RosterHourFragment on RosterHour {
    id
    hour
    visible
    toBeDeleted

    taskId
    userId

    insertedAt
    updatedAt
  }
`;

export const ROSTER_HOURS_LIST_QUERY = gql`
  query rosterHours($customerId: ID!, $filters: RosterHourFilters) {
    rosterHours(customerId: $customerId, filters: $filters) {
      ...RosterHourFragment
    }
  }
  ${ROSTER_HOUR_FRAGMENT}
`;

export const ROSTER_HOUR_QUERY = gql`
  query rosterHour($customerId: ID!, $id: ID!) {
    rosterHour(customerId: $customerId, id: $id) {
      ...RosterHourFragment
    }
  }
  ${ROSTER_HOUR_FRAGMENT}
`;

export const ROSTER_HOUR_MUTATION = gql`
  mutation mutateRosterHour($customerId: ID!, $id: ID, $values: RosterHourInput!) {
    mutateRosterHour(customerId: $customerId, id: $id, values: $values) {
      ...RosterHourFragment
    }
  }
  ${ROSTER_HOUR_FRAGMENT}
`;

export const ROSTER_SHIFT_HOURS_MUTATION = gql`
  mutation shiftRosterHours($customerId: ID!, $userId: ID!, $day: NaiveDate!, $minutes: Int!) {
    shiftRosterHours(customerId: $customerId, userId: $userId, day: $day, minutes: $minutes) {
      ...RosterHourFragment
    }
  }
  ${ROSTER_HOUR_FRAGMENT}
`;

export const ROSTER_CONFIRM_HOUR_MUTATION = gql`
  mutation confirmRosterHours($customerId: ID!, $userIds: [ID]!, $day: NaiveDate!) {
    confirmRosterHours(customerId: $customerId, userIds: $userIds, day: $day) {
      ...RosterHourFragment
    }
  }
  ${ROSTER_HOUR_FRAGMENT}
`;

export const ROSTER_PLAN_STANDARD_HOURS_MUTATION = gql`
  mutation planStandardRosterHours($customerId: ID!, $userId: ID!, $day: NaiveDate!) {
    planStandardRosterHours(customerId: $customerId, userId: $userId, day: $day) {
      ...RosterHourFragment
    }
  }
  ${ROSTER_HOUR_FRAGMENT}
`;

export const DELETE_ROSTER_HOUR_MUTATION = gql`
  mutation deleteRosterHour($customerId: ID!, $id: ID!) {
    deleteRosterHour(customerId: $customerId, id: $id) {
      ...RosterHourFragment
    }
  }
  ${ROSTER_HOUR_FRAGMENT}
`;

export const BATCH_DELETE_ROSTER_HOUR_MUTATION = gql`
  mutation batchDeleteRosterHours($customerId: ID!, $userId: ID!, $day: NaiveDate!) {
    batchDeleteRosterHours(customerId: $customerId, userId: $userId, day: $day) {
      ...RosterHourFragment
    }
  }
  ${ROSTER_HOUR_FRAGMENT}
`;

export const ROSTER_MONTH_QUERY = gql`
  query rosterMonth($customerId: ID!, $month: NaiveDate!, $filters: RosterMonthFilters) {
    rosterMonth(customerId: $customerId, month: $month, filters: $filters) {
      userId
      sum

      days {
        date
        startTime
        stopTime
        pauseTime
        mostUsedTaskId

        attendanceType {
          ...RosterAttendanceTypeFragment
        }
      }
    }
  }
  ${ATTENDANCE_TYPE_FRAGMENT}
`;

export const COPY_ROSTER_HOURS_MUTATION = gql`
  mutation copyRosterHours($customerId: ID!, $userId: ID!, $sourceDay: NaiveDate!, $targetDay: NaiveDate!) {
    copyRosterHours(customerId: $customerId, userId: $userId, sourceDay: $sourceDay, targetDay: $targetDay) {
      ...RosterHourFragment
    }
  }
  ${ROSTER_HOUR_FRAGMENT}
`;

export const ROSTER_GROUP_FOR_USER_MUTATION = gql`
  mutation mutateRosterGroupsForUser($customerId: ID!, $userId: ID!, $groupIds: [ID]!) {
    mutateRosterGroupsForUser(customerId: $customerId, userId: $userId, groupIds: $groupIds) {
      ...UserFragment
    }
  }
  ${USER_FRAGMENT}
`;

export const ROSTER_TASK_SUBSCRIPTION = gql`
  subscription notifyRosterHour {
    notifyRosterHour {
      ...RosterHourFragment

      task {
        id
        name
      }
    }
  }
  ${ROSTER_HOUR_FRAGMENT}
`;

export const ROSTER_HOUR_MONTH_SUMS_QUERY = gql`
  query rosterHoursMonthSums($customerId: ID!, $month: NaiveDate!, $groupIds: [ID]) {
    rosterHoursMonthSums(customerId: $customerId, month: $month, groupIds: $groupIds) {
      userId
      sum
    }
  }
`;

export const ROSTER_NOTIFICATION_FRAGMENT = gql`
  fragment RosterNotificationFragment on RosterNotification {
    id
    content
    done
    customerId
    insertedAt
    updatedAt
  }
`;

export const ROSTER_NOTIFICATIONS_QUERY = gql`
  query rosterNotifications($customerId: ID!, $filters: RosterNotificationFilters) {
    rosterNotifications(customerId: $customerId, filters: $filters) {
      ...RosterNotificationFragment
    }
  }
  ${ROSTER_NOTIFICATION_FRAGMENT}
`;

export const ROSTER_NOTIFICATION_MARK_DONE_MUTATION = gql`
  mutation markRosterNotificationDone($customerId: ID!, $id: ID!) {
    markRosterNotificationDone(customerId: $customerId, id: $id) {
      ...RosterNotificationFragment
    }
  }
  ${ROSTER_NOTIFICATION_FRAGMENT}
`;
