import { differenceInMonths } from "date-fns";
import type { TFunction } from "i18next";
import _ from "lodash";

import { EVENT_MUTATION, EVENT_TYPES_LIST_QUERY } from "@api/calendar";
import { CONTACT_MUTATION } from "@api/contacts/contacts";
import { TASK_MUTATION } from "@api/tasks";
import { TODO_MUTATION } from "@api/todos";

import apolloClient from "../../../apollo";
import { addDangerFlash, addSuccessFlash } from "../../../components/flash/flashReducer";
import handleError, { MutationError } from "../../../handleError";
import store from "../../../store";
import { naiveDateTime } from "../../../utils/dates";
import { type ValuesType } from "./types";
import { ABS_TODO_USER } from "./values";

async function createFollowUpEvent(session: SessionInterface, values: ValuesType, t: TFunction) {
  try {
    const { data: eventTypeData } = await apolloClient.query<EventTypesDataInterface, EventTypesVariablesInterface>({
      query: EVENT_TYPES_LIST_QUERY,
      variables: {
        customerId: session.currentCustomer.id,
        projectId: session.currentProject.id,
      },
    });

    const eventType = eventTypeData?.eventTypes.find((eventType) => eventType.type === "FOLLOWUP");

    if (!eventType) {
      store.dispatch(addDangerFlash("Oops! Es konnte kein FOLLOWUP-Event-Typ gefunden werden!"));
    }

    const { data } = await apolloClient.mutate<EventMutationInterface, EventMutationVariablesInterface>({
      mutation: EVENT_MUTATION,
      variables: {
        customerId: session.currentCustomer.id,
        projectId: session.currentProject.id,
        event: {
          subject: "Wiedervorlage",
          allDay: false,
          details: values.description,
          startTime: naiveDateTime(values.attrs.followUp),
          endTime: naiveDateTime(values.attrs.followUp),
          contactId: values.contactId,
          ownerId: "1",
          typeId: eventType?.id,
        },
      },
    });

    if (!data?.mutateEvent) {
      throw new MutationError();
    }
  } catch (e) {
    store.dispatch(addDangerFlash(t("translation:global.general_error")));
    handleError(e);
  }
}

const getTodoConcern = (values: ValuesType) => {
  if (values.attrs.reclamation) {
    return "Bestandskundenreklamation";
  }

  return values.attrs.closing ? "Buchung gewünscht" : "Angebot angefordert";
};

async function createTodo(session: SessionInterface, values: ValuesType, t: TFunction) {
  try {
    const { data } = await apolloClient.mutate<TodoMutationInterface>({
      mutation: TODO_MUTATION,
      variables: {
        customerId: session.currentCustomer.id,
        projectId: session.currentProject.id,
        values: {
          description: values.description,
          contactId: values.contactId,
          ownerId: ABS_TODO_USER,
          attrs: {
            concern: getTodoConcern(values),
            bookingDate: values.attrs.bookingDate,
            requestedProduct: values.attrs.requestedProduct,
            requestedPlan: values.attrs.requestedPlan,
          },
        },
      },
    });

    if (!data?.mutateTodo) {
      throw new MutationError();
    }
  } catch (e) {
    store.dispatch(addDangerFlash(t("translation:global.general_error")));
    handleError(e);
  }
}

export const saveTask = async (
  session: SessionInterface,
  existingTask: Nilable<TaskInterface>,
  states: ContactStatusInterface[],
  values: ValuesType,
  t: TFunction,
) => {
  const { bookingDate } = values.attrs;

  if (values.attrs.interested === "Interesse" && bookingDate && differenceInMonths(bookingDate, new Date()) >= 3) {
    values.attrs.qualifiedFollowUp = true;
  } else if (values.attrs.interested === "Interesse" && bookingDate) {
    values.attrs.closing = true;
    createTodo(session, values, t);
  }

  if (values.attrs.interested === "Interesse an anderer Lösung") {
    createTodo(session, values, t);
  } else if (values.attrs.followUp) {
    createFollowUpEvent(session, values, t);
  }

  if (values.attrs.reclamation) {
    createTodo(session, values, t);
  }

  const { statusId, contact } = values;

  try {
    const { data } = await apolloClient.mutate<TaskMutationInterface>({
      mutation: TASK_MUTATION,
      variables: {
        values: _.omit(values, ["infos", "contact", "statusId", "caddfields", "todo"]),
        customerId: session.currentCustomer.id,
        projectId: session.currentProject.id,
        id: existingTask?.id,
      },
    });

    if (!data?.mutateTask) {
      throw new MutationError();
    }

    if (statusId && contact) {
      const status = states.find((s) => s.id === statusId);

      const { data } = await apolloClient.mutate<ContactMutationInterface>({
        mutation: CONTACT_MUTATION,
        variables: {
          customerId: session.currentCustomer.id,
          projectId: session.currentProject.id,
          id: contact.id,
          contact: { statusId: status?.id },
        },
      });

      if (!data?.mutateContact) {
        throw new MutationError();
      }
    }

    const transString = existingTask?.id ? "customerContacts:edit_form.updated" : "customerContacts:new_form.created";

    store.dispatch(addSuccessFlash(t(transString)));

    return data.mutateTask;
  } catch (e) {
    store.dispatch(addDangerFlash(t("translation:global.general_error")));
    handleError(e);
  }
};
