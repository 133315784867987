import clsx from "clsx";
import { useFormikContext } from "formik";
import { Form } from "react-bootstrap";
import { type SingleValue } from "react-select";
import { type Descendant } from "slate";

import type { ValuesType } from ".";
import FormikInput from "../../../containers/FormikInput";
import TTFormGroup from "../../../containers/TTFormGroup";
import { fieldInvalid, fieldValid } from "../../../new_utils";
import SlateEditor from "../../SlateEditor";
import TTSelect from "../../TTSelect";

const PROBLEM_TYPES = [
  { label: "Kein Ton", value: "NO_SOUND" },
  { label: "Kamera geht nicht", value: "CAMERA_NOT_WORKING" },
  { label: "Monitor bleibt schwarz", value: "BLACK_SCREEN" },
  { label: "Status lässt sich nicht ändern", value: "STATUS_NOT_CHANGING" },
  { label: "Mitarbeiter kann sich nicht anmelden", value: "LOGIN_PROBLEM" },
  { label: "anderes Problem", value: "OTHER" },
];

export default function Inner() {
  const { values, errors, touched, setFieldValue } = useFormikContext<ValuesType>();

  function updateValues(value: SingleValue<(typeof PROBLEM_TYPES)[number]>) {
    if (!value) {
      return;
    }

    setFieldValue("attrs.problemType", value.value);
    setFieldValue("document", {
      content: [
        {
          type: "paragraph",
          children: [
            {
              text: `Bei Workstation ${values.attrs.workstation || "…"} ist folgendes Problem aufgetreten: ${value.label}`,
            },
          ],
        },
      ],
    });

    if (value.value !== "OTHER") {
      setFieldValue("subject", PROBLEM_TYPES.find((problem) => problem.value === value.value)?.label || "");
    }
  }

  function updateDescription(event: React.ChangeEvent<HTMLInputElement>) {
    const problemType = PROBLEM_TYPES.find((problem) => problem.value === values.attrs.problemType)?.label || "…";

    setFieldValue("attrs.workstation", event.target.value);
    setFieldValue("document", {
      content: [
        {
          type: "paragraph",
          children: [
            {
              text: `Bei Workstation ${event.target.value} ist folgendes Problem aufgetreten: ${problemType}`,
            },
          ],
        },
      ],
    });
  }

  function handleDescriptionChange(value: Descendant[]) {
    setFieldValue("document.content", value);
  }

  return (
    <>
      <TTFormGroup>
        <Form.Label htmlFor="attrs.problemType">Problem</Form.Label>
        <TTSelect
          id="attrs.problemType"
          name="attrs.problemType"
          placeholder="Problem wählen…"
          onChange={updateValues}
          options={PROBLEM_TYPES}
          className={clsx("TT-formik-react-select", {
            "is-invalid": fieldInvalid(errors, touched, "attrs.problemType"),
            "is-valid": fieldValid(errors, touched, "attrs.problemType"),
          })}
        />
      </TTFormGroup>

      <TTFormGroup>
        <Form.Label htmlFor="attrs.workstation">Workstation</Form.Label>
        <FormikInput
          id="attrs.workstation"
          name="attrs.workstation"
          type="text"
          placeholder="Workstation eingeben…"
          onChange={updateDescription}
        />
        <Form.Text className="text-muted">
          Die Nummer der Workstation steht auf dem Aufkleber am Computer, z.B. „WS01“
        </Form.Text>
      </TTFormGroup>

      {values.attrs.problemType === "OTHER" && (
        <TTFormGroup>
          <Form.Label htmlFor="subject">Betreff</Form.Label>
          <FormikInput id="subject" name="subject" type="text" placeholder="Betreff eingeben…" />
        </TTFormGroup>
      )}

      <TTFormGroup>
        <Form.Label htmlFor="document">Beschreibung</Form.Label>
        <SlateEditor id="document" value={values.document.content} onChange={handleDescriptionChange} />
      </TTFormGroup>
    </>
  );
}
