import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { startOfMonth } from "date-fns";

import type { RootState } from "../../store";

export type StatsReducerUserStateType = null | "locked" | "active";
export type StatsReducerCapacityType = {
  days: number[];
  eventsWanted: number;
  month: Date;
};
export type StatsReducerType = {
  activeTab: RangeType;
  activeDate: Date;
  activeDateStop: Date;
  team: null | TeamInterface;
  hideUsersByState: StatsReducerUserStateType;
  onlyStornos: boolean;
  capacity: StatsReducerCapacityType;
  activeProject: Nullable<BaseProjectInterface>;
  statsObject: string;
};

const initialState: StatsReducerType = {
  activeTab: "today",
  activeDate: new Date(),
  activeDateStop: new Date(),
  team: null,
  onlyStornos: false,
  hideUsersByState: null,
  activeProject: null,
  capacity: {
    days: [],
    eventsWanted: 0,
    month: startOfMonth(new Date()),
  },
  statsObject: "TICKET",
};

const statsSlice = createSlice({
  name: "stats",
  initialState,
  reducers: {
    setTeam(state, action: PayloadAction<Nullable<TeamInterface>>) {
      state.team = action.payload;
    },
    setHideUsersByState(state, action: PayloadAction<StatsReducerUserStateType>) {
      state.hideUsersByState = action.payload;
    },
    setActiveDate(state, action: PayloadAction<{ tab: RangeType; start: Date; stop?: Date }>) {
      state.activeTab = action.payload.tab;
      state.activeDate = action.payload.start;

      if (action.payload.stop) {
        state.activeDateStop = action.payload.stop;
      }
    },
    setActiveTab(state, action: PayloadAction<RangeType>) {
      state.activeTab = action.payload;
    },
    toggleOnlyStornos(state) {
      state.onlyStornos = !state.onlyStornos;
    },
    resetCapacity(state, action: PayloadAction<{ days: number[]; wanted: number }>) {
      state.capacity.days = action.payload.days;
      state.capacity.eventsWanted = action.payload.wanted;
    },
    setCapacityDays(state, action: PayloadAction<number[]>) {
      const days = [...action.payload];
      days.sort();
      state.capacity.days = days;
    },
    setCapacityEventsWanted(state, action: PayloadAction<number>) {
      state.capacity.eventsWanted = action.payload;
    },
    setCapacityMonth(state, action: PayloadAction<Date>) {
      state.capacity.month = action.payload;
    },
    setActiveProject(state, action: PayloadAction<Nullable<BaseProjectInterface>>) {
      state.activeProject = action.payload;
    },
    setStatsObject(state, action: PayloadAction<string>) {
      state.statsObject = action.payload;
    },
  },
});

export const {
  setTeam,
  setHideUsersByState,
  setActiveDate,
  setActiveTab,
  toggleOnlyStornos,
  resetCapacity,
  setCapacityDays,
  setCapacityEventsWanted,
  setCapacityMonth,
  setActiveProject,
  setStatsObject,
} = statsSlice.actions;
export const statsSelector = (state: RootState) => state.stats;
export default statsSlice.reducer;
