import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";
import { nanoid } from "nanoid";

import { AppThunk, RootState } from "../../store";

const initialState: FlashMessageInterface[] = [];

const flashMessagesSlice = createSlice({
  name: "flash",
  initialState,
  reducers: {
    addNewFlashMessage(state, action: PayloadAction<FlashMessageInterface>) {
      state.push(action.payload);
    },
    deleteFlashMessage(state, action: PayloadAction<string>) {
      return state.filter((msg) => msg.id !== action.payload);
    },
  },
});

const timeoutByType = (type: FlashMessageType) => (type === "success" ? 5000 : null);

const addFlashMessage = (message: FlashMessageInterface, timeout: Nullable<number> = null): AppThunk => {
  if (_.isUndefined(timeout)) {
    timeout = timeoutByType(message.type);
  }

  message = { ...message, id: nanoid() };

  return (dispatch) => {
    dispatch(addNewFlashMessage(message));

    if (timeout) {
      window.setTimeout(() => dispatch(deleteFlashMessage(message.id)), timeout);
    }
  };
};

export const addSuccessFlash = (body: string, timeout: Nullable<number> = 5000) =>
  addFlashMessage({ id: "", type: "success", body }, timeout);
export const addWarningFlash = (body: string, timeout: Nullable<number> = 5000) =>
  addFlashMessage({ id: "", type: "warning", body }, timeout);
export const addDangerFlash = (body: string, timeout: Nullable<number> = null) =>
  addFlashMessage({ id: "", type: "danger", body }, timeout);

export const { addNewFlashMessage, deleteFlashMessage } = flashMessagesSlice.actions;
export const flashSelector = (state: RootState) => state.flash;
export default flashMessagesSlice.reducer;
