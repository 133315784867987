import { featureEnabled, projectFeatureEnabled, userFeatureEnabled } from "./helpers";

export interface ProjectNewsMayInterface {
  (session: SessionInterface, path: "project-news"): boolean;
  (session: SessionInterface, path: "project-news", action: "new" | "edit" | "delete"): boolean;
}

const ProjectNewsPermissions: PermissionType<any> = {
  "project-news": {
    index: ({ currentUser, currentProject }) => featureEnabled(currentUser, currentProject, "project_news"),
    new: ({ currentUser, currentProject }) =>
      projectFeatureEnabled(currentProject, "project_news") && userFeatureEnabled(currentUser, "project_news/write"),
    edit: ({ currentUser, currentProject }) =>
      projectFeatureEnabled(currentProject, "project_news") && userFeatureEnabled(currentUser, "project_news/write"),
    delete: ({ currentUser, currentProject }) =>
      projectFeatureEnabled(currentProject, "project_news") && userFeatureEnabled(currentUser, "project_news/write"),
  },
};

export default ProjectNewsPermissions;
