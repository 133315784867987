import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../../store";

export interface PunchlistReducerInterface {
  search: string;
  page: number;
  status: PunchlistEntryStatusType[];
  category: PunchlistEntryCategoryType[];
  user: Nullable<UserInterface>;
  starts: Nullable<Date>;
  stops: Nullable<Date>;
}

const initialState: PunchlistReducerInterface = {
  search: "",
  page: 0,
  status: ["NEW", "RESOLVED", "QUERY"],
  category: [],
  user: null,
  starts: null,
  stops: null,
};

const ticketsSlice = createSlice({
  name: "punchlist",
  initialState,
  reducers: {
    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    setPage(state, action: PayloadAction<number>) {
      state.page = action.payload;
    },
    setStatus(state, action: PayloadAction<PunchlistEntryStatusType[]>) {
      state.status = action.payload;
    },
    setCategory(state, action: PayloadAction<PunchlistEntryCategoryType[]>) {
      state.category = action.payload;
    },
    setUser(state, action: PayloadAction<Nullable<UserInterface>>) {
      state.user = action.payload;
    },
    setStarts(state, { payload }: PayloadAction<Nullable<Date>>) {
      state.starts = payload;
    },
    setStops(state, { payload }: PayloadAction<Nullable<Date>>) {
      state.stops = payload;
    },
    resetFilters(state) {
      state.search = "";
      state.page = 0;
      state.status = ["NEW", "RESOLVED", "QUERY"];
      state.category = [];
      state.user = null;
      state.starts = null;
      state.stops = null;
    },
  },
});

export const { setSearch, setPage, setStatus, setCategory, setUser, setStarts, setStops, resetFilters } =
  ticketsSlice.actions;
export const punchlistSelector = (state: RootState) => state.punchlist;
export default ticketsSlice.reducer;
