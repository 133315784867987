import { useCallback, useEffect } from "react";

import { useQuery } from "@apollo/client";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { MdMessage } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";

import { messagesPath } from "@path/messages_paths";
import { showMessagePath } from "@path/messages_paths";

import { useAppSelector } from "../../../hooks";
import { sessionSelector } from "../../../store/sessionReducer";
import { messageSubject } from "../../messages/utils";
import { COUNT_MESSAGES_QUERY, COUNT_MESSAGES_SUBSCRIPTION } from "./queries";
import type { CountMessagesQueryResultInterface } from "./types";

export default function Messages() {
  const session = useAppSelector(sessionSelector);
  const { currentUser } = session;
  const hasMessages = _.includes(currentUser.flags, "messages");
  const { t } = useTranslation(["translation", "messages"]);
  const navigate = useNavigate();

  const { data, subscribeToMore, refetch } = useQuery<CountMessagesQueryResultInterface>(COUNT_MESSAGES_QUERY, {
    skip: !hasMessages,
  });

  useEffect(() => {
    if (hasMessages) {
      subscribeToMore({
        document: COUNT_MESSAGES_SUBSCRIPTION,
        updateQuery: (prev, { subscriptionData }) => subscriptionData.data || prev,
      });
    }
  }, [subscribeToMore, hasMessages]);

  const unread = parseInt(data?.countMessages?.unread || "0", 10) || 0;
  const showNotification = useCallback(
    (event: MessageNotificationEvent) => {
      const notification = new Notification(t("messages:notification.title"), {
        body: messageSubject(t, event.detail),
        icon: "/apple-icon-152x152.png",
      });

      notification.onclick = () => {
        notification.close();
        navigate(showMessagePath(session, event.detail));
      };
    },
    [session, t, navigate],
  );

  // TODO: backend_rs: remove when Elixir backend has been replaced
  useEffect(() => {
    if (hasMessages) {
      document.addEventListener("tt:messagesChanged", refetch);
      return () => {
        document.removeEventListener("tt:messagesChanged", refetch);
      };
    }
  }, [refetch, hasMessages]);

  useEffect(() => {
    document.addEventListener("tt:messageNotification", showNotification);
    return () => {
      document.removeEventListener("tt:messageNotification", showNotification);
    };
  });

  if (!hasMessages || unread <= 0) {
    return null;
  }

  return (
    <Link
      className="TT-unseen-messages"
      to={messagesPath(session)}
      title={t("translation:sidebar.messages", { count: unread })}
    >
      <MdMessage /> {unread}
    </Link>
  );
}
