import React, { FunctionComponent, ReactNode } from "react";

type PropsType = {
  onClick: (customer: FullCustomerInterface) => void;
  customer: FullCustomerInterface;
  tag: string;
  className?: string;
  children?: ReactNode;
};

const Customer: FunctionComponent<PropsType> = ({ customer, onClick, tag, children, className }) => {
  function doOnClick() {
    onClick(customer);
  }

  const Tag: any = tag || "div";

  return (
    <Tag onClick={doOnClick} className={className}>
      <img src={customer.logo.medium} alt="" className="TT-switcher-logo-img" />
      <h3 className="TT-switcher-customer-name">{customer.name}</h3>
      {children}
    </Tag>
  );
};

export default React.memo<FunctionComponent<PropsType>>(Customer);
