import { MdDelete } from "react-icons/md";

import ConfirmButton from "./ConfirmButton";

const DeleteButton: TTButtonProps = ({ children, as: component, ...props }) => {
  const Element = component || ConfirmButton;

  return (
    <Element {...props}>
      <MdDelete /> {children}
    </Element>
  );
};

export default DeleteButton;
