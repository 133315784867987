import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../../../store";

export type SwitcherReducerType = {
  show: boolean;
  onlyActive: boolean;
  customer: Nullable<FullCustomerInterface>;
};

const initialState: SwitcherReducerType = {
  show: false,
  onlyActive: true,
  customer: null,
};

const switcherSlice = createSlice({
  name: "switcher",
  initialState,
  reducers: {
    toggleModal(state) {
      state.show = !state.show;
    },

    setCustomer(state, action: PayloadAction<Nullable<FullCustomerInterface>>) {
      state.customer = action.payload;
    },

    setOnlyActive(state, action: PayloadAction<boolean>) {
      state.onlyActive = action.payload;
    },
  },
});

export const { toggleModal, setCustomer, setOnlyActive } = switcherSlice.actions;
export const switcherSelector = (state: RootState) => state.switcher;
export default switcherSlice.reducer;
