import type { TFunction } from "i18next";

export const messageSubject = (t: TFunction, message: MessageInterface): string => {
  if (message.format === "LOCALIZED") {
    return t(`messages:${message.subjectI18nId!}`, message.subjectParams!);
  }

  return message.subject || "";
};

export const messageContent = (t: TFunction, message: MessageInterface) => {
  if (message.format === "PLAIN") {
    return <pre>{message.content}</pre>;
  } else if (message.format === "HTML") {
    return <div className="form-control-plaintext redacted" dangerouslySetInnerHTML={{ __html: message.content! }} />;
  }

  return (
    <div
      className="form-control-plaintext redacted"
      dangerouslySetInnerHTML={{ __html: t(`messages:${message.contentI18nId!}`, message.contentParams!) }}
    />
  );
};
