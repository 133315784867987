import { ButtonGroup } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { editDocumentPath, showDocumentPath } from "@path/document_paths";

import { may } from "../../../abilities";
import ActionButtonGroup from "../../../containers/actions/ActionButtonGroup";
import { DeleteButton, EditButton, ShowButton } from "../../../containers/buttons";
import { NoDataTag } from "../../../containers/NoData";
import { useAppSelector } from "../../../hooks";
import { sessionSelector } from "../../../store/sessionReducer";
import type { DocumentsListPropsInterface } from "../types";

export default function CompactList({ documents, deleteDocument, showDocument }: DocumentsListPropsInterface) {
  const session = useAppSelector(sessionSelector);
  const { t } = useTranslation(["translation"]);

  return (
    <ul className="TT-documents-compactList">
      <NoDataTag data={documents} />

      {documents.map((doc) => {
        const docSession = {
          ...session,
          currentCustomer: doc.customer,
          currentProject: doc.project as ProjectInterface,
        };

        return (
          <li key={doc.id}>
            <div className="subject">
              {t("translation:global.subject")}: {doc.subject}
            </div>
            <div className="relevant-project">
              {t("translation:global.project")}: {doc.relevantProject.customer.name} / {doc.relevantProject.name}
            </div>
            <div className="keywords">
              {doc.keywords.length !== 0 && (
                <>
                  {t("translation:global.tags")}: {doc.keywords.join(", ")}
                </>
              )}
            </div>
            <ActionButtonGroup>
              <ButtonGroup size="sm">
                {may(docSession, "documents", "show", doc) && showDocument && (
                  <ShowButton onClick={() => showDocument(doc)}>{t("translation:global.details")}</ShowButton>
                )}
                {may(docSession, "documents", "show", doc) && !showDocument && (
                  <ShowButton as={Link} to={showDocumentPath(docSession, doc)}>
                    {t("translation:global.details")}
                  </ShowButton>
                )}
                {may(docSession, "documents", "edit", doc) && (
                  <EditButton as={Link} to={editDocumentPath(docSession, doc)}>
                    {t("translation:global.edit")}
                  </EditButton>
                )}
                {may(docSession, "documents", "delete", doc) && deleteDocument && (
                  <DeleteButton onClick={() => deleteDocument(doc)}>{t("translation:global.delete")}</DeleteButton>
                )}
              </ButtonGroup>
            </ActionButtonGroup>
          </li>
        );
      })}
    </ul>
  );
}
