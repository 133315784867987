import { ApolloClient, from, InMemoryCache, split } from "@apollo/client";
import { HttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import * as Sentry from "@sentry/react";
import createUploadLink from "apollo-upload-client/createUploadLink.mjs";
import { createClient } from "graphql-ws";
import _ from "lodash";

import { getAuthorizationToken } from "../authorization_token";
import { authLink, GRAPHQL_URI, GRAPHQL_URI_RS, GRAPHQL_URI_WS_RS, sentryLink } from "../graphqlUtils";
import { setContext } from "../sentry";

declare module "@apollo/client" {
  interface DataMasking {
    mode: "unmask";
  }
}

const httpLink = new HttpLink({ uri: GRAPHQL_URI });
const httpLinkRs = createUploadLink({ uri: GRAPHQL_URI_RS });
const client = createClient({
  url: GRAPHQL_URI_WS_RS,
  shouldRetry: () => true,
  lazy: true,
  keepAlive: 10_000,
});

const wsLink = new GraphQLWsLink(client);

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors && "filter" in graphQLErrors) {
    graphQLErrors
      .filter(({ message }) => message !== "not_found")
      .forEach(({ message, locations, path, extensions }) => {
        if (["login", "refresh"].includes(operation.operationName)) return;

        console.error(
          `[GraphQL error]: Message: ${message}, Location:`,
          locations,
          "Path:",
          path,
          "extensions",
          extensions,
          "Operation:",
          operation.operationName,
          operation,
        );

        if (process.env.NODE_ENV === "production") {
          const variables = _.omit(operation.variables, ["password", "values.password"]);

          const errorContext = {
            extensions: JSON.stringify(extensions, undefined, 2),
            locations: JSON.stringify(locations, undefined, 2),
            path: JSON.stringify(path, undefined, 2),
            operationName: operation.operationName,
            operation: JSON.stringify(path, undefined, 2),
            variables: JSON.stringify(variables, undefined, 2),
          };

          const err = new Error(message);
          err.name = "graphQLError";
          if ("captureStackTrace" in Error) Error.captureStackTrace(err);

          Sentry.setTag("type", "graphql");

          Sentry.setContext("graphqlContext", errorContext);

          setContext();

          Sentry.captureException(err);
        }
      });
  }

  if (graphQLErrors && !("filter" in graphQLErrors)) {
    console.error("[unknown GraphQL error]", graphQLErrors);

    if (process.env.NODE_ENV === "production") {
      const err = new Error("unknown GraphQL error structure");
      err.name = "graphQLError";
      if ("captureStackTrace" in Error) Error.captureStackTrace(err);

      Sentry.setTag("type", "graphql");
      setContext();

      Sentry.captureException(err);
    }
  }

  if (networkError) {
    console.error("[Network error]: ", networkError);
  }
});

const link = from([sentryLink, errorLink, authLink(() => getAuthorizationToken()).concat(httpLink)]);
const linkRs = from([sentryLink, errorLink, authLink(() => getAuthorizationToken()).concat(httpLinkRs as any)]);

const EX_OPERTAIONS: Record<string, boolean> = {
  jobResultCreate: true,
  deletePresettlement: true,
  mutatePresettlement: true,
  presettlements: true,
  presettlementValidation: true,
  countPresettlements: true,
  presettlementSuggestion: true,
  presettlement: true,
  deleteSettlement: true,
  mutateSettlement: true,
  markReadSettlement: true,
  settlements: true,
  countSettlements: true,
  settlement: true,
  mutateWorktime: true,
  customerContactDashboard: true,
  customerContactsStats: true,
  customerContactAttrStats: true,
  customerContactAttrStatsByHour: true,
  regenContactStates: true,
  todoDashboard: true,
  workerStats: true,
  dashboardStats: true,
};

const apolloClient = new ApolloClient({
  link: split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === "OperationDefinition" && definition.operation === "subscription";
    },
    wsLink,
    split((op) => EX_OPERTAIONS[op.operationName], link, linkRs),
  ),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network",
    },
    query: {
      fetchPolicy: "network-only",
    },
  },
});

export default apolloClient;
