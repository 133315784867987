import { RS_API_URI } from "@api/session";

import { JobResultType } from "../components/jobs/Show";
import { queryString, sessionPrefix } from "./helpers";

export const jobsPath = (session: LightSessionInterface, args: QueryStringType = null) =>
  `${sessionPrefix(session)}/jobs${queryString(args)}`;
export const showJobPath = (session: LightSessionInterface, job: JobResultType | JobResultInterface, args = {}) =>
  `${sessionPrefix(session)}/jobs/${job.id}${queryString(args)}`;

export const jobDownloadPath = (job: JobResultType) => `${RS_API_URI}/jobs/${job.id}`;
