import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../../store";

export type ReminderNotificationReducer = {
  done: Nullable<boolean>;
};

const initialState: ReminderNotificationReducer = {
  done: false,
};

const reminderNotificationSlice = createSlice({
  name: "reminderNotificationList",
  initialState,
  reducers: {
    setDone(state, { payload }: PayloadAction<Nullable<boolean>>) {
      state.done = payload;
    },

    resetFilters(state) {
      state.done = false;
    },
  },
});

export const { setDone, resetFilters } = reminderNotificationSlice.actions;
export const reminderNotificationSelector = (state: RootState) => state.reminderNotifications;
export default reminderNotificationSlice.reducer;
