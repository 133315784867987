import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { endOfDay, startOfDay } from "date-fns";

import type { RootState } from "../../../store";

export interface TimesheetReducerInterface {
  starts: Date;
  stops: Date;
  activeTab: RangeType;
  search: string;
  team: TeamInterface | null;
  year: number;
  month: Date;
}

const initialState: TimesheetReducerInterface = {
  starts: startOfDay(new Date()),
  stops: endOfDay(new Date()),
  activeTab: "today",
  search: "",
  team: null,
  year: new Date().getFullYear(),
  month: new Date(),
};

const timesheetsSlice = createSlice({
  name: "timesheets",
  initialState,
  reducers: {
    setTab(state, action: PayloadAction<RangeType>) {
      state.activeTab = action.payload;
    },

    setStarts(state, action: PayloadAction<Date>) {
      state.starts = action.payload;
    },

    setStops(state, action: PayloadAction<Date>) {
      state.stops = action.payload;
    },

    setSearch(state, { payload }: PayloadAction<string>) {
      state.search = payload;
    },

    setYear(state, { payload }: PayloadAction<number>) {
      state.year = payload;
    },

    setMonth(state, { payload }: PayloadAction<Date>) {
      state.month = payload;
    },

    setTeam(state, { payload }: PayloadAction<TeamInterface | null>) {
      state.team = payload;
    },

    resetFilters(state) {
      state.starts = startOfDay(new Date());
      state.stops = endOfDay(new Date());
      state.activeTab = "today";
      state.search = "";
      state.team = null;
      state.year = new Date().getFullYear();
      state.month = new Date();
    },
  },
});

export const timesheetSelector = (state: RootState) => state.timesheets;
export const { setStarts, setStops, setTab, setSearch, setYear, setMonth, setTeam, resetFilters } =
  timesheetsSlice.actions;
export default timesheetsSlice.reducer;
