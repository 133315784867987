import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { NavGroup } from "../../containers/SubSubNav";
import type { RootState } from "../../store";

export interface ShortcutNavInterface<T = any> {
  id: string;
  nav: React.ComponentType<T>;
  props?: T;
}

type SubSubNavType = { id: string; groups: NavGroup[] };

export interface ScaffoldReducerInterface {
  shortcuts: ShortcutNavInterface[];
  subSubNav: SubSubNavType[];
}

const initialState: ScaffoldReducerInterface = {
  shortcuts: [],
  subSubNav: [],
};

const scaffoldSlice = createSlice({
  name: "scaffold",
  initialState,
  reducers: {
    addShortcutNav(state, action: PayloadAction<ShortcutNavInterface>) {
      const shortcuts = state.shortcuts.filter((item) => item.id !== action.payload.id);
      shortcuts.push(action.payload);
      state.shortcuts = shortcuts;
    },
    removeShortcutNav(state, action: PayloadAction<string>) {
      state.shortcuts = state.shortcuts.filter((shortcut) => shortcut.id !== action.payload);
    },
    addSubSubNav(state, { payload }: PayloadAction<{ groups: NavGroup[]; id: string }>) {
      state.subSubNav = [{ groups: payload.groups, id: payload.id }, ...state.subSubNav];
    },
    removeSubSubNav(state, action: PayloadAction<string>) {
      state.subSubNav = state.subSubNav.filter((item) => item.id !== action.payload);
    },
  },
});

export const { addShortcutNav, removeShortcutNav, addSubSubNav, removeSubSubNav } = scaffoldSlice.actions;
export const scaffoldSelector = (state: RootState) => state.scaffold;
export default scaffoldSlice.reducer;
