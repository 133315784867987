import { queryString, sessionPrefix } from "./helpers";

export const customerContactsPath = (session: LightSessionInterface, args: QueryStringType = null) =>
  `${sessionPrefix(session)}/customer-contacts${queryString(args)}`;
export const newCustomerContactPath = (session: LightSessionInterface, args = {}) =>
  `${sessionPrefix(session)}/customer-contacts/new${queryString(args)}`;

export const editCustomerContactPath = <T = Record<string, unknown>>(
  session: LightSessionInterface,
  cc: TaskInterface<T>,
  args: QueryStringType = null,
): string => {
  return `${sessionPrefix(session)}/customer-contacts/${cc.id}/edit${queryString(args)}`;
};

export const showCustomerContactPath = <T = Record<string, unknown>>(
  session: LightSessionInterface,
  cc: TaskInterface<T>,
  args: QueryStringType = null,
) => `${sessionPrefix(session)}/customer-contacts/${cc.id}${queryString(args)}`;

export const exportCustomerContactPath = (session: LightSessionInterface, args: QueryStringType = null) =>
  `${sessionPrefix(session)}/customer-contacts/export${queryString(args)}`;

export const CustomerContactsAuditPath = (session: LightSessionInterface, args: QueryStringType = null) =>
  `${sessionPrefix(session)}/customer-contacts/audit${queryString(args)}`;

export const tasksOffersPath = (session: LightSessionInterface) => `${sessionPrefix(session)}/customer-contacts/offers`;
export const newTasksOfferPath = (session: LightSessionInterface) =>
  `${sessionPrefix(session)}/customer-contacts/offers/new`;
export const editTasksOfferPath = (session: LightSessionInterface, offer: TaskOfferInterface) =>
  `${sessionPrefix(session)}/customer-contacts/offers/${offer.id}/edit`;
export const showTasksOfferPath = (session: LightSessionInterface, offer: TaskOfferInterface) =>
  `${sessionPrefix(session)}/customer-contacts/offers/${offer.id}`;

export const newTasksProductPath = (session: LightSessionInterface, offer: TaskOfferInterface) =>
  `${sessionPrefix(session)}/customer-contacts/offers/${offer.id}/products/new`;
export const editTasksProductPath = (
  session: LightSessionInterface,
  offer: TaskOfferInterface,
  product: TaskProductInterface,
) => `${sessionPrefix(session)}/customer-contacts/offers/${offer.id}/products/edit/${product.id}`;
export const deleteTasksProductPath = (
  session: LightSessionInterface,
  offer: TaskOfferInterface,
  product: TaskProductInterface,
) => `${sessionPrefix(session)}/customer-contacts/offers/${offer.id}/products/delete/${product.id}`;
