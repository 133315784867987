import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import _ from "lodash";

import type { RootState } from "../../store";

export interface MailsReducerInterface {
  from: Nullable<Date>;
  to: Nullable<Date>;
  direction: Nullable<MailDirectionType>;
  states: MailStatusType[];
  attrFilter: Record<string, AttributeFilterType>;
  recipient: Nullable<string>;
  sender: Nullable<string>;
  isSpam: boolean;
  labels: string[];
  folder: Nullable<string>;
  account: Nullable<string>;
  search: Nullable<string>;
  ownerId: Nullable<string>;
}

const initialState: MailsReducerInterface = {
  from: null,
  to: null,
  direction: null,
  states: [],
  attrFilter: {},
  recipient: null,
  sender: null,
  isSpam: false,
  labels: [],
  folder: null,
  account: null,
  search: null,
  ownerId: null,
};

const mailsSlice = createSlice({
  name: "mails",
  initialState,
  reducers: {
    setFrom(state, { payload }: PayloadAction<Nullable<Date>>) {
      state.from = payload;
    },
    setTo(state, { payload }: PayloadAction<Nullable<Date>>) {
      state.to = payload;
    },
    setDirection(state, { payload }: PayloadAction<Nullable<MailDirectionType>>) {
      state.direction = payload;
    },
    setStates(state, { payload }: PayloadAction<MailStatusType[]>) {
      state.states = payload;
    },
    setAttrFilter(state, { payload }: PayloadAction<{ name: string; value: Nilable<AttributeFilterType> }>) {
      if (payload.value === null || payload.value === undefined) {
        state.attrFilter = _.omit(state.attrFilter, [payload.name]);
      } else {
        state.attrFilter = { ...state.attrFilter, [payload.name]: payload.value };
      }
    },
    setSender(state, { payload }: PayloadAction<Nullable<string>>) {
      state.sender = payload;
    },
    setRecipient(state, { payload }: PayloadAction<Nullable<string>>) {
      state.recipient = payload;
    },
    setIsSpam(state, { payload }: PayloadAction<boolean>) {
      state.isSpam = payload;
    },
    setLabels(state, { payload }: PayloadAction<string[]>) {
      state.labels = payload;
    },
    addLabel(state, { payload }: PayloadAction<string>) {
      state.labels = [...state.labels, payload];
    },
    removeLabel(state, { payload }: PayloadAction<string>) {
      state.labels = state.labels.filter((label) => label !== payload);
    },
    setFolder(state, { payload }: PayloadAction<Nullable<string>>) {
      state.folder = payload;
    },
    setAccount(state, { payload }: PayloadAction<Nullable<string>>) {
      state.account = payload;
    },
    setSearch(state, { payload }: PayloadAction<Nullable<string>>) {
      state.search = payload;
    },
    setOwnerId(state, { payload }: PayloadAction<Nullable<string>>) {
      state.ownerId = payload;
    },

    resetFilters(state) {
      state.from = null;
      state.to = null;
      state.direction = null;
      state.states = [];
      state.attrFilter = {};
      state.recipient = null;
      state.sender = null;
      state.labels = [];
      state.folder = null;
      state.account = null;
      state.isSpam = false;
      state.search = null;
      state.ownerId = null;
    },
  },
});

export const {
  setFrom,
  setTo,
  setDirection,
  setStates,
  setAttrFilter,
  setRecipient,
  setSender,
  setIsSpam,
  setLabels,
  addLabel,
  removeLabel,
  setFolder,
  setAccount,
  setSearch,
  setOwnerId,
  resetFilters,
} = mailsSlice.actions;
export const mailsSelector = (state: RootState) => state.mails;
export default mailsSlice.reducer;
