import { useEffect, useRef, useState } from "react";

import RecordRTC from "recordrtc";

const CHUNK_INTERVAL = 500;
const SPEECH_TO_TEXT_URL =
  process.env.NODE_ENV === "production" ? "wss://termitool.de/api/speech-to-text/v2" : "ws://127.0.0.1:8080";

const useSpeechToText = (setValue: (text: string) => void) => {
  const [isRecording, setIsRecording] = useState(false);
  const recorderRef = useRef<Nullable<RecordRTC>>(null);
  const localStreamRef = useRef<MediaStream | null>(null);
  const webSocketRef = useRef<WebSocket | null>(null);
  const checkChunkInterval = useRef<NodeJS.Timeout | null>(null);

  const initializeWebSocket = () => {
    if (!webSocketRef.current) {
      webSocketRef.current = new WebSocket(SPEECH_TO_TEXT_URL);

      webSocketRef.current.onopen = () => {
        console.log("Successfully connected to the server");
      };

      webSocketRef.current.onmessage = (event) => {
        const { text } = JSON.parse(event.data);
        setValue(text);
      };

      webSocketRef.current.onclose = () => {
        console.log("WebSocket connection closed");
      };
    }
  };

  useEffect(() => {
    initializeWebSocket();

    return () => {
      finalizeRecording();
      if (webSocketRef.current) {
        webSocketRef.current.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendAudioChunk = (chunk: Blob) => {
    const reader = new FileReader();
    reader.onloadend = (event) => {
      if (event.target && event.target.result) {
        const audioData = event.target.result;
        webSocketRef.current?.send(audioData);
      }
    };
    reader.readAsArrayBuffer(chunk);
  };

  const startRecording = () => {
    setIsRecording(true);

    if (!webSocketRef.current) {
      initializeWebSocket();
    }

    navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
      localStreamRef.current = stream;

      recorderRef.current = new RecordRTC(stream, {
        type: "audio",
        mimeType: "audio/wav",
        sampleRate: 48000,
        disableLogs: true,
      });

      recorderRef.current.startRecording();

      if (checkChunkInterval.current) {
        clearInterval(checkChunkInterval.current);
        checkChunkInterval.current = null;
      }

      checkChunkInterval.current = setInterval(() => {
        if (recorderRef.current) {
          recorderRef.current.stopRecording(() => {
            sendAudioChunk(recorderRef.current!.getBlob());
            recorderRef.current!.reset();
            recorderRef.current!.startRecording();
          });
        }
      }, CHUNK_INTERVAL);
    });
  };

  const finalizeRecording = () => {
    if (recorderRef.current) {
      recorderRef.current.stopRecording(() => {
        if (checkChunkInterval.current) {
          clearInterval(checkChunkInterval.current);
          checkChunkInterval.current = null;
        }

        if (localStreamRef.current) {
          localStreamRef.current.getTracks().forEach((track) => track.stop());
        }
      });
    }
  };

  const stopRecording = () => {
    setIsRecording(false);
    finalizeRecording();
    if (webSocketRef.current) {
      webSocketRef.current.close();
      webSocketRef.current = null;
    }
  };

  return {
    isRecording,
    setIsRecording,
    startRecording,
    stopRecording,
  };
};

export default useSpeechToText;
