import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { endOfDay, startOfDay, startOfMonth } from "date-fns";
import _ from "lodash";

import type { RootState } from "../../../store";
import { VACATION_REQUEST_STATES } from "../Vacation/utils";

export interface WorktimesReducerInterface {
  starts: Nullable<Date>;
  stops: Nullable<Date>;
  month: Date;
  user: Nullable<UserInterface>;
  filter: Nullable<string>;
  taskId: Nullable<string>;
  declined: Nullable<boolean>;
  modified: Nullable<boolean>;
  unconfirmedWorktimes: WorktimeInterface[];
  employeeListTab: string;

  vacationRequestType: keyof typeof VACATION_REQUEST_STATES | null;

  employeePage: number;
  vacationRequestsPage: number;
  showTaskModal: boolean;
  showCustomerModal: boolean;

  team: Nullable<TeamInterface>;

  showWarning: boolean;
}

const initialState: WorktimesReducerInterface = {
  starts: startOfDay(new Date()),
  stops: endOfDay(new Date()),
  month: startOfMonth(new Date()),
  user: null,
  filter: null,
  taskId: null,
  declined: null,
  modified: null,
  unconfirmedWorktimes: [],
  vacationRequestType: null,
  employeeListTab: "MANAGEMENT",
  employeePage: 0,
  vacationRequestsPage: 0,
  showTaskModal: false,
  showCustomerModal: false,

  team: null,

  showWarning: false,
};

const worktimesSlice = createSlice({
  name: "worktimes",
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<Nullable<UserInterface>>) {
      state.user = action.payload;
    },
    setStarts(state, action: PayloadAction<Nullable<Date>>) {
      state.starts = action.payload;
    },
    setStops(state, action: PayloadAction<Nullable<Date>>) {
      state.stops = action.payload;
    },
    setMonth(state, action: PayloadAction<Date>) {
      state.month = action.payload;
    },
    setFilter(state, action: PayloadAction<Nullable<string>>) {
      state.filter = action.payload;
    },
    setTaskId(state, action: PayloadAction<Nullable<string>>) {
      state.taskId = action.payload;
    },
    setDeclined(state, action: PayloadAction<Nullable<boolean>>) {
      state.declined = action.payload;
    },
    setModified(state, action: PayloadAction<Nullable<boolean>>) {
      state.modified = action.payload;
    },
    setUnconfirmedWorktimes(state, action: PayloadAction<WorktimeInterface[]>) {
      state.unconfirmedWorktimes = action.payload;
    },
    removeUnconfirmedWorktime(state, action: PayloadAction<string>) {
      state.unconfirmedWorktimes = _.reject(state.unconfirmedWorktimes, (wt) => wt.id === action.payload);
    },
    setVacationRequestType(state, action: PayloadAction<keyof typeof VACATION_REQUEST_STATES | null>) {
      state.vacationRequestType = action.payload;
    },
    setEmployeePage(state, { payload }: PayloadAction<number>) {
      state.employeePage = payload;
    },
    setVacationRequestsPage(state, { payload }: PayloadAction<number>) {
      state.vacationRequestsPage = payload;
    },
    setShowTaskModal(state, { payload }: PayloadAction<boolean>) {
      state.showTaskModal = payload;
    },
    setShowCustomerModal(state, { payload }: PayloadAction<boolean>) {
      state.showCustomerModal = payload;
    },
    setShowWarning(state, { payload }: PayloadAction<boolean>) {
      state.showWarning = payload;
    },

    setEmployeeListTab(state, { payload }: PayloadAction<string>) {
      state.employeeListTab = payload;
    },

    setTeam(state, { payload }: PayloadAction<Nullable<TeamInterface>>) {
      state.team = payload;
    },

    resetWorktimes(state) {
      state.starts = startOfDay(new Date());
      state.stops = endOfDay(new Date());
      state.month = startOfMonth(new Date());
      state.user = null;
      state.filter = null;
      state.taskId = null;
      state.declined = null;
      state.modified = null;
      state.unconfirmedWorktimes = [];
      state.vacationRequestType = null;

      state.showTaskModal = false;
      state.showCustomerModal = false;

      state.team = null;

      state.showWarning = false;
    },
  },
});

export const worktimesSelector = (state: RootState) => state.worktimes;

export const {
  setUser,
  setStarts,
  setStops,
  setMonth,
  setFilter,
  setTaskId,
  setDeclined,
  setModified,
  setUnconfirmedWorktimes,
  removeUnconfirmedWorktime,
  setVacationRequestType,
  setEmployeePage,
  setVacationRequestsPage,
  setShowTaskModal,
  setShowCustomerModal,
  setShowWarning,
  setTeam,
  resetWorktimes,
  setEmployeeListTab,
} = worktimesSlice.actions;
export default worktimesSlice.reducer;
