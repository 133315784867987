import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "../../../store";

export type PerformanceReducerType = {
  activeTab: RangeType;
  activeDate: Date;
  activeDateStop: Date;
  search: string;
  team: TeamInterface | null;
};

const initialState: PerformanceReducerType = {
  activeTab: "yesterday",
  activeDate: new Date(),
  activeDateStop: new Date(),
  search: "",
  team: null,
};

const performanceSlice = createSlice({
  name: "stats",
  initialState,
  reducers: {
    setActiveDate(state, action: PayloadAction<{ tab: RangeType; start: Date; stop?: Date }>) {
      state.activeTab = action.payload.tab;
      state.activeDate = action.payload.start;

      if (action.payload.stop) {
        state.activeDateStop = action.payload.stop;
      }
    },
    setActiveTab(state, action: PayloadAction<RangeType>) {
      state.activeTab = action.payload;
    },
    setSearch(state, action: PayloadAction<string>) {
      state.search = action.payload;
    },
    setTeam(state, action: PayloadAction<TeamInterface | null>) {
      state.team = action.payload;
    },
    resetFilters(state) {
      state.activeTab = "today";
      state.activeDate = new Date();
      state.activeDateStop = new Date();
      state.search = "";
      state.team = null;
    },
  },
});

export const { setActiveDate, setActiveTab, setSearch, setTeam, resetFilters } = performanceSlice.actions;
export const performanceSelector = (state: RootState) => state.performance;
export default performanceSlice.reducer;
